import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { userlaneIdentify, userlaneInit } from 'utils/userlane'
import PROP_TYPES from 'constants/propTypes'

const CheckAuth = ({ auth, children, location }) => {
  const [redirectTo, setRedirectTo] = useState(null)
  const { search } = useLocation() // URL query parameters

  const query = useMemo(() => {
    return new URLSearchParams(search)
  }, [search])

  if (auth.token) {
    userlaneIdentify(
      auth.userId,
      auth.user.language,
      auth.signupClubId,
      auth.signupTeamId
    )
    userlaneInit()

    return children
  }

  useEffect(() => {
    if (!auth.token) {
      let loginUrl = '/login'

      /**
       * Get club UUID from URL query and use ID=1 as fallback.
       * @type {string|number}
       */
      const clubUuid = query.get('club') || 1

      // Get CFG club UUID from environment variables
      const cfgClubUuid = process.env.REACT_APP_CFG_CLUB_UUID

      // Redirect to CFG login page if the club UUID matches
      if (cfgClubUuid === clubUuid) {
        loginUrl = `/cfg`
      }
      setRedirectTo(loginUrl)
    }
  }, [])

  if (redirectTo) {
    return (
      <Redirect
        to={{ pathname: redirectTo, fromPathname: location.pathname }}
      />
    )
  }

  return null // Prevent rendering issues
}

CheckAuth.propTypes = {
  auth: PROP_TYPES.shape().isRequired,
  children: PROP_TYPES.children.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default compose(connect(({ auth }) => ({ auth })))(CheckAuth)
