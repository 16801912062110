import { Paper, Button, Typography, Toolbar } from '@material-ui/core'
import React, { useMemo } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { DrillCard, Carousel } from 'components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { SubLink } from 'containers'
import CarouselItem from 'components/material/CarouselItem'
import { getClub } from 'utils/localstorage'
import PropTypes from 'prop-types'
import { TOGGLE_DRILL_FAVORITE } from '../../../redux/modules/favorites'
import { updateFavoriteDrill } from './api'
import { ACTIVITIES_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const DrillsWidgetContainer = ({
  activities,
  favoritesDrills,
  clubId,
  language,
  dispatch,
}) => {
  const classes = useStyles()

  // filter all drills by specified club ID and set favorite value
  const filterAndMapDrills = (
    activitiesData,
    favoritesDrillsData,
    currentClubId
  ) => {
    return activitiesData
      .filter((drill) => drill.club_id === currentClubId)
      .map((drill) => {
        return {
          ...drill,
          favorite: Boolean(favoritesDrillsData.findById(drill.id)),
        }
      })
  }

  const drills = useMemo(
    () => filterAndMapDrills(activities, favoritesDrills, clubId),
    [activities, favoritesDrills, clubId]
  )

  const handleFavoriteToggle = (uuid) => {
    const drill = drills.findByUuid(uuid)

    updateFavoriteDrill(uuid, !drill.favorite)
      .then(() => {
        dispatch({
          type: TOGGLE_DRILL_FAVORITE,
          payload: { id: drill.id, favorite: !drill.favorite },
        })
      })
      .catch((error) => {
        console.error('Error toggling favorite status:', error)
      })
  }

  return (
    <Paper className={classes.root}>
      <Toolbar disableGutters={false} className={classes.toolbar}>
        <Typography variant="h4" component="h4" className={classes.title}>
          {clubId === DEFAULT_CLUB_ID ? (
            <Translate id="home.coachbetter_drills" />
          ) : (
            <>
              {`${getClub()?.name}`} <Translate id="home.drills" />
            </>
          )}
        </Typography>
        <Button
          color="primary"
          component={SubLink}
          to={ACTIVITIES_ROUTE}
          endIcon={<ChevronRightIcon />}
        >
          <Translate id="home.read-more" />
        </Button>
      </Toolbar>
      <Carousel>
        {drills.map(
          ({
            uuid,
            title,
            images,
            duration,
            max_players,
            min_players,
            favorite,
            coaching_points,
            drill_flow,
            description,
            organization,
            variations,
            video_url,
            age_classes,
          }) => (
            <CarouselItem key={uuid}>
              <DrillCard
                withPhaseMenu={false}
                showAddToTrainingButton={false}
                showDrillActions={false}
                onFavoriteToggle={() => handleFavoriteToggle(uuid)}
                drill={{
                  uuid,
                  title,
                  relationships: {
                    images: images || [],
                    age_classes: age_classes || [],
                  },
                  duration,
                  max_players,
                  min_players,
                  variations,
                  organization,
                  description,
                  coaching_points,
                  drill_flow,
                  video_url,
                  favorite,
                  can: [],
                }}
                language={language}
                onPhaseSelect={() => {}}
                onDuplicate={() => {}}
              />
            </CarouselItem>
          )
        )}
      </Carousel>
    </Paper>
  )
}

DrillsWidgetContainer.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  favoritesDrills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  language: PropTypes.string.isRequired,
  clubId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default connect(({ drills, favorites, auth }) => ({
  activities: drills.items,
  language: auth.user.language,
  favoritesDrills: favorites.items.drills,
}))(DrillsWidgetContainer)
