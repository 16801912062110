import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card, CardHeader, Typography, Grid } from '@material-ui/core'
import { LoadingSpinner } from 'components'
import { Translate } from 'react-localize-redux'
import useTeamFeature from 'hooks/useTeamFeature'
import AddEditTeamSkillsDialog from './components/AddEditTeamSkillsDialog'
import RadarChartTeamSkills from './components/RadarChartTeamSkills'
import { fetchSkills } from './api'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const TeamSkillsRadarChartWidget = ({ teamId }) => {
  const [skills, setSkills] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const { enabled: hasAddEditTeamSkillsEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.team_skills_enabled
  )

  useEffect(() => {
    if (teamId) {
      setIsLoading(true)

      fetchSkills(teamId, { fields: 'skill_avg' })
        .then((res) => {
          setSkills(res.data)
          setIsLoading(false)
        })
        .catch((err) => {
          setError(err)
          setIsLoading(false)
          console.error('Error fetching team skills:', error)
        })
    }
  }, [teamId])

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            <Translate id="team.overall-team-skills" />
          </Typography>
        }
        action={
          <>
            {hasAddEditTeamSkillsEnabled && !isLoading && (
              <AddEditTeamSkillsDialog skills={skills} setSkills={setSkills} />
            )}
          </>
        }
      />
      {isLoading && <LoadingSpinner />}
      {error && <div> Something went wrong! </div>}
      {!isLoading && (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={8} sm={6} md={9} xl={7}>
            {skills.length && <RadarChartTeamSkills skills={skills} />}
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

TeamSkillsRadarChartWidget.propTypes = {
  teamId: PropTypes.number.isRequired,
}

export default connect(({ team }) => ({
  teamId: team.id,
}))(TeamSkillsRadarChartWidget)
