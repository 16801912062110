import React from 'react'
import { connect } from 'react-redux'
import TrainingDashboardLayout from './TrainingDashboardLayout'
import PROP_TYPES from 'constants/propTypes'

const TrainingDashboardContainer = ({ currentTraining }) => {
  return <>{currentTraining && <TrainingDashboardLayout />}</>
}

TrainingDashboardContainer.propTypes = {
  currentTraining: PROP_TYPES.shape().isRequired,
}
export default connect(
  ({ trainings: { current } }) => ({
    currentTraining: current,
  }),
  {}
)(TrainingDashboardContainer)
