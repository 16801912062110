import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { rgba } from 'polished'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import AlertDialog from 'components/AlertDialog'
import { taskGroups } from 'redux/modules'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { PlayersLoader } from 'loaders'
import { TRAININGS_GAME } from '../../constants/calender'
import { PLAYER, PLAYER_CAMEL_CASE } from '../../constants/player'
import TaskGroupEventsCollapsibleRow from './TaskGroupEventsCollapsibleRow'

const useStyles = makeStyles((theme) => ({
  table: {
    position: 'relative',
    '& tr.MuiTableRow-root:hover': {
      backgroundColor: rgba(theme.Dataset.color_1, 0.1),
    },
    '& .MuiChip-root': {
      width: 100,
    },
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: 'transparent',
    },
    '& .MuiAvatar-root': {
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.09)',
      backgroundColor: '#eee',
      color: '#b5b5b5',
    },
    '& .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      padding: '7px 16px',
      fontSize: '.8rem',
      fontWeight: 500,
    },
    '& .MuiCollapse-root.MuiCollapse-entered': {
      backgroundColor: '#F5F5F5',
      fontSize: '0.8rem',
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      textTransform: 'capitalize',
    },
    '& .MuiCollapse-root .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root':
      {
        borderBottom: '0',
      },
    '& .MuiCollapse-root & .MuiTableRow-head': {
      backgroundColor: '#ddd',
    },
    '& .MuiCollapse-root & .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root':
      {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
    '& .MuiBox-root.MuiBox-root-41': {
      position: 'absolute',
      left: '0',
      right: '0',
      top: '39%',
    },
  },
}))

const TaskGroupsTable = ({
  taskGroupsList,
  players,
  teamName,
  handleOpen,
  deleteTaskGroup,
  dateFormat,
  updateTaskGroup,
}) => {
  const [openAlert, setOpenAlert] = useState(false)
  const [openCompleteAlert, setOpenCompleteAlert] = useState(false)
  const [deleteTaskGroupId, setDeleteTaskId] = useState(false)
  const [taskGroupComplete, setTaskGroupComplete] = useState(null)

  const handleAlertOpen = () => {
    setOpenAlert(true)
  }
  const handleAlertOpenForCompleteTask = (taskId) => {
    setOpenCompleteAlert(true)
    const task = taskGroupsList.find((p) => p.id === taskId)
    setTaskGroupComplete(task)
  }
  const handleClose = () => {
    setOpenAlert(false)
  }

  const handleCloseForCompleteTask = () => {
    setOpenCompleteAlert(false)
    setTaskGroupComplete(null)
  }
  const openAlertBox = (taskId) => {
    if (taskId) {
      setDeleteTaskId(taskId)
      handleAlertOpen()
    }
  }
  const handleDelete = () => {
    deleteTaskGroup(deleteTaskGroupId)
    setOpenAlert(false)
  }

  const splitType = (value) => {
    let type = null
    if (value?.includes(TRAININGS_GAME.training_camel_case)) {
      type = TRAININGS_GAME.training
    } else if (value?.includes(TRAININGS_GAME.match_camel_case)) {
      type = TRAININGS_GAME.match
    } else if (value?.includes(PLAYER_CAMEL_CASE)) {
      type = PLAYER
    }

    return type
  }

  const handleUpdate = () => {
    const body = {
      completed: 1,
      title: taskGroupComplete.title,
      model_ids: taskGroupComplete.model_ids,
      model_type: splitType(taskGroupComplete?.model_type),
      event_ids: taskGroupComplete.event_ids,
      event_type: splitType(taskGroupComplete?.event_type),
      description: taskGroupComplete.description,
      due_date: taskGroupComplete.due_date_offset,
    }

    if (taskGroupComplete.questions && taskGroupComplete.questions.length > 0) {
      body.questions = taskGroupComplete.questions
    }

    updateTaskGroup(taskGroupComplete.id, body).then(() => {
      setOpenCompleteAlert(false)
    })
  }

  const classes = useStyles()

  return (
    <PlayersLoader>
      <TableContainer>
        <Table stickyHeader className={classes.table} aria-label="tasks table">
          <TableHead>
            <TableRow>
              <TableCell>{}</TableCell>
              <TableCell width="28%">
                <Translate id="articles.title" />
              </TableCell>
              <TableCell>
                <Translate id="task-management.category" />
              </TableCell>
              <TableCell>
                <Translate id="task-management.assignees" />
              </TableCell>
              <TableCell>
                <Translate id="season.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskGroupsList.map((row) => (
              <TaskGroupEventsCollapsibleRow
                key={row.name}
                teamName={teamName}
                taskGroup={row}
                dateFormat={dateFormat}
                players={players}
                handleOpen={handleOpen}
                handleAlertOpenForCompleteTask={handleAlertOpenForCompleteTask}
                openAlertBox={openAlertBox}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openAlert && (
        <AlertDialog
          open={openAlert}
          onClose={handleClose}
          onSuccess={handleDelete}
          onCancel={handleClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {openCompleteAlert && (
        <AlertDialog
          open={openCompleteAlert}
          onClose={handleCloseForCompleteTask}
          onSuccess={handleUpdate}
          onCancel={handleCloseForCompleteTask}
          title={<Translate id="button.complete" />}
          subTitle={
            <Translate id="events.are-you-sure-you-want-to-complete-task" />
          }
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
    </PlayersLoader>
  )
}
TaskGroupsTable.propTypes = {
  taskGroupsList: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  players: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  handleOpen: PropTypes.func.isRequired,
  deleteTaskGroup: PropTypes.func.isRequired,
  updateTaskGroup: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
}
export default compose(
  withRouter,
  connect(
    ({ auth, players, team }) => ({
      players: players.items,
      dateFormat: auth.user.datetime_format,
      teamName: team.name,
    }),
    {
      deleteTaskGroup: taskGroups.deleteTaskGroup,
      updateTaskGroup: taskGroups.updateTaskGroup,
    }
  )
)(TaskGroupsTable)
