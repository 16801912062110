import React, { useMemo } from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { ClubLoader, TeamLoader } from 'loaders'
import { NavBar } from 'containers'
import {
  Article,
  ActivityCreatePage,
  ActivityEditPage,
  DrillsCategories,
  TrainingPackageCreate,
  TrainingPackageEdit,
  Favorites,
  HomeDashboard,
  ArticlesDashboard,
  ArticleCreate,
  ArticleEdit,
  ArticlesCategories,
  ArticlesCategoriesView,
  MatchDashboard,
  NoMatch,
  PlayerDashboard,
  TeamDashboard,
  TeamEdit,
  TeamMenu,
  Seasons,
  TrainingDashboard,
  TrainingsCalendar,
  Video,
  VideoAnalysis,
  LineupsDashboard,
  LineupCreate,
  LineupEdit,
  UpdateCard,
  User,
  Invoice,
  TaskGroups,
  GeneralEventPage,
  PlayerTransferHistory,
  UserManagement,
} from 'routes'
import TaskGroup from 'routes/TaskManagement/TaskGroup'
import ActivitiesPage from 'routes/ActivitiesPage'
import TrainingPackagesPage from 'routes/TrainingPackagesPage'
import {
  ARTICLE_ROUTE,
  DRILL_CREATE_ROUTE,
  DRILL_EDIT_ROUTE,
  DRILLS_CATEGORIES,
  TRAINING_PACKAGE_CREATE_ROUTE,
  TRAINING_PACKAGE_EDIT_ROUTE,
  FAVORITES_ROUTE,
  ARTICLES_DASHBOARD_ROUTE,
  ARTICLE_CREATE_ROUTE,
  ARTICLE_EDIT_ROUTE,
  ARTICLES_CATEGORIES,
  ARTICLES_CATEGORIES_VIEW,
  MATCH_DASHBOARD_ROUTE,
  TEAM_DASHBOARD_ROUTE,
  TEAM_EDIT_ROUTE,
  TEAM_MENU_ROUTE,
  TEAM_UPGRADE_ROUTE,
  TEAM_PLAYER_DASHBOARD_ROUTE,
  SEASONS_ROUTE,
  TRAINING_DASHBOARD_ROUTE,
  EVENT_ROUTE,
  VIDEO_ANALYSIS_ROUTE,
  VIDEO_ROUTE,
  LINEUPS_DASHBOARD_ROUTE,
  LINEUP_CREATE_ROUTE,
  LINEUP_EDIT_ROUTE,
  UPDATE_CARD_DATA,
  USER_ROUTE,
  SUBSCRIPTION_INVOICE,
  TASK_MANAGEMENT_ROUTE,
  TASK_ROUTE,
  EVENT_DASHBOARD_ROUTE,
  ACTIVITIES_ROUTE,
  TRAINING_PACKAGES_ROUTE,
  TEAM_PLAYER_TRANSFER_HISTORY_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const RouteWithSubRoutes = ({
  match: {
    path: parentPath,
    params: { teamUuid },
  },
}) => {
  const routes = useMemo(
    () => [
      {
        path: '',
        exact: true,
        component: HomeDashboard,
      },
      {
        path: EVENT_ROUTE,
        exact: true,
        component: TrainingsCalendar,
      },
      {
        path: USER_ROUTE,
        component: User,
      },
      {
        path: TRAINING_DASHBOARD_ROUTE,
        component: TrainingDashboard,
      },
      {
        path: MATCH_DASHBOARD_ROUTE,
        component: MatchDashboard,
      },
      {
        path: EVENT_DASHBOARD_ROUTE,
        component: GeneralEventPage,
      },
      {
        path: ARTICLES_DASHBOARD_ROUTE,
        exact: true,
        component: ArticlesDashboard,
      },
      {
        path: `${ARTICLES_DASHBOARD_ROUTE}/:type(all|new)`,
        exact: true,
        component: ArticlesDashboard,
      },
      {
        path: `${ARTICLES_DASHBOARD_ROUTE}/:type(all|new)/:category`,
        component: ArticlesDashboard,
      },
      {
        path: ARTICLE_CREATE_ROUTE,
        component: ArticleCreate,
      },
      {
        path: ARTICLE_ROUTE,
        exact: true,
        component: Article,
      },
      {
        path: ARTICLE_EDIT_ROUTE,
        component: ArticleEdit,
      },
      {
        path: ARTICLES_CATEGORIES,
        component: ArticlesCategories,
      },
      {
        path: `${ARTICLES_CATEGORIES_VIEW}/:id`,
        component: ArticlesCategoriesView,
      },
      {
        path: TEAM_EDIT_ROUTE,
        component: TeamEdit,
        withoutSubscription: true,
      },
      {
        path: TEAM_UPGRADE_ROUTE,
        component: TeamEdit,
        withoutSubscription: true,
      },
      {
        path: SEASONS_ROUTE,
        component: Seasons,
      },
      {
        path: TEAM_MENU_ROUTE,
        component: TeamMenu,
      },
      {
        path: TEAM_DASHBOARD_ROUTE,
        exact: true,
        component: TeamDashboard,
      },
      {
        path: TEAM_PLAYER_DASHBOARD_ROUTE,
        component: PlayerDashboard,
      },
      {
        path: DRILL_CREATE_ROUTE,
        component: ActivityCreatePage,
      },
      {
        path: DRILL_EDIT_ROUTE,
        component: ActivityEditPage,
      },
      {
        path: DRILLS_CATEGORIES,
        component: DrillsCategories,
      },
      {
        path: TRAINING_PACKAGE_CREATE_ROUTE,
        component: TrainingPackageCreate,
      },
      {
        path: TRAINING_PACKAGE_EDIT_ROUTE,
        component: TrainingPackageEdit,
      },
      {
        path: FAVORITES_ROUTE,
        component: Favorites,
      },
      {
        path: VIDEO_ANALYSIS_ROUTE,
        exact: true,
        component: VideoAnalysis,
      },
      {
        path: VIDEO_ROUTE,
        component: Video,
      },
      {
        path: LINEUPS_DASHBOARD_ROUTE,
        exact: true,
        component: LineupsDashboard,
      },
      {
        path: LINEUP_CREATE_ROUTE,
        component: LineupCreate,
      },
      {
        path: LINEUP_EDIT_ROUTE,
        component: LineupEdit,
      },
      {
        path: UPDATE_CARD_DATA,
        component: UpdateCard,
      },
      {
        path: SUBSCRIPTION_INVOICE,
        exact: true,
        component: Invoice,
        withoutSubscription: true,
      },
      {
        path: TASK_MANAGEMENT_ROUTE,
        component: TaskGroups,
      },
      {
        path: TASK_ROUTE,
        component: TaskGroup,
      },
      {
        path: ACTIVITIES_ROUTE,
        component: ActivitiesPage,
      },
      {
        path: TRAINING_PACKAGES_ROUTE,
        component: TrainingPackagesPage,
      },
      {
        path: TEAM_PLAYER_TRANSFER_HISTORY_ROUTE,
        component: PlayerTransferHistory,
      },
      {
        path: USER_MANAGEMENT_ROUTE,
        component: UserManagement,
      },
    ],
    []
  )

  return (
    <TeamLoader teamUuid={teamUuid}>
      <ClubLoader>
        <NavBar>
          <Switch>
            {routes.map(({ path, ...rest }) => {
              return <Route key={path} path={parentPath + path} {...rest} />
            })}
            <Route component={NoMatch} />
          </Switch>
        </NavBar>
      </ClubLoader>
    </TeamLoader>
  )
}

RouteWithSubRoutes.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
}

export default withRouter(RouteWithSubRoutes)
