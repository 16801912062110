import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  searchBar: {
    border: '1px solid #0000003A',
    height: '44px',
    borderRadius: '12px',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  root: {
    minHeight: '100vh',
    padding: '24px',
  },
  container: {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
  },
  filtersContainer: {
    padding: '20px',
    marginBottom: '20px',
  },
  title: {
    marginBottom: '10px',
  },
  menu: {
    width: '200px',
  },
  tableRow: {
    '& .MuiTableCell-root': {
      fontWeight: '600 !important',
      width: '20%',
    },
  },
  chip: {
    '&.MuiChip-root': {
      height: '30px',
      padding: '0 10px',
      borderRadius: '16px',
      backgroundColor: '#fff',
      border: '1px solid #c4c4c4',
    },
  },
  invites: {
    marginBottom: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
  },
  invitationLink: {
    marginBottom: '.5rem',
  },
  invitesTitle: {
    padding: '20px',
  },
  tab: {
    minWidth: '90px',
    '&.MuiTab-textColorPrimary': {
      color: 'unset',
    },
  },
  copyIcon: {
    marginLeft: '.5rem',
  },
  resetBtn: {
    marginRight: '10px',
  },
  email: {
    width: '300px',
  },
  deepLink: {
    display: 'flex',
    alignItems: 'center',
  },
  tabContainer: {
    borderBottom: '1px solid #e0e0e0',
  },
  contentContainer: {
    minHeight: '200px',
  },
  pendingInvitationText: {
    fontStyle: 'italic',
    color: 'gray',
  },
}))

export default useStyles
