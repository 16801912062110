import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import {
  Modal,
  BorderedGreenButton,
  ActionWithPrompt,
  TextArea,
  ReactSelect,
} from 'components'
import SequencesImage from 'assets/images/video-sequences.svg'
import { videos } from 'redux/modules'
import filterObject from 'utils/filterObject'
import { OutlinedInput, FormControl } from '@mui/material'
import { Grid, Button, Box, makeStyles, Chip, Popover } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import useTeamFeature from 'hooks/useTeamFeature'
import VideoPlayer from './VideoPlayer'
import SEQUENCE_TAGS from 'constants/sequenceTags'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const useStyles = makeStyles((theme) => ({
  input: {
    marginRight: '1rem',
    borderRadius: '.5rem!important',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  popoverPaper: {
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    width: 400,
    maxHeight: 300,
    overflowY: 'auto',
  },
  chips: {
    display: 'flex',
    direction: 'rtl',
    '& .MuiChip-icon': {
      margin: theme.spacing(0),
    },
    '& .MuiChip-label': {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
      height: theme.spacing(2),
      width: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  },
}))
const SequenceModal = ({
  sequence,
  onHide,
  createVideoSequence,
  removeNewVideoSequence,
  updateSequence,
  deleteSequence,
  players: availablePlayers,
  matches: availableMatches,
  open,
}) => {
  const [sequenceState, changeSequenceState] = useState({})
  // for matches suggestionseModel
  let matchesSuggestions = []

  const { enabled: hasVideoSectionEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.video_manager_enabled
  )

  if (availableMatches) {
    matchesSuggestions = availableMatches
      .filter((match) => match.status === 'published')
      .map((match) => ({
        value: match.id,
        label: `@VS ${match.opponent} | at ${match.location} | on ${match.date}`,
      }))
  } else {
    matchesSuggestions = []
  }
  const playersSuggestions = availablePlayers
    .filter((player) => player.first_name !== null || player.last_name !== null)
    .map((player) => ({
      value: player.id,
      label: `@${player.first_name} ${player.last_name}`,
    }))

  useEffect(() => {
    changeSequenceState({
      ...sequence,
      title: sequence.title || '',
      notes: sequence.notes || '',
      tag: sequence.tag || 'others',
      players: playersSuggestions.filter(({ value }) =>
        (sequence.players || []).includes(value)
      ),
      matches: matchesSuggestions.filter(({ value }) =>
        (sequence.matches || []).includes(value)
      ),
    })
  }, [sequence])

  const {
    id,
    title,
    video_url: videoUrl,
    video_id: videoId,
    from,
    to,
    tag,
    duration,
    notes,
    players,
    matches,
  } = sequenceState

  const handleHide = () => {
    onHide()

    if (id === 'new') {
      removeNewVideoSequence()
    }
  }

  const handleSave = () => {
    createVideoSequence(videoId, {
      video_id: videoId,
      from: Math.floor(from),
      to: Math.floor(to),
      title,
      notes,
      tag,
      players: players.map(({ value }) => value),
      matches: matches.map(({ value }) => value),
    })
      .then(() => {
        handleHide()
        window.location.reload()
      })
      .catch(({ errors }) => {
        Object.entries(errors).forEach(([, value]) => {
          const errorMessage = value.find(Boolean) || ''

          toast(errorMessage)
        })
      })
  }

  const handleUpdate = () => {
    updateSequence(
      id,
      filterObject(
        {
          title,
          notes,
          tag,
          players: players ? players.map(({ value }) => value) : [],
          matches: matches ? matches.map(({ value }) => value) : [],
        },
        Boolean
      )
    ).then(handleHide)
  }

  const handleDelete = () => {
    deleteSequence(id).then(handleHide)
  }

  const onChangeTitle = ({ target: { value } }) =>
    changeSequenceState({ ...sequenceState, title: value })

  const onChangeNotes = ({ target: { value } }) =>
    changeSequenceState({ ...sequenceState, notes: value })

  const onChangeTag = (newTag) =>
    changeSequenceState({ ...sequenceState, tag: newTag })

  const onChangePlayers = (newPlayers, { option }) => {
    if (option && option.value === 'everyOne') {
      return changeSequenceState({
        ...sequenceState,
        players: playersSuggestions,
      })
    }

    return changeSequenceState({ ...sequenceState, players: newPlayers })
  }
  // for matches onchange
  const onChangeMatches = (newMatches, { option }) => {
    if (option && option.value === 'everyOne') {
      return changeSequenceState({
        ...sequenceState,
        matches: matchesSuggestions,
      })
    }

    return changeSequenceState({ ...sequenceState, matches: newMatches })
  }

  const updateFromTo = ({ start, end }) => {
    changeSequenceState({
      ...sequenceState,
      from: (start / 100) * duration,
      to: (end / 100) * duration,
    })
  }

  const visibleTags = SEQUENCE_TAGS.slice(0, 11)
  const additionalDropdownTags = SEQUENCE_TAGS.slice(11)
  const [anchorEl0, setAnchorEl0] = useState(null)

  const isSelectedTag = anchorEl0

  const handleTagClick = (event) => {
    setAnchorEl0(event.currentTarget)
  }

  const handleTagClose = () => {
    setAnchorEl0(null)
  }

  const openTag = Boolean(anchorEl0)
  const popoverIdTag = openTag ? 'popover0' : undefined
  const sortedTags = additionalDropdownTags.sort((a, b) => {
    const valueA = a.value && typeof a.value === 'string' ? a.value : ''
    const valueB = b.value && typeof b.value === 'string' ? b.value : ''

    return valueA.localeCompare(valueB)
  })

  const classes = useStyles()

  return (
    <Translate>
      {({ translate }) => (
        <Modal
          title={translate('videos.sequence')}
          onHide={handleHide}
          icon={SequencesImage}
          open={open}
        >
          <Grid container spacing={2}>
            <Grid item md={8}>
              <FormControl>
                <OutlinedInput
                  value={title || ''}
                  onChange={onChangeTitle}
                  className={classes.input}
                  placeholder={translate('videos.name')}
                />
              </FormControl>
            </Grid>
            {hasVideoSectionEnabled && (
              <Grid item md={4}>
                <ReactSelect
                  value={players}
                  suggestions={playersSuggestions}
                  onChange={onChangePlayers}
                  placeholder={translate('videos.tag-person')}
                  isEveryOne
                />
                <ReactSelect
                  value={matches}
                  suggestions={matchesSuggestions}
                  onChange={onChangeMatches}
                  placeholder={translate('videos.tag-match')}
                  isEveryOne
                />
              </Grid>
            )}
          </Grid>
          <Grid
            container
            className="padding-vertical-15 sequences-player"
            spacing={2}
          >
            <Grid item md={8}>
              {id && (
                <VideoPlayer
                  video={{ id, url: videoUrl, from, to, duration }}
                  isSequence
                  updateFromTo={updateFromTo}
                />
              )}
            </Grid>
            <Grid item md={4}>
              <TextArea
                value={notes || ''}
                onChange={onChangeNotes}
                placeholder={translate('videos.notes')}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              md={8}
              className="d-flex flex-wrap"
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <Translate id="videos.tags" />
              {visibleTags.map(
                ({ value, title: tagTitle, background, color }) => (
                  <BorderedGreenButton
                    key={value}
                    background={background}
                    color={color}
                    isActive={tag === value}
                    onClick={() => onChangeTag(value)}
                  >
                    {tagTitle}
                  </BorderedGreenButton>
                )
              )}
              <Chip
                label={<Translate id="videos.more" />}
                icon={<ArrowDropDownIcon />}
                component={Box}
                className={classes.chips}
                m={(0.3, 0.6)}
                p={2}
                color={isSelectedTag ? 'primary' : ''}
                onClick={handleTagClick}
              />
              <Popover
                id={popoverIdTag}
                open={openTag}
                anchorEl={anchorEl0}
                onClose={handleTagClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                PaperProps={{
                  className: classes.popoverPaper,
                }}
              >
                <Box display="flex" flexWrap="wrap">
                  {sortedTags.map(
                    ({ value, title: tagTitle, background, color }) => (
                      <BorderedGreenButton
                        key={value}
                        background={background}
                        color={color}
                        isActive={tag === value}
                        onClick={() => onChangeTag(value)}
                      >
                        {tagTitle}
                      </BorderedGreenButton>
                    )
                  )}
                </Box>
              </Popover>
            </Grid>
            <Grid item md={4}>
              <Box display="flex">
                <ActionWithPrompt
                  className="flex-grow-1 text-danger border-danger"
                  onSuccess={id === 'new' ? handleHide : handleDelete}
                  message="Are you sure you want to delete the Sequence?"
                  title={
                    id === 'new'
                      ? translate('button.cancel')
                      : translate('button.delete')
                  }
                  style={{ flexGrow: 1 }}
                  zIndexClass
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={id === 'new' ? handleSave : handleUpdate}
                >
                  <Translate id="button.save" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
    </Translate>
  )
}

SequenceModal.defaultProps = {
  players: [],
  matches: [],
}

SequenceModal.propTypes = {
  sequence: PropTypes.shape().isRequired,
  onHide: PropTypes.func.isRequired,
  createVideoSequence: PropTypes.func.isRequired,
  removeNewVideoSequence: PropTypes.func.isRequired,
  updateSequence: PropTypes.func.isRequired,
  deleteSequence: PropTypes.func.isRequired,
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  matches: PropTypes.arrayOf(PropTypes.shape()),
  open: PropTypes.bool.isRequired,
}

export default connect(null, {
  createVideoSequence: videos.createVideoSequence,
  removeNewVideoSequence: videos.removeNewVideoSequence,
})(SequenceModal)
