import React from 'react'
import { Translate } from 'react-localize-redux'
import HomeIcon from '@material-ui/icons/Dashboard'
import TrainingIcon from '@material-ui/icons/EventAvailable'
import KnowledgeBaseIcon from '@material-ui/icons/School'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import TeamIcon from '@material-ui/icons/SportsSoccer'
import VideoAnalysisIcon from '@material-ui/icons/VideoLibrary'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'

import { TeaserTagOverlay } from 'components'
import {
  EVENT_ROUTE,
  TRAINING_DASHBOARD_ROUTE,
  EVENT_DASHBOARD_ROUTE,
  ARTICLES_DASHBOARD_ROUTE,
  ARTICLE_ROUTE,
  TEAM_MENU_ROUTE,
  TEAM_DASHBOARD_ROUTE,
  TEAM_EDIT_ROUTE,
  TEAM_PLAYER_CREATE_ROUTE,
  TEAM_UPGRADE_ROUTE,
  TEAM_PLAYER_EDIT_ROUTE,
  TEAM_PLAYER_DASHBOARD_ROUTE,
  VIDEO_ANALYSIS_ROUTE,
  VIDEO_ROUTE,
  TASK_MANAGEMENT_ROUTE,
  ACTIVITIES_ROUTE,
  TRAINING_PACKAGES_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from 'constants/routes'
import { PLUS, PRO } from 'constants/plans'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

export default [
  {
    id: 'DASHBOARD_ROUTE',
    to: '/',
    title: <Translate id="menu.dashboard" />,
    icon: <HomeIcon />,
    rootPath: '',
    forRoutes: [''],
  },
  {
    id: 'EVENT_ROUTE',
    to: EVENT_ROUTE,
    title: <Translate id="menu.event" />,
    icon: <TrainingIcon />,
    rootPath: EVENT_ROUTE,
    forRoutes: [EVENT_ROUTE, TRAINING_DASHBOARD_ROUTE, EVENT_DASHBOARD_ROUTE],
    featureKey: TEAM_FEATURE_FLAGS.events_enabled,
    teaser: (
      <TeaserTagOverlay
        tagLabel={PLUS}
        wrapperStyleObj={{ position: 'unset' }}
      />
    ),
  },
  {
    id: 'TEAM_MENU_ROUTE',
    to: TEAM_MENU_ROUTE,
    title: <Translate id="menu.team" />,
    icon: <TeamIcon />,
    rootPath: TEAM_DASHBOARD_ROUTE,

    forRoutes: [
      TEAM_MENU_ROUTE,
      TEAM_DASHBOARD_ROUTE,
      TEAM_EDIT_ROUTE,
      TEAM_PLAYER_CREATE_ROUTE,
      TEAM_UPGRADE_ROUTE,
      TEAM_PLAYER_EDIT_ROUTE,
      TEAM_PLAYER_DASHBOARD_ROUTE,
    ],
  },
  {
    id: 'ACTIVITIES_ROUTE',
    to: ACTIVITIES_ROUTE,
    title: <Translate id="menu.activities" />,
    icon: <FitnessCenterIcon />,
    rootPath: '/activities',
    forRoutes: [ACTIVITIES_ROUTE, TRAINING_PACKAGES_ROUTE],
    featureKey: TEAM_FEATURE_FLAGS.activities_enabled,
    teaser: (
      <TeaserTagOverlay
        tagLabel={PLUS}
        wrapperStyleObj={{ position: 'unset' }}
      />
    ),
  },
  {
    id: 'ARTICLES_DASHBOARD_ROUTE',
    to: ARTICLES_DASHBOARD_ROUTE,
    title: <Translate id="menu.knowledge" />,
    icon: <KnowledgeBaseIcon />,
    rootPath: ARTICLES_DASHBOARD_ROUTE,
    forRoutes: [ARTICLES_DASHBOARD_ROUTE, ARTICLE_ROUTE],
    featureKey: TEAM_FEATURE_FLAGS.knowledge_enabled,
    teaser: (
      <TeaserTagOverlay
        tagLabel={PLUS}
        wrapperStyleObj={{ position: 'unset' }}
      />
    ),
  },
  {
    id: 'TASK_MANAGEMENT_ROUTE',
    to: TASK_MANAGEMENT_ROUTE,
    title: <Translate id="menu.task" />,
    icon: <FormatListBulletedIcon />,
    rootPath: TASK_MANAGEMENT_ROUTE,
    forRoutes: [TASK_MANAGEMENT_ROUTE],
    featureKey: TEAM_FEATURE_FLAGS.task_manager_enabled,
    teaser: (
      <TeaserTagOverlay
        tagLabel={PLUS}
        wrapperStyleObj={{ position: 'unset' }}
      />
    ),
  },
  {
    id: 'VIDEO_ANALYSIS_ROUTE',
    to: VIDEO_ANALYSIS_ROUTE,
    title: <Translate id="menu.video-analysis" />,
    icon: <VideoAnalysisIcon />,
    rootPath: VIDEO_ANALYSIS_ROUTE,
    forRoutes: [VIDEO_ANALYSIS_ROUTE, VIDEO_ROUTE],
    featureKey: TEAM_FEATURE_FLAGS.video_manager_enabled,
    teaser: (
      <TeaserTagOverlay
        tagLabel={PRO}
        wrapperStyleObj={{ position: 'unset' }}
      />
    ),
  },
  {
    id: 'USER_MANAGEMENT_ROUTE',
    to: USER_MANAGEMENT_ROUTE,
    title: <Translate id="userRoles.team-access" />,
    icon: <PeopleAltIcon />,
    rootPath: USER_MANAGEMENT_ROUTE,
  },
]
