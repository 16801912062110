import {
  Button,
  Paper,
  Divider,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core'
import axios from 'axios'
import React, { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import UploadingLinearProgress from 'components/loader/UploadingLinearProgress'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { videos as videosModule } from 'redux/modules'
import { InnrHead } from 'containers'
import PropTypes from 'prop-types'
import useStyles from './useStyles'
import VideoCard from './VideoCard'

const VIDEO_MAX_SIZE = 10240 * 1024 * 1024 // 10GB

const ACCEPTED_VIDEO_FILE_FORMATS = [
  '.mp4',
  '.quicktime',
  '.mov',
  '.avi',
  '.mpeg',
  '.mpg',
  '.wmv',
]

const UploadedAndNotCompleted = ({
  videos,
  handleComplete,
  setVideoUploadProgress,
  videoUploadProgress,
  uploadVideo,
}) => {
  const [isUploading, setIsUploading] = useState(false)
  const [videoFiles, setVideoFiles] = useState(null)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  const fileInputRef = useRef(null)

  const onProgress = (event) =>
    setVideoUploadProgress(Math.ceil((event.loaded / event.total) * 100))

  const handleFileUpload = (event) => {
    const { files } = event.target
    if (files.length === 0) return

    const file = files[0]
    const fileType = file.name.split('.').pop().toLowerCase()

    if (!ACCEPTED_VIDEO_FILE_FORMATS.includes(`.${fileType}`)) {
      toast('Only video files .mp4, .mov, .avi, .mpeg, .mpg, .wmv supported!')

      return
    }

    if (file.size > VIDEO_MAX_SIZE) {
      toast('Only video files less than 10GB supported!')

      return
    }

    setIsUploading(true)

    setVideoFiles(file)

    const newCancelSource = axios.CancelToken.source()

    setCancelTokenSource(newCancelSource)

    uploadVideo({ video: file }, newCancelSource.token, onProgress)
      .then(() => {
        setIsUploading(false)
        setCancelTokenSource(null)
      })
      .finally(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
        }
      })
  }
  const handleCancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel()
    }

    setVideoUploadProgress(0)
    setCancelTokenSource(null)
    setIsUploading(false)
  }

  const classes = useStyles()

  return (
    <Paper variant="outlined">
      <InnrHead title={<Translate id="videos.upload" />}>
        <label htmlFor="video-upload-input">
          <input
            ref={fileInputRef}
            accept={ACCEPTED_VIDEO_FILE_FORMATS.join(',')}
            style={{ display: 'none' }}
            id="video-upload-input"
            type="file"
            onChange={handleFileUpload}
          />

          <Button variant="contained" color="primary" component="span">
            <Translate id="videos.upload-new" />
          </Button>
        </label>
      </InnrHead>
      {isUploading && (
        <>
          <Box px={2} py={1} display="flex" flexDirection="column">
            <Box
              display="flex"
              alignContent="space-between"
              alignItems="center"
            >
              <UploadingLinearProgress progressValue={videoUploadProgress} />
              <IconButton
                size="small"
                className={classes.iconbutton}
                onClick={handleCancel}
              >
                <HighlightOffIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Typography variant="body2" color="textSecondary">
              {videoFiles.name}
            </Typography>
          </Box>
        </>
      )}
      <Divider />
      {videos.length > 0 && (
        <VideoCard
          videos={videos}
          relation="notCompleted"
          onComplete={handleComplete}
        />
      )}
    </Paper>
  )
}

UploadedAndNotCompleted.defaultProps = {
  videos: [],
  handleComplete: () => {},
}

UploadedAndNotCompleted.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object.isRequired),
  setVideoUploadProgress: PropTypes.func.isRequired,
  videoUploadProgress: PropTypes.func.isRequired,
  uploadVideo: PropTypes.func.isRequired,
  handleComplete: PropTypes.func,
}

export default connect(null, {
  uploadVideo: videosModule.uploadVideo,
})(UploadedAndNotCompleted)
