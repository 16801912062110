import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { videos } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const VideosLoader = ({ teamId, children, fetchVideos, ...restProps }) => {
  const fetchMethod = () => fetchVideos({ team_id: teamId })

  return (
    <Loader fetchMethod={fetchMethod} {...restProps}>
      {children}
    </Loader>
  )
}

VideosLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchVideos: PropTypes.func.isRequired,
  teamId: PROP_TYPES.id.isRequired,
}

export default connect(
  ({ team }) => ({
    teamId: team.id,
  }),
  { fetchVideos: videos.fetchVideos }
)(VideosLoader)
