import React from 'react'
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

export const SelectDays = ({day, onChange, isEditMode}) => {

  return (
      <FormControl variant="outlined" margin="dense">
        <InputLabel id="day_of_week-outlined_label">
          <Translate id="day-name.day" />
        </InputLabel>
        <Select
          labelId="day_of_week-label"
          id="day_of_week-outlined"
          value={day}
          onChange={onChange}
          name="day_of_week"
          label="Days"
          disabled={isEditMode}
        >
          <MenuItem value={0}>
            <Translate id="day-name.daily" />
          </MenuItem>
          <MenuItem value={1}>
            <Translate id="day-name.monday" />
          </MenuItem>
          <MenuItem value={2}>
            <Translate id="day-name.tuesday" />
          </MenuItem>
          <MenuItem value={3}>
            <Translate id="day-name.wednesday" />
          </MenuItem>
          <MenuItem value={4}>
            <Translate id="day-name.thursday" />
          </MenuItem>
          <MenuItem value={5}>
            <Translate id="day-name.friday" />
          </MenuItem>
          <MenuItem value={6}>
            <Translate id="day-name.saturday" />
          </MenuItem>
          <MenuItem value={7}>
            <Translate id="day-name.sunday" />
          </MenuItem>
        </Select>
      </FormControl>
  )
}
SelectDays.defaultProps={
  day : null
}
SelectDays.propTypes = {
  day: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
}
export default SelectDays
