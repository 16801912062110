import { useSelector } from 'react-redux'

/**
 * Custom hook to check if a team feature is enabled.
 * It also verifies if "teasers_enabled" is active when the feature is disabled.
 *
 * @param {string} featureName - The name of the feature to check.
 * @returns {{ enabled: boolean, tease: boolean }} - An object with:
 *   - `enabled`: `true` if the feature is fully accessible.
 *   - `tease`: `true` if the feature is unavailable, but "teasers_enabled" shows teaser overlays.
 */
const useTeamFeature = (featureName) => {
  // Get the feature flags from the Redux store
  const featureFlags = useSelector((state) => state.team?.feature_flags)

  // Return default values if feature flags are missing or not an object
  if (!featureFlags || typeof featureFlags !== 'object') {
    return { enabled: false, tease: false }
  }

  return {
    enabled: featureFlags[featureName] === true, // Feature is fully enabled
    tease:
      featureFlags[featureName] !== true &&
      featureFlags.teasers_enabled === true, // Tease mode enabled
  }
}

export default useTeamFeature
