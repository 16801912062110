import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  IconButton,
  Dialog,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import { DatePicker, Input, TimePicker, SelectedMenu } from 'components'
import Rating from '@material-ui/lab/Rating'
import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus'
import {
  formatDateTimeToServer,
  formatLocalDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import InfoIcon from '@material-ui/icons/Info'
import { createTraining } from 'containers/ActivitiesContainer/api'
import { useStyles } from '../styles'
import { DialogContent } from './StyledComponents'

const CreateTrainingModal = ({
  open,
  handleClose,
  timezone,
  dateTimeFormat,
  teamAvailabilityCutOffTime,
  match: {
    params: { seasonId },
  },
  teamId,
}) => {
  const classes = useStyles()
  const [intensity, changeIntensity] = useState(0.0)
  const cutoffTimeOptions = [0, 6, 12, 24]

  const getEventDefaultDateTimeInUserTimezone = () => {
    const nowDate = new Date()

    nowDate.setMinutes(nowDate.getMinutes() + 30)

    const momentDateTimeInUserTimezone =
      formatLocalDateTimeToUserTimezoneDateTime(nowDate, timezone)
    const jsDateTime = new Date(momentDateTimeInUserTimezone)

    return jsDateTime
  }
  const dateAfterToday = new Date()
  dateAfterToday.setDate(dateAfterToday.getDate() + 7)

  const [trainingData, setTrainingData] = useState({
    date: getEventDefaultDateTimeInUserTimezone(),
    status: 'draft',
    type: 'all',
    location: '',
    street: '',
    city: '',
    country: '',
    postal_code: '',
    lat: '',
    lng: '',
    state: '',
    training_focus: '',
    intensity: 0,
    end_time: null,
    availability_cutoff_time: null,
    duplicate: false,
    additional_info: '',
  })
  useEffect(() => {
    if (teamAvailabilityCutOffTime >= 0) {
      setTrainingData({
        ...trainingData,
        availability_cutoff_time: teamAvailabilityCutOffTime,
      })
    }
  }, [teamAvailabilityCutOffTime])

  const handleDateChange = (date) => {
    setTrainingData({ ...trainingData, date })
  }

  const handleTextBox = (event) => {
    const {
      target: { name, value },
    } = { ...event }
    setTrainingData({
      ...trainingData,
      [name]: value,
    })
  }
  const onChangeRating = (newRating) => {
    changeIntensity(newRating)
    setTrainingData({ ...trainingData, intensity: newRating })
  }
  const handleCutoffTimeChange = (event) => {
    const { value } = event.target
    setTrainingData({ ...trainingData, availability_cutoff_time: value })
  }
  const handleEndTimeChange = (time) => {
    setTrainingData({ ...trainingData, end_time: time })
  }

  const newTraining = () => {
    const {
      date,
      location,
      status,
      type,
      training_focus: trainingFocus,
      street,
      city,
      country,
      postal_code: postalCode,
      lat,
      lng,
      state,
      additional_info: additionalInfo,
      duplicate,
      end_time: endTime,
      availability_cutoff_time: availabilityCutoffTime,
    } = { ...trainingData }

    createTraining({
      team_id: teamId,
      season_id: seasonId,
      date: formatDateTimeToServer(date),
      location,
      status,
      type,
      duplicate,
      training_focus: trainingFocus,
      street,
      city,
      country,
      postal_code: postalCode,
      lat: lat.toString(),
      lng: lng.toString(),
      state,
      intensity: intensity ? intensity.toFixed(2) : 0.0,
      additional_info: additionalInfo || '',
      end_time: endTime ? formatDateTimeToServer(endTime) : null,
      availability_cutoff_time: availabilityCutoffTime,
    }).then((res) => {
      handleClose(res.data)
    })
  }

  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
      className={classes.createTrainingWrapper}
    >
      <InnrHead title={<Translate id="button.create-plan-training" />}>
        <IconButton onClick={() => handleClose()} size="small">
          <CloseIcon />
        </IconButton>
      </InnrHead>
      <DialogContent dividers>
        <Grid item xs={12} md={12}>
          <DatePicker
            required
            label={<Translate id="training.date" />}
            selectedValue={trainingData.date}
            onDateChange={handleDateChange}
            isEditMode={false}
            dateFormat={dateTimeFormat}
          />
        </Grid>
        <Grid item xs={12}>
          <TimePicker
            selectedValue={trainingData.date}
            onDateChange={handleDateChange}
            label={<Translate id="training.time" />}
            isEditMode={false}
          />
        </Grid>

        <Grid item xs={12}>
          <PlacesSearchField
            error={trainingData.error_location}
            helperText={
              trainingData.error_location ? (
                <Translate id="events.validation.location" />
              ) : (
                ''
              )
            }
            defaultValue={trainingData.location}
            savedValue={trainingData.location}
            onChange={handleTextBox}
            changeFormData={setTrainingData}
            eventData={trainingData}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={<Translate id="form.training-focus" />}
            name="training_focus"
            value={trainingData.training_focus}
            onChange={handleTextBox}
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FilterCenterFocusIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography component="legend" style={{ color: 'gray' }}>
              <Translate id="training.intensity" />
            </Typography>
            <Rating
              name="training-rating"
              value={trainingData.intensity}
              precision={0.5}
              onChange={(e, v) => onChangeRating(v)}
            />
            {intensity > 0 && (
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                style={{ float: 'right' }}
                onClick={() => onChangeRating(0.0)}
              >
                Reset <Translate id="training.intensity" />
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              selectedValue={trainingData.end_time}
              onDateChange={handleEndTimeChange}
              label={<Translate id="matches.end-time" />}
              isEditMode={!!trainingData.end_time}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SelectedMenu
            id="cutoff-time"
            value={trainingData.availability_cutoff_time}
            margin="dense"
            className={classes.select}
            onChange={handleCutoffTimeChange}
            label={<Translate id="player.event-response.cutoff-time" />}
            endAdornment={
              <InputAdornment position="start" className={classes.infoIcon}>
                <Tooltip
                  title={<Translate id="player.event-response.info-message" />}
                  placement="bottom-end"
                >
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            }
          >
            {cutoffTimeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option === 0 ? (
                  <Translate id="player.event-response.disabled" />
                ) : (
                  <>
                    {option} <Translate id="player.event-response.hours" />
                  </>
                )}{' '}
              </MenuItem>
            ))}
          </SelectedMenu>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="additional_info"
            variant="outlined"
            margin="dense"
            defaultValue={trainingData.additional_info}
            value={trainingData.additional_info}
            multiline
            minRows={4}
            label={<Translate id="matches.additional-info" />}
            onChange={handleTextBox}
            className={classes.textarea}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={4}>
            <Button
              onClick={newTraining}
              variant="contained"
              fullWidth
              size="large"
              color="primary"
            >
              <Translate id="button.create-plan-training" />
            </Button>
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

CreateTrainingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  teamAvailabilityCutOffTime: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  dateTimeFormat: PropTypes.string.isRequired,
  teamId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default compose(
  connect(({ auth, team }) => ({
    timezone: auth.user.time_zone,
    dateTimeFormat: auth.user.datetime_format,
    teamAvailabilityCutOffTime: team.availability_cutoff_time,
    teamId: team.id,
  })),
  withRouter
)(CreateTrainingModal)
