import {
  Button,
  DialogContent,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  styled,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { DatePicker, TimePicker, Input } from 'components'
import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import { Rating } from '@material-ui/lab'
import SelectList from 'components/inputes/SelectList'
import InfoIcon from '@material-ui/icons/Info'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
  '& .MuiRating-root': {
    '& .MuiRating-label': {
      bottom: 2,
    },
  },
  '& .inputMultiline': {
    marginTop: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: 144,
    },
  },
}))

const useStyles = makeStyles(() => ({
  textarea: {
    '& .MuiInputBase-root': {
      height: 'unset',
      paddingTop: 'unset',
      paddingBottom: 'unset',
    },
  },
  infoIcon: {
    position: 'absolute',
    right: 25,
    top: 20,
  },
}))

const cutoffTimeOptions = [0, 1, 3, 6, 12, 24, 48]
const getDateTimeTranslatedError = (translationKey) => {
  return (
    <Typography variant="caption" color="error">
      <Translate id={translationKey} />
    </Typography>
  )
}

const TrainingInfoEditForm = ({
  dateFormat,
  handleSubmit,
  handleDateChange,
  handleEndTimeChange,
  datetimesAndLocationData,
  setTrainingData,
  onChange,
  onRatingChange,
  dateTimeError,
}) => {
  const classes = useStyles()

  return (
    <div>
      <StyledDialogContent>
        <Grid container alignItems="center">
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <DatePicker
                required
                label={<Translate id="training.date" />}
                selectedValue={datetimesAndLocationData.start_datetime}
                onDateChange={handleDateChange}
                name="start_datetime"
                isEditMode
                dateFormat={dateFormat}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TimePicker
                selectedValue={datetimesAndLocationData.start_datetime}
                onDateChange={handleDateChange}
                label={<Translate id="training.time" />}
                isEditMode={!!datetimesAndLocationData.start_datetime}
                name="start_datetime"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PlacesSearchField
              defaultValue={datetimesAndLocationData.location}
              savedValue={datetimesAndLocationData.location}
              name="location"
              changeFormData={setTrainingData}
              eventData={datetimesAndLocationData}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Field
              name="focus"
              label={<Translate id="form.training-focus" />}
              component={Input}
              className={classes.textarea}
              margin="dense"
              onChange={onChange}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TimePicker
                selectedValue={datetimesAndLocationData.end_datetime}
                onDateChange={handleEndTimeChange}
                label={<Translate id="matches.end-time" />}
                isEditMode={!!datetimesAndLocationData.end_datetime}
                name="end_datetime"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                name="availability_cutoff_time"
                component={SelectList}
                label={<Translate id="player.event-response.cutoff-time" />}
                endAdornment={
                  <InputAdornment position="start">
                    <Tooltip
                      title={
                        <Translate id="player.event-response.info-message" />
                      }
                      placement="bottom-end"
                    >
                      <InfoIcon style={{ marginRight: 10 }} />
                    </Tooltip>
                  </InputAdornment>
                }
              >
                {cutoffTimeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option === 0 ? 'Disabled' : `${option} hours`}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Field
              name="details"
              label={<Translate id="matches.additional-info" />}
              component={Input}
              multiline
              minRows={4}
              className={classes.textarea}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="legend" className="pt-2">
              <Translate id="training.intensity" />
            </Typography>
            <Rating
              size="medium"
              value={datetimesAndLocationData.intensity}
              precision={0.5}
              onChange={(e, newRating) => onRatingChange(newRating)}
            />
          </Grid>
          <Grid item xs={12}>
            {dateTimeError && getDateTimeTranslatedError(dateTimeError)}
          </Grid>
          <Grid item md={12} xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              margin="dense"
              fullWidth
              style={{ marginTop: 10 }}
              onClick={handleSubmit}
            >
              <Translate id="button.save" />
            </Button>
          </Grid>
        </Grid>
      </StyledDialogContent>
    </div>
  )
}

TrainingInfoEditForm.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleEndTimeChange: PropTypes.func.isRequired,
  datetimesAndLocationData: PropTypes.shape({
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string,
    location: PropTypes.string,
    intensity: PropTypes.number,
    focus: PropTypes.string,
    details: PropTypes.string,
  }).isRequired,
  setTrainingData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRatingChange: PropTypes.func.isRequired,
  dateTimeError: PropTypes.string.isRequired,
}

export default compose(
  connect(({ auth }) => ({
    dateFormat: auth.user.datetime_format,
  })),
  reduxForm({
    form: 'trainingInfoEditForm',
  })
)(TrainingInfoEditForm)
