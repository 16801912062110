import React from 'react'
import { Button, Grid, Typography, InputAdornment, Tooltip, MenuItem  } from '@material-ui/core'
import { DatePicker, Input, TimePicker } from 'components'
import PlacesSearchField from 'components/googlemaps/PlacesSearchField'
import Joi from 'joi'
import { Translate } from 'react-localize-redux'
import { Field, reduxForm } from 'redux-form'
import createJoiValidator from 'utils/createJoiValidator'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'
import InfoIcon from '@material-ui/icons/Info'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import SelectList from 'components/inputes/SelectList'
import { useInfoFormStyles, DialogActions } from '../styles'

const validationSchema = Joi.object().keys({
  name: Joi.string()
    .max(100)
    .label(getTranslatedLabelForFormField('events.event-name')),
  details: Joi.string()
    .label(getTranslatedLabelForFormField('events.additional-info'))
    .allow(null),
  availability_cutoff_time: Joi.number().label(
    getTranslatedLabelForFormField('player.event-response.cutoff-time')
  ),
})

const getDateTimeTranslatedError = (translationKey) => {
  return (
    <Typography variant="caption" color="error">
      <Translate id={translationKey} />
    </Typography>
  )
}

const EventInfoEditForm = ({
  dateFormat,
  handleCancel,
  handleSubmit,
  handleDateChange,
  handleEndTimeChange,
  datetimesAndLocationData,
  setEventData,
  dateTimeError,
}) => {
  const classes = useInfoFormStyles()
  const cutoffTimeOptions = [0, 1, 3, 6, 12, 24, 48]
  
return (
    <Grid container>
      <Grid item xs={12}>
        <DatePicker
          required
          label={<Translate id="training.date" />}
          selectedValue={datetimesAndLocationData.start_datetime}
          onDateChange={handleDateChange}
          name="start_datetime"
          isEditMode
          dateFormat={dateFormat}
        />
        <TimePicker
          selectedValue={datetimesAndLocationData.start_datetime}
          onDateChange={handleDateChange}
          name="start_datetime"
          label={<Translate id="events.table-time" />}
          isEditMode
        />
        <TimePicker
          selectedValue={datetimesAndLocationData.end_datetime}
          onDateChange={handleEndTimeChange}
          label={<Translate id="events.end-time" />}
          isEditMode={!!datetimesAndLocationData.end_datetime}
          name="end_datetime"
        />
        <Field
          name="name"
          label={<Translate id="events.event-name" />}
          component={Input}
          margin="dense"
        />
        <PlacesSearchField
          defaultValue={datetimesAndLocationData.location}
          savedValue={datetimesAndLocationData.location}
          name="location"
          changeFormData={setEventData}
          eventData={datetimesAndLocationData}
        />
        <Field
          name="availability_cutoff_time"
          component={SelectList}
          label={<Translate id="player.event-response.cutoff-time" />}
          endAdornment={
            <InputAdornment position="start" className={classes.infoIcon}>
              <Tooltip
                title={<Translate id="player.event-response.info-message" />}
                placement="bottom-end"
              >
                <InfoIcon />
              </Tooltip>
            </InputAdornment>
          }
        >
          {cutoffTimeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option === 0 ? 'Disabled' : `${option} hours`}
            </MenuItem>
          ))}
        </Field>
        <Field
          name="details"
          label={<Translate id="events.additional-info" />}
          component={Input}
          multiline
          minRows={4}
          className={classes.textarea}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12}>
        {dateTimeError && getDateTimeTranslatedError(dateTimeError)}
      </Grid>
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          <Translate id="button.cancel" />
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          <Translate id="button.save" />
        </Button>
      </DialogActions>
    </Grid>
  )
}
EventInfoEditForm.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleEndTimeChange: PropTypes.func.isRequired,
  datetimesAndLocationData: PropTypes.func.isRequired,
  setEventData: PropTypes.func.isRequired,
  dateTimeError: PropTypes.string.isRequired,
}

export default compose(
  connect(({ auth }) => ({
    dateFormat: auth.user.datetime_format,
  })),
  reduxForm({
    form: 'eventInfoEditForm',
    validate: createJoiValidator(validationSchema),
  })
)(EventInfoEditForm)
