import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.4),
    color: theme.palette.grey[500],
  },
  dialog: {
    padding: theme.spacing(1),
  },
  dialogTitle: {
    paddingLeft: theme.spacing(2),
  },
  dialogContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const UserActionDialog = ({
  open,
  onClose,
  onActionOne,
  onActionTwo,
  title,
  subTitle,
  buttonOneLabel,
  buttonTwoLabel,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.dialog}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          id="training-series-dialog-title"
          className={classes.dialogTitle}
        >
          {title}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="training-series-dialog-description">
            {subTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onActionOne} color="primary" variant="contained">
            {buttonOneLabel}
          </Button>
          <Button onClick={onActionTwo} color="primary" variant="contained">
            {buttonTwoLabel}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

UserActionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onActionOne: PropTypes.func.isRequired,
  onActionTwo: PropTypes.func.isRequired,
  title: PropTypes.shape().isRequired,
  subTitle: PropTypes.shape().isRequired,
  buttonOneLabel: PropTypes.shape().isRequired,
  buttonTwoLabel: PropTypes.shape().isRequired,
}

export default UserActionDialog
