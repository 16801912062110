import React from 'react'
import { TaskGroupsLoader } from 'loaders'
import TaskGroupsDashboard from './TaskGroupsDashboard'

const TaskGroups = () => (
  <TaskGroupsLoader>
    <TaskGroupsDashboard />
  </TaskGroupsLoader>
)

export default TaskGroups
