import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import { drills } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import filterObject from 'utils/filterObject'
import getTargetTranslationFields from 'utils/getTargetTranslationFields'
import DrillCreateEditForm from 'containers/ActivityCreateContainer/components/DrillCreateEditForm'
import { ACTIVITIES_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const FIELDS_WITH_TRANSLATION = [
  'title',
  'description',
  'drill_flow',
  'organization',
  'coaching_points',
  'variations',
]

const DrillEditContainer = ({
  history,
  match: {
    params: { teamUuid, drillUuid },
  },
  match,
  location: { fromUrl, fromPathname },
  drill: { translations, drillsCategories, ...drill },
  updateDrill,
  deleteDrill,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState('en')

  const linkBack =
    fromUrl ||
    createSubUrl(match, ACTIVITIES_ROUTE.populateURLParams({ drillUuid }))

  const onSubmit = ({
    duration,
    min_players: minPlayers,
    max_players: maxPlayers,
    images,
    title,
    description,
    organization,
    drill_flow: drillFlow,
    coaching_points: coachingPoints,
    variations,
    ...values
  }) => {
    const data = {
      ...values,
      duration: +duration,
      min_players: +minPlayers,
      max_players: +maxPlayers,
      team_id: teamUuid,
      title: title !== undefined ? title : '',
      description: description !== undefined ? description : '',
      organization: organization !== undefined ? organization : '',
      drill_flow: drillFlow !== undefined ? drillFlow : '',
      coaching_points: coachingPoints !== undefined ? coachingPoints : '',
      variations: variations !== undefined ? variations : '',
    }

    if (!images) {
      data[`images[0]`] = null
    } else if (typeof images === 'string') {
      data[`images[0]`] = drill.images[drill.images.length - 1].id
    } else {
      data[`images[0]`] = images
    }

    if (title !== '' && title !== undefined) {
      updateDrill(drillUuid, data)
    }
  }

  // This is needed because after update and in response drill.age_class contains array of string of age class names and not ids
  // But we need array of age class ids to bind auto checkboxes in AgeClassSelector
  const getAgeClassIds = (ageclasses) => {
    const ageClassesIds = ageclasses.map((ageclass) => ageclass.id)

    return ageClassesIds
  }

  const initialValues = {
    global: 0,
    ...filterObject(drill, Boolean),
    ...getTargetTranslationFields(
      translations,
      selectedLanguage,
      FIELDS_WITH_TRANSLATION
    ),
    locale: selectedLanguage,
    age_class: getAgeClassIds(drill.age_classes),
    images:
      drill && drill?.images.length > 0
        ? drill.images[drill.images.length - 1].full_url
        : null,
  }

  const handleDelete = () =>
    deleteDrill(drillUuid).then(() => history.push(linkBack))

  return (
    <Translate>
      {({ translate }) => (
        <DrillCreateEditForm
          form="drillEdit"
          initialValues={initialValues}
          caption={translate('drills.edit-drill')}
          fromPath={{
            value: linkBack,
            title: fromPathname || translate('drills.own-drills'),
          }}
          onSubmit={onSubmit}
          drillsCategories={drillsCategories}
          isEditForm
          showDeleteButton
          handleDelete={handleDelete}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
      )}
    </Translate>
  )
}

DrillEditContainer.defaultProps = {}

DrillEditContainer.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  drill: PROP_TYPES.shape().isRequired,
  updateDrill: PROP_TYPES.func.isRequired,
  deleteDrill: PROP_TYPES.func.isRequired,
}

export default compose(
  connect(
    ({ drills: { current: drill } }) => ({
      drill,
    }),
    {
      updateDrill: drills.updateDrill,
      deleteDrill: drills.deleteDrill,
    }
  ),
  withLocalize
)(DrillEditContainer)
