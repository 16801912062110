import React from 'react'
import { Translate } from 'react-localize-redux'

const plansData = [
  {
    value: 'basic',
    selectedLabelId: 'plans.basic.label',
    labelId: 'plans.coachbetter',
    sub_labelId: '',
    monthly: 0,
    annual: 0,
    benefitsId: [
      "plans.basic-benefits.1",
      "plans.basic-benefits.2",
      "plans.basic-benefits.3",
      "plans.basic-benefits.4",
      "plans.basic-benefits.5",
      "plans.basic-benefits.6",
      "plans.basic-benefits.7",
      "plans.basic-benefits.8",
    ],
    benefitsDescId: "plans.basic.benefitsDesc",
  },
  {
    value: 'plus',
    selectedLabelId: 'plans.pro.label',
    labelId: 'plans.coachbetter',
    sub_labelId: 'plans.pro.plus',
    monthly: 19.90,
    annual: 199.00,
    benefitsId: [
      "plans.pro-benefits.1",
      "plans.pro-benefits.2",
      "plans.pro-benefits.3",
      "plans.pro-benefits.4",
      "plans.pro-benefits.5",
      "plans.pro-benefits.6",
      "plans.pro-benefits.7",
      "plans.pro-benefits.8",
      "plans.pro-benefits.9",
      "plans.pro-benefits.10",
      "plans.pro-benefits.11",

    ],
    benefitsDescId: "plans.pro.benefitsDesc",
  },
  {
    value: 'elite',
    selectedLabelId: 'plans.elite.label',
    labelId: 'plans.coachbetter',
    sub_labelId: 'plans.elite.pro',
    monthly: 29.90,
    annual: 299.00,
    benefitsId: [
        "plans.elite-benefits.1",
        "plans.elite-benefits.2",
        "plans.elite-benefits.3",
        "plans.elite-benefits.4",
        "plans.elite-benefits.5",
        "plans.elite-benefits.6",
        "plans.elite-benefits.7",
        "plans.elite-benefits.8",
        "plans.elite-benefits.9",
        "plans.elite-benefits.10",
        "plans.elite-benefits.11",
        "plans.elite-benefits.12",
        "plans.elite-benefits.13",
        "plans.elite-benefits.14",
        "plans.elite-benefits.15",
    ],
    benefitsDescId: "plans.elite.benefitsDesc",
  },
]
export const plans = plansData.map(( plan ) => ({
value: plan.value,
selectLabel: <Translate id={plan.selectedLabelId} />,
label: <Translate id={plan.labelId} /> ,
sub_label: plan.sub_labelId ? <Translate id={plan.sub_labelId} /> : plan.sub_labelId,
benefitsDesc: <Translate id={plan.benefitsDescId} />,
benefits: plan.benefitsId.map((benefitId) => <Translate key={benefitId} id={benefitId} />),
monthly: plan.monthly,
annual: plan.annual,

}))



export const billingCycle = [
  {value: 'monthly', label: <Translate id="plans.monthly" />,period:'month'},
  {value: 'annual', label: <Translate  id="plans.annual" /> ,period:'year'},
]

export const seats = [
  {
    plan: 'basic',
    seat: 2,
  },
  {
    plan: 'plus',
    seat: 5,
  },
  {
    plan: 'elite',
    seat: 1000,
  },
  {
    plan: 'champion',
    seat: 1000,
  },
]
// @todo Replace ELITE with PRO when backend send 'pro' instead of 'elite'
export const PRO = "ELITE"
export const PLUS = "PLUS"
export const BASIC = "BASIC"