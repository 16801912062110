import React, { useEffect, useState } from 'react'
import { Typography, IconButton, Box, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Translate } from 'react-localize-redux'
import {
  deleteTrainingDrill,
  updateTrainingDrill,
  updateTrainingSessionDrills,
} from 'containers/ActivitiesContainer/api'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import DrillViewDialog from 'components/DrillCard/components/DrillViewDialog'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import CloseIcon from '@material-ui/icons/Close'
import fallbackImageUrl from '../../assets/images/login-coach.jpg'
import { DraggableItemContainer, DurationControls } from './styles'

const TrainingPlan = ({
  sessionDrills,
  handleShareClick,
  trainingUuid,
  onUpdateDrills,
}) => {
  const [phasesWithDrills, setPhasesWithDrills] = useState([])
  const [totalDuration, setTotalDuration] = useState(0)
  const [selectedDrill, setSelectedDrill] = useState(false)
  const [drillViewDialogOpen, setDrillViewDialogOpen] = useState(false)

  useEffect(() => {
    setPhasesWithDrills([
      {
        key: 'warmup',
        label: <Translate id="training.warmup" />,
        items: sessionDrills.filter((drill) => drill.session_part === 'warmup'),
      },
      {
        key: 'main_part',
        label: <Translate id="training.main_part" />,
        items: sessionDrills.filter(
          (drill) => drill.session_part === 'main_part'
        ),
      },
      {
        key: 'final_part',
        label: <Translate id="training.final_part" />,
        items: sessionDrills.filter(
          (drill) => drill.session_part === 'final_part'
        ),
      },
    ])
  }, [sessionDrills])

  const calculateTotalDuration = (phases) => {
    return phases
      .flatMap((phase) => phase.items)
      .reduce((total, item) => total + (item.customized_duration || 0), 0)
  }

  useEffect(() => {
    const newTotalDuration = calculateTotalDuration(phasesWithDrills)
    setTotalDuration(newTotalDuration)
  }, [phasesWithDrills])

  const handleDeleteDrill = (drillUuid) => {
    deleteTrainingDrill(drillUuid)
      .then(() => {
        onUpdateDrills((prevDrills) =>
          prevDrills.filter((drill) => drill.uuid !== drillUuid)
        )
      })
      .catch((error) => {
        console.error('Failed to delete drill:', error)
      })
  }

  const handleUpdateDuration = (drillUuid, newDuration) => {
    const updatedPhases = phasesWithDrills.map((phase) => {
      const updatedItems = phase.items.map((item) =>
        item.uuid === drillUuid
          ? { ...item, customized_duration: newDuration }
          : item
      )

      return { ...phase, items: updatedItems }
    })

    setPhasesWithDrills(updatedPhases)
    onUpdateDrills((prevDrills) =>
      prevDrills.map((drill) =>
        drill.uuid === drillUuid
          ? { ...drill, customized_duration: newDuration }
          : drill
      )
    )
    updateTrainingDrill(drillUuid, { customized_duration: newDuration }).catch(
      (error) => {
        console.error('Failed to update duration:', error)
      }
    )
  }

  const handleDecreaseDuration = (drill) => {
    if (drill.customized_duration > 0) {
      const newDuration = drill.customized_duration - 1
      handleUpdateDuration(drill.uuid, newDuration)
    }
  }

  const handleIncreaseDuration = (drill) => {
    const newDuration = drill.customized_duration + 1
    handleUpdateDuration(drill.uuid, newDuration)
  }

  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) return

    const sourcePhase = phasesWithDrills.find(
      (phase) => phase.key === source.droppableId
    )
    const destinationPhase = phasesWithDrills.find(
      (phase) => phase.key === destination.droppableId
    )

    if (!sourcePhase || !destinationPhase) return

    const sourceItems = [...sourcePhase.items]
    const destinationItems = [...destinationPhase.items]
    const [movedDrill] = sourceItems.splice(source.index, 1)

    if (sourcePhase.key !== destinationPhase.key) {
      movedDrill.session_part = destinationPhase.key
      destinationItems.splice(destination.index, 0, movedDrill)
    } else {
      sourceItems.splice(destination.index, 0, movedDrill)
    }

    const updateDrillOrders = (items) =>
      items.map((drill, index) => ({ ...drill, order: index + 1 }))

    const updatedPhasesWithDrills = phasesWithDrills.map((phase) => {
      if (phase.key === sourcePhase.key) {
        return { ...phase, items: updateDrillOrders(sourceItems) }
      }
      if (phase.key === destinationPhase.key) {
        return { ...phase, items: updateDrillOrders(destinationItems) }
      }

      return phase
    })
    setPhasesWithDrills(updatedPhasesWithDrills)

    const allUpdatedDrills = updatedPhasesWithDrills.flatMap(
      (phase) => phase.items
    )
    onUpdateDrills(allUpdatedDrills)

    updateTrainingSessionDrills(trainingUuid, {
      training_drills: allUpdatedDrills,
    }).catch((error) => {
      console.error('Failed to update drill order:', error)
      setPhasesWithDrills((prevPhases) =>
        prevPhases.map((phase) => {
          if (phase.key === sourcePhase.key) {
            return { ...phase, items: sourcePhase.items }
          }
          if (phase.key === destinationPhase.key) {
            return { ...phase, items: destinationPhase.items }
          }

          return phase
        })
      )
    })
  }
  const handleDrillClick = (item) => {
    setSelectedDrill(item.relationships.drill)
    setDrillViewDialogOpen(true)
  }

  const toggleDrillViewDialogOpen = () =>
    setDrillViewDialogOpen(!drillViewDialogOpen)

  return (
    <>
      {drillViewDialogOpen && (
        <DrillViewDialog
          drill={selectedDrill}
          hanldeOpenClose={toggleDrillViewDialogOpen}
          open={drillViewDialogOpen}
        />
      )}
      <Box
        style={{
          padding: '14px 16px',
          borderBottom: '1px solid #e8e8e8',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3">
          <Translate id="training.session-plan" />
        </Typography>
        <Button
          onClick={handleShareClick}
          variant="contained"
          color="primary"
          size="small"
        >
          <Translate id="button.download" />
        </Button>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        {phasesWithDrills.map((phase) => (
          <Droppable droppableId={phase.key} key={phase.key}>
            {(droppableProvided) => (
              <Box
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                style={{
                  borderBottom: '1px solid #ccc',
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  mb={2}
                  color="textSecondary"
                  style={{
                    fontWeight: 600,
                    textAlign: 'left',
                    width: '100%',
                  }}
                >
                  {phase.label}
                </Typography>

                {phase.items.length > 0 ? (
                  phase.items.map((item, index) => (
                    <Draggable
                      draggableId={item.uuid}
                      index={index}
                      key={item.uuid}
                    >
                      {(draggableProvided) => (
                        <DraggableItemContainer
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          p={2}
                          mb={2}
                          border="1px solid #ddd"
                          borderRadius="12px"
                          boxShadow="0 1px 3px rgba(0,0,0,0.1)"
                        >
                          <Box display="flex">
                            <DragIndicatorIcon
                              style={{
                                marginRight: 8,
                                marginTop: '34px',
                                color: '#757575',
                              }}
                            />
                            <Box
                              style={{
                                width: '65px',
                                height: '65px',
                                minWidth: '65px',
                                minHeight: '65px',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '8px',
                                marginRight: '8px',
                              }}
                            >
                              <img
                                src={
                                  item.relationships?.drill?.relationships
                                    ?.images?.[0]?.full_url || fallbackImageUrl
                                }
                                alt={item.relationships.drill.title}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                  objectPosition: 'center',
                                }}
                              />
                            </Box>
                            <Box flexGrow={1} overflow="hidden">
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                overflow="hidden"
                                whiteSpace="nowrap"
                              >
                                <Typography
                                  noWrap
                                  variant="body2"
                                  color="textPrimary"
                                  fontWeight="bold"
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    minWidth: 0,
                                    flexGrow: 1,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleDrillClick(item)}
                                >
                                  {item.relationships.drill.title}
                                </Typography>
                                <IconButton
                                  onClick={() => handleDeleteDrill(item.uuid)}
                                  size="small"
                                  sx={{ marginLeft: 1 }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                              <Box display="flex" alignItems="center" gap={1}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {item.relationships.drill.duration} min
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  style={{ margin: '0 4px' }}
                                >
                                  •
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {item.relationships.drill.min_players}P -{' '}
                                  {item.relationships.drill.max_players}P
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="end">
                            <DurationControls>
                              <IconButton
                                onClick={() => handleDecreaseDuration(item)}
                                size="small"
                              >
                                <RemoveIcon />
                              </IconButton>
                              <Typography
                                variant="body2"
                                mx={1}
                                style={{ padding: '0 10px' }}
                              >
                                {item.customized_duration} mins
                              </Typography>
                              <IconButton
                                onClick={() => handleIncreaseDuration(item)}
                                size="small"
                              >
                                <AddIcon />
                              </IconButton>
                            </DurationControls>
                          </Box>
                        </DraggableItemContainer>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <Box style={{ margin: 40 }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      <Translate id="training.drag-drills" />
                    </Typography>
                  </Box>
                )}

                {droppableProvided.placeholder}
              </Box>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h4">
          <Translate id="training.training-duration" />
        </Typography>
        <Typography>
          <strong>{totalDuration} mins</strong>
        </Typography>
      </Box>
    </>
  )
}

TrainingPlan.propTypes = {
  sessionDrills: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      session_part: PropTypes.string.isRequired,
      customized_duration: PropTypes.number.isRequired,
      drill: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  handleShareClick: PropTypes.func.isRequired,
  trainingUuid: PropTypes.string.isRequired,
  isTrainingSeries: PropTypes.bool.isRequired,
  onUpdateDrills: PropTypes.func.isRequired,
}

export default TrainingPlan
