import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { trainings } from 'redux/modules'
import { toast } from 'react-toastify'
import { Translate } from 'react-localize-redux'
import { Grid } from '@material-ui/core'
import GROUPS from '../../groupConstant'
import {
  GroupsList,
  NotAvailable,
  NotResponded,
  PlayersList,
} from './components'
import PROP_TYPES from 'constants/propTypes'
import { PLAYER_POSITIONS } from 'constants/player'

const GroupsAndParticipationContainer = ({
  players,
  groupsCount = 4,
  updateParticipant,
  onChange,
  updateParticipantsInBulk,
  currentTraining,
}) => {
  const preparedTrainingPlayers = useMemo(
    () =>
      players.map((trainingPlayer) => {
        return {
          ...trainingPlayer,
          isSelected: trainingPlayer.isSelected ?? false,
        }
      }),
    [players]
  )

  const [selectedPlayers, setSelectedPlayers] = useState([])

  const notAvailablePlayers = useMemo(() =>
    preparedTrainingPlayers.filter(
      (player) => player.availability === 'NOT_AVAILABLE'
    )
  )

  const notRespondedPlayers = useMemo(() =>
    preparedTrainingPlayers.filter(
      (player) => player.availability === 'NOT_RESPONDED'
    )
  )

  const availablePlayers = useMemo(() =>
    preparedTrainingPlayers.filter(
      (player) => player.availability === 'AVAILABLE'
    )
  )

  const playersWithPosition = (playerPosition) =>
    preparedTrainingPlayers.filter(
      (player) =>
        player.availability === 'AVAILABLE' &&
        player.group_id === null &&
        player.relationships?.player?.position === playerPosition
    )

  const isAnyPlayerSelected = useMemo(
    () => players.some(({ isSelected }) => isSelected),
    [players]
  )

  const changeIsSelectedById = (player) => {
    setSelectedPlayers((prevSelectedPlayers) => {
      const isAlreadySelected = prevSelectedPlayers.includes(player.uuid)

      const newSelectedPlayers = isAlreadySelected
        ? prevSelectedPlayers.filter((uuid) => uuid !== player.uuid)
        : [...prevSelectedPlayers, player.uuid]

      onChange((prevPlayers) =>
        prevPlayers.map((p) =>
          p.uuid === player.uuid ? { ...p, isSelected: !isAlreadySelected } : p
        )
      )

      return newSelectedPlayers
    })
  }

  const changeIsSelectedByRelation = (relation, isSelected) => {
    const updatedPlayers = preparedTrainingPlayers.map((trainingPlayer) => {
      if (trainingPlayer.relationships.player.position === relation) {
        return { ...trainingPlayer, isSelected }
      }

      return trainingPlayer
    })

    onChange((prevPlayers) => {
      const newSelectedPlayers = updatedPlayers
        .filter((player) => player.isSelected)
        .map((player) => player.uuid)

      setSelectedPlayers(newSelectedPlayers)

      return prevPlayers.map((player) => {
        const updatedPlayer = updatedPlayers.find(
          (updated) => updated.uuid === player.uuid
        )

        return updatedPlayer ? { ...player, ...updatedPlayer } : player
      })
    })
  }

  const onDragEnd = useCallback(
    (result) => {
      const { destination, draggableId } = result

      if (!destination) {
        return
      }

      const playerUuid = draggableId

      let availabilityStatus = 'AVAILABLE'
      let groupId = null

      if (destination.droppableId === 'notResponded') {
        availabilityStatus = 'NOT_RESPONDED'
      } else if (destination.droppableId === 'notAvailable') {
        availabilityStatus = 'NOT_AVAILABLE'
      } else if (
        PLAYER_POSITIONS.some(
          (position) => position.value === destination.droppableId
        )
      ) {
        availabilityStatus = 'AVAILABLE'
      } else {
        groupId =
          typeof destination.droppableId === 'string'
            ? GROUPS.find((group) => group.value === destination.droppableId)
                ?.groupId
            : GROUPS[destination.droppableId]?.groupId
      }

      const playerData = {
        availability: availabilityStatus,
        group_id: groupId,
      }

      const updatedPlayers =
        selectedPlayers.length > 1
          ? selectedPlayers.map((uuid) => ({
              uuid,
              ...playerData,
            }))
          : [{ uuid: playerUuid, ...playerData }]

      if (selectedPlayers.length > 1) {
        updateParticipantsInBulk(currentTraining, {
          participants: updatedPlayers,
        }).catch((error) => {
          console.error('Error updating participants:', error)
          toast.error(error.message)
        })
      } else {
        updateParticipant(playerUuid, playerData).catch((error) => {
          console.error('Error updating participants:', error)
          toast.error(error.message)
        })
      }

      onChange((prevPlayers) =>
        prevPlayers.map((player) => {
          const updatedPlayer = updatedPlayers.find(
            (updated) => updated.uuid === player.uuid
          )

          return updatedPlayer
            ? { ...player, ...updatedPlayer, isSelected: false }
            : player
        })
      )
      setSelectedPlayers([])
    },
    [
      selectedPlayers,
      currentTraining,
      updateParticipant,
      updateParticipantsInBulk,
      onChange,
    ]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <GroupsList
        groupsCount={groupsCount}
        players={availablePlayers}
        changeIsSelectedById={changeIsSelectedById}
        isAnyPlayerSelected={isAnyPlayerSelected}
      />
      <Translate>
        {({ translate }) => (
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            {PLAYER_POSITIONS.map((position) => (
              <Grid item xs={12} sm={6} md={3} key={position.value}>
                <PlayersList
                  relation={position.value}
                  players={playersWithPosition(position.value)}
                  name={translate(`player.${position.value}`)}
                  image={position.image}
                  changeIsSelectedById={changeIsSelectedById}
                  changeIsSelectedByRelation={changeIsSelectedByRelation}
                  isAnyPlayerSelected={isAnyPlayerSelected}
                  updateParticipantsInBulk={updateParticipantsInBulk}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Translate>
      <NotAvailable
        players={notAvailablePlayers}
        changeIsSelectedById={changeIsSelectedById}
        isAnyPlayerSelected={isAnyPlayerSelected}
      />
      <NotResponded
        players={notRespondedPlayers}
        changeIsSelectedById={changeIsSelectedById}
        isAnyPlayerSelected={isAnyPlayerSelected}
      />
    </DragDropContext>
  )
}

GroupsAndParticipationContainer.defaultProps = {
  players: [],
  groupsCount: 4,
}

GroupsAndParticipationContainer.propTypes = {
  players: PROP_TYPES.arrayOfObjects,
  onChange: PROP_TYPES.func.isRequired,
  groupsCount: PropTypes.oneOf([2, 3, 4]),
  updateParticipant: PropTypes.func.isRequired,
  updateParticipantsInBulk: PropTypes.func.isRequired,
  currentTraining: PropTypes.string.isRequired,
}

export default connect(
  ({ trainings: { current } }) => ({
    currentTraining: current.uuid,
  }),
  {
    updateParticipant: trainings.updateTrainingParticipant,
    updateParticipantsInBulk: trainings.updateTrainingParticipantsInBulk,
  }
)(GroupsAndParticipationContainer)
