import { Paper } from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filtersContainer: {
    display: 'flex',
    justifyItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  searchBar: {
    background: '#f2f2f2',
    maxWidth: '500px',
  },
  formControl: {
    marginTop: 0,
    maxWidth: 100,
    marginBottom: 0,
    marginRight: 100,
    '& .MuiInputBase-formControl': {
      height: 48,
      minWidth: 180,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  tablePagination: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
}))

export const MenuPaper = styled(Paper)({
  border: `1px solid ${grey[300]}`,
  boxShadow: `1px 1px 12px 0 ${grey[400]}`,
})
