import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ageClasses } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const AgeClassesLoader = ({ children, currentClub, fetchClubAgeClasses }) => {
  return (
    <Loader fetchMethod={() => fetchClubAgeClasses(currentClub.id)}>
      {children}
    </Loader>
  )
}

AgeClassesLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchClubAgeClasses: PropTypes.func.isRequired,
  currentClub: PROP_TYPES.shape({
    id: PROP_TYPES.id.isRequired,
  }).isRequired,
}

export default connect(
  ({ clubs: { current } }) => ({
    currentClub: current,
  }),
  {
    fetchClubAgeClasses: ageClasses.fetchClubAgeClasses,
  }
)(AgeClassesLoader)
