import React from 'react'
import PropTypes from 'prop-types'
import requiredIf from 'react-required-if'
import { withRouter, Link } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import urlConstructor from 'utils/urlConstructor'
import PROP_TYPES from 'constants/propTypes'

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}))

const SubLink =
  (
    {
      children,
      className,
      to,
      fromPathname,
      isFullPath,
      match,
      target,
      onClick,
      ...restProps
    }
  ) => {
    const {
      params: { teamUuid, seasonId },
      url: fromUrl,
    } = match

    const pathname = isFullPath ? to : urlConstructor(teamUuid, seasonId, to)

    return (
      <StyledLink
        className={className}
        to={{
          pathname,
          fromUrl,
          fromPathname,
          ...restProps,
        }}
        onClick={onClick}
        target={target}
      >
        {children}
      </StyledLink>
    )
  }

SubLink.defaultProps = {
  children: <div />,
  className: null,
  to: '',
  target: '',
  fromPathname: undefined,
  isFullPath: false,
  isExternal: false,
  onClick: () => {},
}

SubLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  fromPathname: PropTypes.string,
  target: PropTypes.string,
  isFullPath: PropTypes.bool,
  isExternal: PropTypes.bool,
  match: PROP_TYPES.match.isRequired,
  onClick: requiredIf(
    PropTypes.func,
    (props) => !props.to && PropTypes.onClick !== null
  ),
}

export default withRouter(SubLink)
