import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { seasons } from 'redux/modules'
import Loader from './Loader'

const getSelectedTeamId = (teams, match) => {
  const {
    params: { teamUuid },
  } = match

  if (teamUuid) {
    return teamUuid
  }

  const firstTeam = teams.items.find(Boolean)

  return firstTeam ? firstTeam.uuid : null
}

const SeasonsLoader = props => {
  const { children, fetchSeasons, teams, match, ...restProps } = props

  const teamUuid = getSelectedTeamId(teams, match)

  const fetchMethod = () => fetchSeasons(teamUuid)
  const triggers = [teamUuid]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

SeasonsLoader.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.shape({
      params: PropTypes.shape().isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
  teams: PropTypes.shape().isRequired,
  fetchSeasons: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ teams }) => ({ teams }),
    { fetchSeasons: seasons.fetchSeasons }
  )
)(SeasonsLoader)
