import { Paper, Divider } from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import PropTypes from 'prop-types'
import VideoCard from './VideoCard'
import useStyles from './useStyles'

const Completed = ({ videos }) => {
  const classes = useStyles()

  return (
    <>
      {videos.length > 0 && (
        <Paper variant="outlined" className={classes.paperRoot}>
          <InnrHead title={<Translate id="videos.completed" />} />
          <Divider />
          <VideoCard videos={videos} relation="completed" />
        </Paper>
      )}
    </>
  )
}

Completed.defaultProps = {
  videos: [],
}

Completed.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object.isRequired),
}

export default Completed
