import React from 'react'
import {
  VideosLoader,
  SequencesLoader,
  PlayersLoader,
  FavoritesLoader,
} from 'loaders'
import { Grid } from '@material-ui/core'
import { VideosUploaded, Sequences } from './components'
import CheckNotProcessedVideos from './CheckNotProcessedVideos'

const VideoAnalysis = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={5} lg={4}>
      <VideosLoader alwaysRenderChildren passIsLoading>
        <FavoritesLoader entity="videos">
          <CheckNotProcessedVideos>
            <VideosUploaded />
          </CheckNotProcessedVideos>
        </FavoritesLoader>
      </VideosLoader>
    </Grid>
    <Grid item xs={12} sm={12} md={7} lg={8}>
      <PlayersLoader alwaysRenderChildren passIsLoading>
        <FavoritesLoader entity="sequences" alwaysRenderChildren passIsLoading>
          <SequencesLoader>
            <Sequences />
          </SequencesLoader>
        </FavoritesLoader>
      </PlayersLoader>
    </Grid>
  </Grid>
)

export default VideoAnalysis
