import React from 'react'
import { TrainingLoader } from 'loaders'
import TrainingDashboardContainer from 'components/TrainingDashboard/TrainingDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const TrainingDashboard = ({
  match: {
    params: { trainingUuid },
  },
}) => (
  <TrainingLoader
    trainingUuid={trainingUuid}
    additionalFields={[
      'players',
      'session_drills',
      'drill_categories',
      'trainings',
    ]}
    alwaysRenderChildren
    passIsLoading
  >
    <TrainingDashboardContainer />
  </TrainingLoader>
)

TrainingDashboard.propTypes = {
  match: PROP_TYPES.match.isRequired,
}

export default TrainingDashboard
