import {
  getRequest,
  deleteRequest,
  postRequest,
  patchRequest,
} from 'utils/axios'

export const fetchAllMembers = (teamUuid, filterParams = {}) => {
  const url = `v2/teams/${teamUuid}/members`

  return getRequest(url, filterParams)
}

export const removeUserRole = (uhrUuid) => {
  const url = `v2/remove-member-role/${uhrUuid}`

  return deleteRequest(url)
}

export const sendInviteUrl = (teamUuid, data) => {
  const url = `v2/teams/${teamUuid}/invite-staff`

  return postRequest(url, data)
}

export const fetchInvites = (teamUuid) => {
  const url = `v2/teams/${teamUuid}/pending-invites`

  return getRequest(url)
}

export const fetchRoles = (teamUuid) => {
  const url = `v2/teams/${teamUuid}/staff-roles`

  return getRequest(url)
}
export const resendInvite = (inviteUuid, data) => {
  const url = `v2/invites/${inviteUuid}/re-send`

  return patchRequest(url, data)
}

export const removeInvite = (inviteUuid) => {
  const url = `v2/invites/${inviteUuid}`

  return deleteRequest(url)
}
