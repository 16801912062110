import React from 'react'
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Input, SelectedMenu } from 'components'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Translate } from 'react-localize-redux'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { TASK_GROUP_QUESTION_TYPES } from 'constants/taskGroups'


const AddEditQuestions = ({
  taskGroup,
  questions,
  handleQuestion,
  addNewQuestion,
  setQuestions,
  setTaskGroup,
}) => {
  const handleDelete = (element) => {
    setQuestions(
      questions.filter(
        (value) =>
          value.id !== element.id ||
          value.question !== element.question ||
          value.questionType !== element.questionType
      )
    )
    if (questions.length <= 1) {
      setTaskGroup({
        ...taskGroup,
        error_questions: false,
      })
    }
  }

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        <Typography variant="h4" style={{ marginBottom: '.5rem' }}>
          <Translate id="task-management.questions-title" />
        </Typography>
        {questions?.map((ques, index) => (
          <>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <SelectedMenu
                  id="question-type"
                  name="questionType"
                  value={ques.questionType}
                  margin="dense"
                  disabled={taskGroup?.completed}
                  required={Boolean(true)}
                  onChange={(event) => handleQuestion(event, index)}
                  label={<Translate id="task-management.question-type-label" />}
                >
                  {TASK_GROUP_QUESTION_TYPES.map((p) => (
                    <MenuItem key={p.type} value={p.type}>
                      {p.title}
                    </MenuItem>
                  ))}
                </SelectedMenu>

                <Input
                  label={<Translate id="task-management.question-label" />}
                  margin="dense"
                  onChange={(event) => handleQuestion(event, index)}
                  name="question"
                  value={ques.question}
                  required
                  disabled={taskGroup?.completed}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltip
                  title={<Translate id="button.delete" />}
                  placement="top"
                  arrow
                >
                  <IconButton
                    onClick={() => handleDelete(ques)}
                    disabled={taskGroup?.completed}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="text"
          color="primary"
          onClick={addNewQuestion}
          disabled={taskGroup?.completed}
        >
          <AddIcon style={{ fontSize: 20 }} />{' '}
          <Translate id="task-management.add-question" />
        </Button>
      </Grid>
      {taskGroup?.error_questions && (
        <Grid item xs={12}>
          <Alert severity="error">
            <Translate id="task-management.task-question.question-validation" />
          </Alert>
        </Grid>
      )}
    </>
  )
}
AddEditQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  handleQuestion: PropTypes.func.isRequired,
  addNewQuestion: PropTypes.func.isRequired,
  setQuestions: PropTypes.func.isRequired,
  taskGroup: PropTypes.shape().isRequired,
  setTaskGroup: PropTypes.func.isRequired,
}
export default AddEditQuestions
