import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { subscription } from 'redux/modules'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import urlConstructor from 'utils/urlConstructor'
import PROP_TYPES from 'constants/propTypes'

const Invoice = ({
  retriveSessionOfBilling,
  history,
  match: {
    params: { teamUuid, seasonId },
  },
}) => {
  const [response, setResponce] = useState(null)

  useEffect(() => {
    const stripeSessionId = localStorage.getItem('stripe_sessionId')

    if (stripeSessionId) {
      retriveSessionOfBilling(
        `&checkout_session=${stripeSessionId}&capture=true`
      )
        .then((res) => {
          setResponce(res.response.data)

          // Redirect to the team homepage page after 2 seconds
          setTimeout(() => {
            window.location.href = urlConstructor(teamUuid, seasonId)
          }, 2000) // 2-second delay
        })
        .catch((error) => {
          setResponce({ payment_status: 'unpaid' })
        })
    } else {
      history.push('/teams')
    }

    return function cleanup() {
      localStorage.removeItem('stripe_sessionId')
    }
  }, [])

  return (
    <div>
      Invoice:{' '}
      {response && (
        <h1>
          {response && response.payment_status === 'unpaid'
            ? 'Error please try again.'
            : `Thank you`}
        </h1>
      )}
    </div>
  )
}

Invoice.propTypes = {
  retriveSessionOfBilling: PROP_TYPES.func.isRequired,
  history: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.shape({
    params: PROP_TYPES.shape({
      teamUuid: PROP_TYPES.string,
      seasonId: PROP_TYPES.string,
    }),
  }).isRequired,
}

export default compose(
  withRouter,
  connect(null, {
    retriveSessionOfBilling: subscription.retriveSessionOfBilling,
  })
)(Invoice)
