import {
  Grid,
  Box,
  FormGroup,
  FormLabel,
  Button,
  FormControl,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Snackbars, SelectedMenu } from 'components'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { subscription } from 'redux/modules'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { compose } from 'redux'
import StripeCheckout from 'StripeCheckout'
import axios from 'axios'
import CircularSpinner from 'components/loader/CircularSpinner'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import PropTypes from 'prop-types'
import PricingBilling from './PricingBilling'
import { useStyles, StyledPricingPaper, InputBaseStyle } from './useStyles'
import { plans, billingCycle } from 'constants/plans'

const currencyLabel = [
  { name: 'usd', label: '$' },
  { name: 'eur', label: '€' },
  { name: 'chf', label: 'CHF' },
]

const PricingDashboardContainer = ({
  team,
  updateSubscription,
  deleteSubscription,
  resumeSubscription,
  updateSubscriptionTrial,
  fetchTeamSubscription,
}) => {
  const classes = useStyles()
  const [successPayment, setSuccessPayment] = useState(false)
  const [selectedGateway, setSelectedGateway] = React.useState('stripe')
  const [loadingcircular, setLoadingCircular] = useState(false)
  const [gatewayPlanId, setGatewayPlanId] = useState('')
  const [Snackbar, changeSnackbar] = useState({
    show: false,
    message: '',
    error: false,
    info: false,
    warning: false,
  })
  const [currentSubscription, setCurrentSubscription] = useState({})
  const [subscriptionPlan, setSubscriptionPlan] = React.useState(null)

  const setCurrency = (curr) => {
    const curlab = currencyLabel.filter((cur) => {
      return curr === cur.name
    })
    if (curlab) {
      return curlab[0].label
    }

    return '$'
  }

  useEffect(() => {
    fetchTeamSubscription(team.uuid).then((res) => {
      const tempSub = res.response.data
      setCurrentSubscription(tempSub)

      setSubscriptionPlan({
        plan: plans.filter((plan) => {
          return plan.value === tempSub.plan.name
        })[0],
        currency: tempSub.plan ? setCurrency(tempSub.plan.currency) : '$',
        currencyName: tempSub.plan ? tempSub.plan.currency : 'usd',
        billingCycle:
          tempSub.plan.interval === 'year' ? billingCycle[1] : billingCycle[0],
        billingCycleOptions:
          tempSub.stripe_plan === 'champion' ? [billingCycle[1]] : billingCycle,
        changed: false,
      })
    })
  }, [])

  const changebillingCycle = (cycle) => {
    const newCycle = cycle === 'annual' ? billingCycle[1] : billingCycle[0]

    setSubscriptionPlan({
      ...subscriptionPlan,
      billingCycle: newCycle,
      changed: true,
    })
  }

  const changeSubscriptionPlan = (newPlans) => {
    let SelectBillingCycle = subscriptionPlan.billingCycle
    let billingCycleOptions = billingCycle

    if (newPlans === 'champion') {
      billingCycleOptions = [billingCycle[1]]

      SelectBillingCycle = { ...billingCycle[1] }
    }

    setSubscriptionPlan({
      ...subscriptionPlan,
      billingCycleOptions,
      billingCycle: SelectBillingCycle,
      plan: plans.find((p) => p.value === newPlans),
      changed: true,
    })
  }

  const cancelSub = () => {
    deleteSubscription(currentSubscription.id)
      .then(() => {
        window.location.reload()
        setLoadingCircular(false)
      })
      .catch((err) => {
        setLoadingCircular(false)
        console.error(err)
      })
  }

  const resumeSub = () => {
    resumeSubscription(currentSubscription.id)
      .then(() => {
        window.location.reload()
        setLoadingCircular(false)
      })
      .catch((err) => {
        setLoadingCircular(false)
        console.error(err)
      })
  }

  const changeSub = () => {
    const data = {
      plan: subscriptionPlan.plan.value,
      interval: subscriptionPlan.billingCycle.period,
      subscription_id: currentSubscription.id,
      currency: subscriptionPlan.currencyName,
      payment_gateway: currentSubscription.plan.gateway,
      withTrial: currentSubscription.trial_ends_at ? 0 : 1,
    }
    if (currentSubscription.stripe_id === 'sub_trial') {
      updateSubscriptionTrial(data).then(() => {
        setSubscriptionPlan({ ...subscriptionPlan, changed: false })
      })
    } else {
      updateSubscription(data)
        .then(() => {
          setLoadingCircular(false)
          window.location.reload()
        })
        .catch((err) => {
          setLoadingCircular(false)
          console.error(err)
        })
    }
  }

  const paypalApiCall = (type, requestType, requestData) => {
    axios({
      url: `${window.REACT_APP_PAYPAL_API_LINK}${currentSubscription.stripe_id}/${requestType}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      auth: {
        username: window.REACT_APP_PAYPAL_CLIENT_ID,
        password: window.REACT_APP_PAYPAL_SECRET,
      },
      data: requestData,
    })
      .then(() => {
        if (type === 'resume') {
          resumeSub()
        } else if (type === 'change') {
          changeSub()
        } else {
          cancelSub()
        }
      })
      .catch((err) => {
        console.error(err)
        setLoadingCircular(false)
        changeSnackbar({
          show: true,
          message: 'Something went wrong! Try again later.',
          error: true,
        })
      })
  }

  const updateCheckout = () => {
    setLoadingCircular(true)
    if (currentSubscription.plan.gateway === 'paypal') {
      paypalApiCall('change', 'revise', { plan_id: gatewayPlanId })
    } else {
      changeSub()
    }
  }

  const cancelPlan = () => {
    setLoadingCircular(true)
    if (currentSubscription.plan.gateway === 'paypal') {
      // Check if trial period ended or not if ended it means customer paid and he can activate the subscription again
      if (new Date(currentSubscription.trial_ends_at) < new Date()) {
        paypalApiCall('cancel', 'suspend', { reason: 'customer request' })
      } else {
        paypalApiCall('cancel', 'cancel', { reason: 'customer request' })
      }
    } else {
      cancelSub()
    }
  }

  const resumePlan = () => {
    setLoadingCircular(true)
    if (currentSubscription.plan.gateway === 'paypal') {
      paypalApiCall('resume', 'activate', { reason: 'customer request' })
    } else {
      resumeSub()
    }
  }

  const onApprove = (data) => {
    try {
      axios
        .request({
          method: 'get',
          url: `${window.REACT_APP_API_ENDPOINT}/subscription/plan/save/details`,
          params: {
            team_id: team.id,
            gateway_plan_id: gatewayPlanId,
            subscription_id: data.subscriptionID,
            interval:
              subscriptionPlan.plan.value === 'basic'
                ? 'month'
                : subscriptionPlan.billingCycle.period,
          },
        })
        .then(() => {
          setSuccessPayment(true)
          window.location.reload()
        })
    } catch (e) {
      console.error(e)
    }
  }

  const handleGatewayChange = (event) => {
    setSelectedGateway(event.target.value)
  }

  useEffect(() => {
    if (subscriptionPlan) {
      // @todo #temporaryfix check this logic later
      const gatewayName =
        subscriptionPlan.plan.value === 'basic' ? 'stripe' : 'paypal'
      axios
        .request({
          method: 'get',
          url: `${window.REACT_APP_API_ENDPOINT}/subscription/plan`,
          params: {
            interval:
              subscriptionPlan.plan.value === 'basic'
                ? 'month'
                : subscriptionPlan.billingCycle.period,
            currency:
              subscriptionPlan.plan.value === 'basic'
                ? 'usd'
                : subscriptionPlan.currencyName,
            plan: subscriptionPlan.plan.value,
            gateway: gatewayName,
            withTrial: currentSubscription.trial_ends_at ? 0 : 1,
            team_id: team.id,
          },
        })
      .then((res) => {
          const { plan } = res.data
          if (plan) {
            setGatewayPlanId(plan.gateway_plan_id)
          }
        })
    }
  }, [subscriptionPlan])

  return (
    <>
      {subscriptionPlan && (
        <PayPalScriptProvider
          options={{
            'client-id': window.REACT_APP_PAYPAL_CLIENT_ID,
            intent: 'subscription',
            vault: true,
          }}
        >
          {loadingcircular && (
            <CircularSpinner loadingcircular={loadingcircular} />
          )}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={8}>
              <PricingBilling
                activePlan={subscriptionPlan}
                changebillingCycle={changebillingCycle}
                changeSubscriptionPlan={changeSubscriptionPlan}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <StyledPricingPaper>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <FormLabel>
                                <Translate id="team.subscription-plan" />:
                              </FormLabel>
                            </TableCell>
                            <TableCell align="right">
                              <SelectedMenu
                                id="subscription-plan"
                                value={subscriptionPlan.plan.value}
                                className={classes.formControl}
                                onChange={(e) =>
                                  changeSubscriptionPlan(e.target.value)
                                }
                                label={
                                  <Translate id="team.subscription-plan" />
                                }
                              >
                                {plans.map((p) => (
                                  <MenuItem
                                    value={p.value}
                                    key={p.value}
                                    hidden={p.value === 'basic'}
                                  >
                                    {p.selectLabel}
                                  </MenuItem>
                                ))}
                              </SelectedMenu>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <FormLabel>
                                <Translate id="team.billing-cycle" />:
                              </FormLabel>
                            </TableCell>
                            <TableCell align="right">
                              <SelectedMenu
                                id="billing-cycle"
                                value={subscriptionPlan.billingCycle.value}
                                className={classes.formControl}
                                onChange={(e) =>
                                  changebillingCycle(e.target.value)
                                }
                                label={<Translate id="team.billing-cycle" />}
                              >
                                {subscriptionPlan.billingCycleOptions.map(
                                  (p) => (
                                    <MenuItem key={p.value} value={p.value}>
                                      {p.label}
                                    </MenuItem>
                                  )
                                )}
                              </SelectedMenu>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <FormLabel>
                                <Translate id="team.costs" />:
                              </FormLabel>
                            </TableCell>
                            <TableCell align="right">
                              <InputBaseStyle>
                                {`${
                                  subscriptionPlan.currency
                                } ${subscriptionPlan.plan[
                                  subscriptionPlan.billingCycle.value
                                ].toFixed(2)}`}
                              </InputBaseStyle>
                            </TableCell>
                          </TableRow>
                          {subscriptionPlan.plan.value !== 'basic' &&
                            (currentSubscription.paid === 0 ? (
                              <>
                                <TableRow>
                                  <TableCell colSpan={2} className="pb-0">
                                    <FormControl>
                                      <FormLabel id="demo-controlled-radio-buttons-group">
                                        <Translate id="checkout.payment-methods" />
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        name="controlled-radio-buttons-group"
                                        value={selectedGateway}
                                        onChange={handleGatewayChange}
                                      >
                                        <FormControlLabel
                                          value="stripe"
                                          control={<Radio />}
                                          label={
                                            <Translate id="checkout.credit-card" />
                                          }
                                        />
                                        <FormControlLabel
                                          value="paypal"
                                          control={<Radio />}
                                          label="Paypal"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                                {selectedGateway === 'paypal' ? (
                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <PayPalButtons
                                        style={{
                                          height: 32,
                                          shape: 'pill',
                                          color: 'silver',
                                          layout: 'vertical',
                                          label: 'subscribe',
                                        }}
                                        fundingSource="paypal"
                                        forceReRender={[
                                          gatewayPlanId,
                                          successPayment,
                                        ]}
                                        createSubscription={(data, actions) => {
                                          return actions.subscription.create({
                                            plan_id: gatewayPlanId,
                                          })
                                        }}
                                        onApprove={(data) => onApprove(data)}
                                        onCancel={() => {
                                          changeSnackbar({
                                            show: true,
                                            message: 'Transaction cancelled!',
                                            error: true,
                                          })
                                        }}
                                        onError={() => {
                                          changeSnackbar({
                                            show: true,
                                            message:
                                              'Something went wrong! Try again later.',
                                            error: true,
                                          })
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={2}>
                                      <StripeCheckout
                                        interval={
                                          subscriptionPlan.billingCycle.period
                                        }
                                        currency={subscriptionPlan.currencyName}
                                        planId={subscriptionPlan.plan.value}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            ) : (
                              <>
                                <TableRow>
                                  <TableCell>
                                    {subscriptionPlan.changed &&
                                      !currentSubscription.cancelled_at && (
                                        <Button
                                          variant="contained"
                                          fullWidth
                                          color="primary"
                                          onClick={updateCheckout}
                                        >
                                          <Translate id="button.change" />
                                        </Button>
                                      )}
                                  </TableCell>
                                  <TableCell align="right">
                                    <Box>
                                      {(currentSubscription.ends_at &&
                                        currentSubscription.paid) ||
                                      currentSubscription.cancelled_at ? (
                                        currentSubscription.onGracePeriod ? (
                                          <Button
                                            variant="outlined"
                                            fullWidth
                                            color="secondary"
                                            onClick={resumePlan}
                                          >
                                            <Translate id="team.resume-subscription" />
                                          </Button>
                                        ) : (
                                          <StripeCheckout
                                            interval={
                                              subscriptionPlan.billingCycle
                                                .period
                                            }
                                            currency={
                                              subscriptionPlan.currencyName
                                            }
                                            planId={subscriptionPlan.plan.value}
                                          />
                                        )
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          fullWidth
                                          style={{
                                            color: 'red',
                                            borderColor: 'red',
                                          }}
                                          onClick={cancelPlan}
                                        >
                                          <Translate id="team.cancel-subscription" />
                                        </Button>
                                      )}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <FormGroup />
                  </Grid>
                </Grid>
              </StyledPricingPaper>
            </Grid>
          </Grid>
          {Snackbar.show && <Snackbars {...Snackbar} />}
        </PayPalScriptProvider>
      )}
    </>
  )
}
PricingDashboardContainer.propTypes = {
  team: PropTypes.shape().isRequired,
  updateSubscription: PropTypes.func.isRequired,
  deleteSubscription: PropTypes.func.isRequired,
  resumeSubscription: PropTypes.func.isRequired,
  updateSubscriptionTrial: PropTypes.func.isRequired,
  fetchTeamSubscription: PropTypes.func.isRequired,
}
export default compose(
  withRouter,
  connect(
    ({ team }) => ({
      team,
    }),
    {
      updateSubscription: subscription.updateSubscription,
      deleteSubscription: subscription.deleteSubscription,
      resumeSubscription: subscription.resumeSubscription,
      updateSubscriptionTrial: subscription.updateSubscriptionTrial,
      fetchTeamSubscription: subscription.fetchTeamSubscription,
    }
  )
)(PricingDashboardContainer)
