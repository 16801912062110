const urlConstructor = (teamUuid, seasonId, restPath = '') => {
  if (teamUuid && seasonId) {
    return `/team/${teamUuid}/season/${seasonId}${
      restPath === '/' ? '' : restPath
    }`
  }

  return `${restPath}`
}

export const createSubUrl = ({ params: { teamUuid, seasonId } }, path = '') =>
  urlConstructor(teamUuid, seasonId, path)

export default urlConstructor
