import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { LoadingSpinner } from 'components'
import { fetchInvites, removeInvite, resendInvite } from '../api'
import PendingInvitesTable from './PendingInvitesTable'

const PendingInvites = ({ teamUuid }) => {
  const [pendingInvites, setPendingInvites] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetchInvites(teamUuid)
      .then((response) => {
        setPendingInvites(response.data)
      })
      .catch((error) => {
        console.error('Error fetching invites:', error)
        toast.error('Error fetching invites')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleRemoveInvite = (inviteUuid) => {
    removeInvite(inviteUuid)
      .then(() => {
        setPendingInvites((prevInvites) =>
          prevInvites.filter((invite) => invite.uuid !== inviteUuid)
        )
      })
      .catch((error) => {
        console.error('Error removing invite:', error)
        toast.error('Something went wrong')
      })
  }

  const handleResendInvite = (inviteUuid) => {
    resendInvite(inviteUuid)
      .then(() => {
        toast.success('Invite successfully sent')
      })
      .catch((error) => {
        console.error('Error resending invite:', error)
        toast.error('Failed to resend invite')
      })
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box>
          {pendingInvites.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <Typography variant="body1">
                <Translate id="team.members.no-data" />
              </Typography>
            </Box>
          ) : (
            <PendingInvitesTable
              pendingInvites={pendingInvites}
              onRemoveInvite={handleRemoveInvite}
              onResendInvite={handleResendInvite}
            />
          )}
        </Box>
      )}
    </>
  )
}

PendingInvites.propTypes = {
  teamUuid: PropTypes.string.isRequired,
}

export default connect(({ team }) => ({
  teamUuid: team.uuid,
}))(PendingInvites)
