import React from 'react'
import { connect } from 'react-redux'
import { SubLink, HeartIcon, ShareMuiIcon } from 'containers'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { articles as articlesModule } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'
import { ARTICLE_ROUTE } from 'constants/routes'

const MuiCard = withStyles((theme) => ({
  root: {
    border: '1px solid #e8e8e8',
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 2, 0),
    },
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Card)
const MuiBox = withStyles((theme) => ({
  root: {
    display: 'flex',

    '& .MuiBox-root': {
      margin: theme.spacing(0, 1.5, 0, 0),
      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.action,
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(Box)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    height: 50,
  },
  media: {
    height: 140,
    backgroundPosition: 'top',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    flex: '1 1 0',
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))
const ArticlesCarousel = ({ favoritesArticles, shareArticle }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      {favoritesArticles.map(
        ({
          id: articleId,
          title: titleValue,
          image_url: imageUrl,
          reading_time: readingTime,
        }) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={articleId}>
            <MuiCard>
              <SubLink to={ARTICLE_ROUTE.populateURLParams({ articleId })}>
                <CardMedia
                  className={classes.media}
                  image={imageUrl}
                  title="placeholder"
                />

                <CardContent className={classes.CardContent}>
                  <Typography
                    gutterBottom
                    className={classes.descriptionCell}
                    variant="h4"
                    component="h4"
                  >
                    {titleValue}
                  </Typography>
                </CardContent>
              </SubLink>
              <CardActions className={classes.cardActions}>
                <div className={classes.title}>
                  <MuiBox>
                    <Box>
                      {' '}
                      <Typography variant="span" component="span">
                        <AccessTimeIcon />
                      </Typography>{' '}
                      {`${readingTime}`} min
                    </Box>
                  </MuiBox>
                </div>
                <HeartIcon
                  isActive
                  entity="articles"
                  entityId={articleId}
                  isPositionRelative
                />
                <ShareMuiIcon
                  share={shareArticle}
                  id={articleId}
                  shareBy="Article"
                />
              </CardActions>
            </MuiCard>
          </Grid>
        )
      )}
    </Grid>
  )
}

ArticlesCarousel.defaultProps = {
  favoritesArticles: [],
}

ArticlesCarousel.propTypes = {
  favoritesArticles: PROP_TYPES.arrayOfObjects,
  shareArticle: PROP_TYPES.func.isRequired,
}

export default connect(
  ({ favorites }) => ({
    favoritesArticles: favorites.items.articles,
  }),
  {
    shareArticle: articlesModule.shareArticle,
  }
)(ArticlesCarousel)
