import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    paddingBottom: '15px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    textTransform: 'none',
    minWidth: '90px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
  },
  tabPanel: {
    padding: 0,
  },
}))

export default useStyles
