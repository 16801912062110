// Team features with key that a team can access

const TEAM_FEATURE_FLAGS = {
  // EVENTS
  events_enabled: 'events_enabled',
  create_match_enabled: 'create_match_enabled',
  advanced_match_features_enable: 'advanced_match_features_enable',
  create_general_event_enabled: 'create_general_event_enabled',
  create_training_enabled: 'create_training_enabled',
  advanced_training_features_enabled: 'advanced_training_features_enabled',
  create_training_series_enabled: 'create_training_series_enabled',
  cutoff_time_enabled: 'cutoff_time_enabled',

  // VIDEOS
  video_manager_enabled: 'video_manager_enabled',
  sequences_enabled: 'sequences_enabled',

  // TASKS
  task_manager_enabled: 'task_manager_enabled',
  task_questions_enabled: 'task_questions_enabled',
  task_group_export_enabled: 'task_group_export_enabled',

  // KNOWLEDGE
  knowledge_enabled: 'knowledge_enabled',
  create_knowledge_enabled: 'create_knowledge_enabled',
  hide_my_club_category_enabled: 'hide_my_club_category_enabled', // @mister This key is not dependent on new flags

  // ACTIVITIES
  activities_enabled: 'activities_enabled',
  create_activities: 'create_activities',
  training_packages_enabled: 'training_packages_enabled',

  // TEAM
  player_statistics_enabled: 'player_statistics_enabled',
  player_attendance_enabled: 'player_attendance_enabled',
  add_players_enabled: 'add_players_enabled',
  staff_management_enabled: 'staff_management_enabled',
  subscription_management_enabled: 'subscription_management_enabled',
  invite_players_and_parents_enabled: 'invite_players_and_parents_enabled',
  team_skills_enabled: 'team_skills_enabled',
  player_availability_enabled: 'player_availability_enabled',
  player_availability_response_enabled: 'player_availability_response_enabled',
  player_profile_export_enabled: 'player_profile_export_enabled',
  players_personal_data_export: 'players_personal_data_export',

  // CHALLENGES
  challenges_enabled: 'challenges_enabled',

  // MESSAGES
  messages_enabled: 'messages_enabled',

  // OTHER
  teasers_enabled: 'teasers_enabled',
}

export default TEAM_FEATURE_FLAGS
