import React from 'react'
import { Translate } from 'react-localize-redux'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import TrainingPlan from 'containers/TrainingPlanSession'
import PropTypes from 'prop-types'
import {
  attachTrainingDrill,
  attachTrainingPackage,
} from 'containers/ActivitiesContainer/api'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import DrillsAndTrainingPackages from '../DrillsAndTrainingPackages'

const TrainingSession = ({
  handleSave,
  trainingUuid,
  language,
  timeZone,
  sessionDrills,
  onUpdateDrills,
}) => {
  const handlePhaseSelect = (drill, selectedPhase) => {
    const param = {
      drill_uuid: drill.uuid,
      session_part: selectedPhase,
    }

    attachTrainingDrill(trainingUuid, param)
      .then((response) => {
        const drillData = response.data
        onUpdateDrills((prevDrills) => [...prevDrills, drillData])
      })
      .catch((error) => {
        console.error('Failed to attach drill to phase:', error)
      })
  }

  const handleTrainingPackageSelect = (trainingPackageUuid) => {
    const param = { training_package_uuid: trainingPackageUuid }

    attachTrainingPackage(trainingUuid, param)
      .then((response) => {
        const drillsData = response.data
        onUpdateDrills((prevDrills) => [...prevDrills, ...drillsData])
      })
      .catch((error) => {
        console.error('Error adding training package to phase:', error)
      })
  }

  const handleShareClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const shareUrl = `${process.env.REACT_APP_API_URL}/trainings/${trainingUuid}/share?language=${language}&time_zone=${timeZone}`
    window.open(shareUrl, '_blank')
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Paper
          style={{
            border: '1px solid #e8e8e8',
            boxShadow: '0px 5px 10px 0 rgba(0,0,0,0.05)',
            borderRadius: 12,
          }}
        >
          <Box style={{ padding: '16px', borderBottom: '1px solid #e8e8e8' }}>
            <Typography variant="h3">
              <Translate id="training.choose-activity" />
            </Typography>
          </Box>
          <Box style={{ padding: '20px' }}>
            <DrillsAndTrainingPackages
              onPhaseSelect={handlePhaseSelect}
              onTrainingPackageSelect={handleTrainingPackageSelect}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          style={{
            border: '1px solid #e8e8e8',
            boxShadow: '0px 5px 10px 0 rgba(0,0,0,0.05)',
            borderRadius: 12,
          }}
        >
          <TrainingPlan
            sessionDrills={sessionDrills}
            handleShareClick={handleShareClick}
            handleSave={handleSave}
            trainingUuid={trainingUuid}
            onUpdateDrills={onUpdateDrills}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

TrainingSession.propTypes = {
  trainingUuid: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  sessionDrills: PropTypes.shape().isRequired,
  onUpdateDrills: PropTypes.func.isRequired,
}

export default compose(
  connect(({ trainings: { current }, auth }) => ({
    trainingUuid: current.uuid,
    language: auth.user.language,
    timeZone: auth.user.time_zone,
  })),
  withRouter
)(TrainingSession)
