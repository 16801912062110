import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { DroppableArea } from 'components'
import {
  EmptyArea,
  Container,
  Summary,
  TrainingNotAvailable,
} from './useStyled'
import PlayersList from './PlayersList'

const NotAvailable = ({
  players,
  changeIsSelectedById,
  isAnyPlayerSelected,
}) => {
  const [isOpen, changeIsOpen] = useState(true)

  const toggleOpen = () => changeIsOpen(!isOpen)

  if (!players.length) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TrainingNotAvailable>
            <Translate id="training.not-available" />
          </TrainingNotAvailable>
        </Grid>

        <EmptyArea item xs={12}>
          <DroppableArea relation="notAvailable">
            <>
              <span>
                <Translate id="training.choose-player" />
              </span>
            </>
          </DroppableArea>
        </EmptyArea>
      </Grid>
    )
  }

  return (
    <Grid>
      <Container>
        <PlayersList
          relation="notAvailable"
          players={isOpen ? players : []}
          changeIsSelectedById={changeIsSelectedById}
          isAnyPlayerSelected={isAnyPlayerSelected}
        >
          <Summary onClick={toggleOpen} role="presentation">
            <div>{players.length}</div>
            <span>
              <Translate id="training.not-available" />
            </span>
          </Summary>
        </PlayersList>
      </Container>
    </Grid>
  )
}

NotAvailable.defaultProps = {
  players: [],
  isAnyPlayerSelected: false,
}

NotAvailable.propTypes = {
  players: PropTypes.arrayOf(PropTypes.object.isRequired),
  changeIsSelectedById: PropTypes.func.isRequired,
  isAnyPlayerSelected: PropTypes.bool,
}

export default NotAvailable
