import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  ageClasses as ageClassesModule,
  team as teamModule,
} from 'redux/modules'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import TeamEditForm from './TeamEditForm'

const TeamEditDialog = ({
  initialValues,
  open,
  handleClose,
  setInitialValues,
  updateTeam,
  fetchClubAgeClasses,
  team,
  currentClub,
}) => {
  const [teamUpdate, setTeamUpdate] = useState({
    ...initialValues,
  })
  const [images, setImages] = useState([])

  const onChange = (imageList) => setImages(imageList)

  const handleSubmit = (values) => {
    const {
      country,
      gender,
      league,
      name,
      age_class: ageClass,
      tag,
    } = { ...values }

    updateTeam(team.id, {
      country,
      gender,
      league,
      name,
      age_class: ageClass,
      tag,
      logo:
        images.length > 0
          ? images[0].file
          : teamUpdate.logo && teamUpdate.logo.full_url
          ? teamUpdate.logo.full_url
          : null,
    }).then((res) => {
      handleClose()
      setInitialValues({
        ...teamUpdate,
        logo: res.response.data.logo,
      })
      setImages([])
    })
  }

  const [ageClasses, SetAgeClasses] = useState([])

  const getAgeClasses = () => {
    fetchClubAgeClasses(currentClub.id)
      .then((res) => {
        SetAgeClasses(res.response.data)
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const handleDialogClose = () => {
    setTeamUpdate({ ...initialValues })
    handleClose()
  }
  React.useEffect(() => {
    getAgeClasses()
  }, [])

  return (
    <div>
      <TeamEditForm
        title={<Translate id="myteams.create-team" />}
        open={open}
        ageClasses={ageClasses}
        teamUpdate={teamUpdate}
        initialValues={{
          ...teamUpdate,
        }}
        onClose={handleDialogClose}
        onSubmit={handleSubmit}
        images={images}
        handleImageChange={onChange}
      />
    </div>
  )
}

TeamEditDialog.defaultProps = {}

TeamEditDialog.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setInitialValues: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  fetchClubAgeClasses: PropTypes.func.isRequired,
  team: PropTypes.shape().isRequired,
  currentClub: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default connect(
  ({ team, clubs: { current } }) => ({
    team,
    currentClub: current,
  }),
  {
    updateTeam: teamModule.updateTeam,
    fetchClubAgeClasses: ageClassesModule.fetchClubAgeClasses,
  }
)(TeamEditDialog)
