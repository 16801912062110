import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined'
import {
  Typography,
  IconButton,
  Dialog,
  Button,
  DialogContent,
  Tabs,
  Tab,
  Link,
  AppBar,
  Grid,
  DialogActions,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { InnrHead } from 'containers'
import TabPanel from 'components/material/TabPanel'
import { Snackbars as Snackbar } from 'components'
import CircularSpinner from 'components/loader/CircularSpinner'
import { track } from 'utils/segmentTracking'
import { makeStyles } from '@material-ui/core/styles'
import PERMISSIONS from '../../../constants/permissions'
import { fetchInviteUrl } from '../../../redux/modules/teams'
import TeamPlayersGridDialog from './TeamPlayersGrid/AddPlayerManually'
import PlayersImportDialog from './TeamPlayersGrid/PlayersBulkUpload'
import { team as teamEvent } from 'constants/trackeventsname'


const useStyles = makeStyles((theme) => ({
  tabPanel: {
    paddingTop: '.5rem',
  },
  invitationLink: {
    marginBottom: '.5rem',
  },
  linkButton: {
    padding: theme.spacing(1, 0, 0, 0),
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiLink-root': {
      color: 'blue'
    }
  },
  closeButton: {
    paddingRight: '3rem',
    paddingLeft: '3rem',
    marginTop: '1.5rem',
  },
  teamNamePosition: {
    marginLeft: '.25rem',
  },
  filterIcon: {
    marginLeft: '.5rem',
  },
}))

const AddPlayersDialog = ({
  open,
  onClose,
  team,
  user,
  handleSave,
  tabDisabled,
  loadingcircular,
  teamPermissions,
  fetchInviteUrl,
}) => {
  const canImportCsv = teamPermissions.includes(
    PERMISSIONS.IMPORT_TEAM_PLAYERS_CSV
  )

  const [deepLink, setDeepLink] = useState('Loading...')
  const [value, setValue] = useState(0)
  const [snackbar, setSnackbar] = useState(false)
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  useEffect(() => {
    if (open) {
      fetchInviteUrl(team.uuid).then(({ response: { data } }) => {
        setDeepLink(data.url)
      })
    }
  }, [open])

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          PaperProps={{ elevation: 0 }}
        >
          {loadingcircular && (
            <CircularSpinner loadingcircular={loadingcircular} />
          )}
          <InnrHead
            title={<Translate id="team-invite.add-players" />}
            style={{ marginBottom: 0 }}
          >
            {' '}
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </InnrHead>
          <DialogContent>
            <AppBar position="static" color="transparent">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label={<Translate id="team-invite.team-invite-tab" />}
                  {...a11yProps(0)}
                />
                {!tabDisabled ? (
                  <Tab
                    label={<Translate id="team-invite.manual-entry-tab" />}
                    {...a11yProps(1)}
                  />
                ) : (
                  ''
                )}

                <Tab
                  label={<Translate id="team-invite.bulk-upload-tab" />}
                  {...a11yProps(2)}
                  disabled={!canImportCsv}
                  // Not needed for now
                  style={{ display: 'none' }}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} className={classes.tabPanel}>
              <Grid spacing={2} container alignItems="center">
                {/* @todo display QR code section after QR integration */}
                <Grid item xs={12} md={3} style={{ display: 'none' }}>
                  QR CODE
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h2"
                    component="h2"
                    className={classes.invitationLink}
                  >
                    <Translate id="team-invite.send-link-title" />
                  </Typography>
                  <Typography variant="h6" component="span" paragraph>
                    <Translate id="team-invite.send-link-subtitle" />
                    <Typography
                      variant="h4"
                      component="b"
                      className={classes.teamNamePosition}
                    >
                      &quot;{team.name}&quot;
                    </Typography>
                  </Typography>
                  <Button
                    className={classes.linkButton}
                    onClick={(e) => {
                      e.preventDefault()
                      navigator.clipboard.writeText(deepLink)
                      setSnackbar({
                        message: <Translate id="team-invite.link-copied" />,
                        success: true,
                      })
                      track(teamEvent.team_players_invite_link_copy, {
                        team_id: +team.id,
                        category: teamEvent.team,
                      })
                    }}
                  >
                    <Link href={deepLink}>
                      {deepLink}
                      <FilterNoneOutlinedIcon
                        fontSize="inherit"
                        className={classes.filterIcon}
                      />
                    </Link>
                  </Button>
                  {/* @todo display after QR integration */}
                  <Typography
                    variant="h6"
                    component="span"
                    paragraph
                    style={{ display: 'none' }}
                  >
                    <Translate id="team-invite.players-can-scan" />
                  </Typography>
                </Grid>
              </Grid>
              <DialogActions>
                {/* @todo uncomment after QR integration done and saving feature is ready */}
                {/* <Button onClick={onClose} color="primary" className="px-5">
                  <Translate id="team-invite.save-qr" />
                </Button> */}
                <Button
                  onClick={onClose}
                  color="primary"
                  variant="contained"
                  className={classes.closeButton}
                >
                  <Translate id="button.close" />
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TeamPlayersGridDialog
                handleSave={handleSave}
                onClose={onClose}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PlayersImportDialog
                teamId={team.id}
                user={user}
                onClose={onClose}
              />
            </TabPanel>
          </DialogContent>
        </Dialog>
      )}
      {snackbar && <Snackbar {...snackbar} />}
    </>
  )
}

AddPlayersDialog.defaultProps = {
  tabDisabled: false,
  title: '',
  deepLink: '',
}

AddPlayersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  tabDisabled: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.shape().isRequired,
  team: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  deepLink: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  loadingcircular: PropTypes.bool.isRequired,
  teamPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchInviteUrl: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ team, auth }) => ({
      team,
      user: auth.user,
      teamPermissions:
        auth.permissions.teams.find((item) => item.id === team.id)
          ?.permissions ?? [],
    }),
    {
      fetchInviteUrl,
    }
  )
)(AddPlayersDialog)
