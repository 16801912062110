import React, { useEffect, useState } from 'react'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import itLocale from 'date-fns/locale/it'
import plLocale from 'date-fns/locale/pl'
import jaLocale from 'date-fns/locale/ja'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { Translate } from 'react-localize-redux'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { EUR_STANDARD, formatDateTimeToServer } from 'utils/formatDate'
import { InputAdornment } from '@material-ui/core'
import TodayIcon from '@material-ui/icons/Today'

const localeMap = {
  en: enLocale,
  de: deLocale,
  es: esLocale,
  it: itLocale,
  pl: plLocale,
  jp: jaLocale,
}

const DatePickerComp = ({
  meta,
  label,
  size,
  variant,
  margin,
  id,
  name,
  onDateChange,
  selectedValue,
  disableFuture,
  disableToolbar,
  openTo,
  isEditMode,
  dateFormat,
  ...rest
}) => {
  const dateError = meta.touched && meta.error
  const [locale, setLocale] = useState(enLocale)

  // This conversion helps to remove formatting error in mobile devices while selecting dates
  const convertdate = (dateTemp) => {
    return dateTemp
      ? formatDateTimeToServer(dateTemp).split('-').join('/')
      : null
  }
  const [selectedDate, handleDate] = useState(convertdate(selectedValue))
  const handleDateChange = (e) => {
    handleDate(e)
    onDateChange(e)
  }
  useEffect(() => {
    const currentLanguage = localStorage.getItem('locale')
    const selectedLocale = localeMap[currentLanguage] || enLocale
    setLocale(selectedLocale)
  }, [])
  useEffect(() => {
    if (!isEditMode) {
      handleDate(selectedValue)
    } else {
      const temp = convertdate(selectedValue)
      handleDate(temp)
    }
  }, [selectedValue])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <DatePicker
        showTodayButton
        disableFuture={disableFuture}
        disableToolbar={disableToolbar}
        autoOk
        value={selectedDate}
        onChange={handleDateChange}
        label={label}
        format={dateFormat}
        margin={margin}
        inputVariant="outlined"
        views={['year', 'month', 'date']}
        openTo={openTo}
        variant={variant}
        error={dateError}
        helperText={dateError}
        size={size}
        id={id}
        okLabel={<Translate id="button.ok" />}
        cancelLabel={<Translate id="button.cancel" />}
        todayLabel={<Translate id="button.today" />}
        name={name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <TodayIcon color="disabled"/>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePickerComp.defaultProps = {
  meta: {},
  size: 'small',
  variant: 'outlined',
  margin: 'dense',
  id: '',
  name: '',
  onDateChange: () => 0,
  selectedValue: '',
  disableFuture: false,
  disableToolbar: true,
  openTo: 'date',
  dateFormat: EUR_STANDARD,
}

DatePickerComp.propTypes = {
  meta: PropTypes.shape(),
  label: PropTypes.shape().isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onDateChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableFuture: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  openTo: PropTypes.string,
  dateFormat: PropTypes.string,
  isEditMode: PropTypes.bool.isRequired,
}

export default DatePickerComp
