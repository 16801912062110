import { useLocation, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { Box, Container, LinearProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from '../../constants/propTypes'
import { auth as authModule } from '../../redux/modules'
import { identify } from '../../utils/segmentTracking'
import { setLocale } from '../../utils/localstorage'

const SsoCallback = ({ authState, loginWithAccessToken, setActiveLanguage, history }) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const [error, setError] = useState('')

  useEffect(() => {
    setError('')

    const tokenType = query.get('token_type')
    const accessToken = query.get('access_token')
    const refreshToken = query.get('refresh_token')

    if (tokenType !== 'Bearer' || !accessToken.length || !refreshToken.length) {
      setError('Invalid request.')

      return
    }

    loginWithAccessToken(accessToken)
      // eslint-disable-next-line camelcase
      .then(({ response: { signup_completed, user: { id, language } } }) => {
        // eslint-disable-next-line camelcase
        if (!signup_completed) {
          throw new Error('User has no teams.')
        }

        identify(id)
        setLocale(language)
        setActiveLanguage(language)

        history.push('/')
      })
      .catch((e) => {
        setError('Login not allowed. Please contact your administrator.')
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }, [])

  return (
    <Container maxWidth="sm">
      <Box sx={{ m: '2rem' }}>
        {error && <Alert severity="error">{error}</Alert>}
        {authState.isLoading && <LinearProgress />}
      </Box>
    </Container>
  )
}

SsoCallback.propTypes = {
  loginWithAccessToken: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  authState: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(
  withRouter,
  withLocalize,
  connect(
    ({ auth }) => ({
      authState: auth,
    }),
    {
      loginWithAccessToken: authModule.loginWithAccessToken,
    },
  ),
)(SsoCallback)

