import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { players } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const PlayersLoader = ({
  teamId,
  fetchTeamPlayers,
  children,
  ...restProps
}) => {
  const fetchMethod = () => fetchTeamPlayers(teamId)
  const triggers = [teamId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

PlayersLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  teamId: PROP_TYPES.id.isRequired,
  fetchTeamPlayers: PropTypes.func.isRequired,
}

export default connect(
  ({ team }) => ({
    teamId: team.id,
  }),
  { fetchTeamPlayers: players.fetchTeamPlayers }
)(PlayersLoader)
