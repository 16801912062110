import {
  getRequest,
} from 'utils/axios'

const exportTaskGroupToExcel = (taskGroupUuid) => {
  const url = `v2/task-groups/${taskGroupUuid}/export`

  return getRequest(url, {}, 'blob')
}

export default exportTaskGroupToExcel