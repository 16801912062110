/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { makeStyles, styled } from '@material-ui/core/styles'
import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, FieldArray, Field, change } from 'redux-form'
import {
  Toolbar,
  Button,
  Paper,
  Grid,
  Table,
  Typography,
  Card,
  CardHeader,
  CardContent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
} from '@material-ui/core'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { ActionWithPrompt, DateInput } from 'components'
import { seasons as seasonsModule } from 'redux/modules'
import MuiAlert from '@material-ui/lab/Alert'
import { withRouter } from 'react-router-dom'
import { SeasonsLoader } from 'loaders'
import mapStateToProps from './mapStateToProps'
import PROP_TYPES from 'constants/propTypes'

const Alert = (props) => {
  return <MuiAlert elevation={0} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  customField: {
    '& .form-group': {
      minHeight: 'auto !important',
      marginBottom: '0 !important',
    },
  },
  container: {
    '& .MuiTableRow-root': {
      display: 'flex !important',
      flexDirection: 'row',

      '& .MuiTableCell-root': {
        flex: '1 1 20em',
      },
    },
  },
  PlayerNum: {
    margin: '0 .8rem',
    '& img': {
      marginRight: '.6rem',
    },
    '& :hover': {
      backgroundColor: 'transparent',
    },
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  title: {
    flex: '1 1 0',
    marginLeft: theme.spacing(2),
  },
  toolbar: {
    minHeight: 54,
  },
  newSessionDrill: {
    backgroundColor: 'white',
    padding: '0 1rem',
    borderRadius: '6px',
  },
  seasonAddBtn: {
    marginTop: '.9rem',
  },
  [theme.breakpoints.down('sm')]: {
    paper: {
      display: 'flex',
      flexDirection: 'column',
    },
    Cancel: {
      padding: '8px 28px',
    },
    Save: {
      padding: '8px 28px',
    },
    MobileSectionDialog: {
      '& .MuiDialog-paperWidthSm': {
        width: '100%',
      },
    },
    container: {
      '& .MuiTableHead-root': {
        display: 'none',
      },
      '& .MuiTableRow-root': {
        display: 'flex !important',
        flexDirection: 'column',
        marginBottom: '.9rem',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        '& .MuiTableCell-root': {
          flex: 'inherit',
        },
      },
    },
    seasonAddBtn: {
      marginTop: '0',
    },
    DialogClose: {
      position: 'absolute',
      top: '4px',
      right: '52px',
      zIndex: '10',
    },
    MobileSectionDialogBtn: {
      textAlign: 'center',
      display: 'flex',
      height: '4.375rem',
      position: 'fixed',
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
      padding: '0.875rem 1.125rem',
      bottom: '0',
      left: '0',
      borderTop: '1px solid var( --light-grey)',
      background: 'var(--white)',
      width: '100%',
      zIndex: '10',
      '& a': {
        width: '50%',
      },
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
}))

let startDateError = false

const CURRENT_DATE = moment().format('YYYY-MM-DD')

const SEASON_STATUS_TO_COLOR_MAPPER = {
  actual: 'lightskyblue',
  finished: 'lightsalmon',
  future: 'lightgreen',
}

const StyledTr = styled(TableRow)(({ theme, seasonStatus }) => ({
  borderLeftColor: SEASON_STATUS_TO_COLOR_MAPPER[seasonStatus],
  borderLeftStyle: 'solid',
  borderLeftWidth: theme.spacing(1.2),
}))

const validate = (values) => ({
  seasons: (values.seasons || []).map((season) => ({
    start_date: !season.start_date ? 'Required' : undefined,
    end_date: !season.end_date ? 'Required' : undefined,
  })),
})

const getSeasonStatus = (season) => {
  if (season && season.id === 'new') {
    return 'new'
  }

  if (season && moment(season.end_date).format('YYYY-MM-DD') < CURRENT_DATE) {
    return 'finished'
  }

  if (season && moment(season.start_date).format('YYYY-MM-DD') > CURRENT_DATE) {
    return 'future'
  }

  return 'actual'
}

const SeasonsContainer = ({
  seasons,
  deleteSeason,
  changeField,
  handleSubmit,
  reset,
  auth: { user },
  match: {
    params: { teamUuid, seasonId },
  },
  history,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(startDateError)

  const handleClose = () => {
    setOpen(false)
  }

  const deleteSeasonById = (teamId, id) => {
    deleteSeason(teamId, id).then(() => {
      if (id === parseInt(seasonId, 10)) {
        const session = seasons.find((p) => p.id !== id)

        history.push(`/team/${teamUuid}/season/${session.id}/seasons`)
      }
    })
  }

  useEffect(() => {
    setOpen(startDateError)
  }, [startDateError])

  return (
    <SeasonsLoader>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Toolbar
              disableGutters={false}
              component={Paper}
              className={classes.toolbar}
            >
              <Typography variant="h4" component="h4" className={classes.title}>
                <Translate id="season.manage" />
              </Typography>
              <Button
                color="primary"
                onClick={reset}
                className={classes.button}
              >
                <Translate id="button.cancel" />
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
              >
                <Translate id="button.save" />
              </Button>
            </Toolbar>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader />
              <CardContent>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow style={{ position: 'relative', zIndex: 0 }}>
                        <TableCell>#</TableCell>
                        <TableCell>
                          <Translate id="season.start-date" />
                        </TableCell>
                        <TableCell>
                          <Translate id="season.end-date" />
                        </TableCell>
                        <TableCell>
                          <Translate id="season.actions" />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <FieldArray
                      name="seasons"
                      component={({ fields }) => (
                        <TableBody className={classes.customField}>
                          {fields.map((name, index) => {
                            const currentSeason = seasons[index]

                            const seasonStatus = getSeasonStatus(currentSeason)

                            const startDateMin =
                              index > 0 &&
                              seasons[index - 1] &&
                              moment(seasons[index - 1].end_date)
                                .add(1, 'd')
                                .format('YYYY-MM-DD')

                            const startDateMax =
                              currentSeason &&
                              currentSeason.end_date &&
                              moment(currentSeason.end_date)
                                .subtract(1, 'd')
                                .format('YYYY-MM-DD')

                            const endDateMin =
                              currentSeason &&
                              currentSeason.start_date &&
                              moment(currentSeason.start_date)
                                .add(1, 'd')
                                .format('YYYY-MM-DD')

                            const endDateMax =
                              index !== fields.length - 1 &&
                              seasons[index + 1] &&
                              seasons[index + 1].start_date &&
                              moment(seasons[index + 1].start_date)
                                .subtract(1, 'd')
                                .format('YYYY-MM-DD')

                            return (
                              <StyledTr key={name} seasonStatus={seasonStatus}>
                                <TableCell>
                                  {seasonStatus === 'new' ? 'New' : index + 1}
                                </TableCell>
                                <TableCell>
                                  <Field
                                    name={`${name}.start_date`}
                                    component={DateInput}
                                    formatDate={user.datetime_format}
                                    min={startDateMin || null}
                                    max={startDateMax || null}
                                    placeholder={user.datetime_format}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Field
                                    name={`${name}.end_date`}
                                    component={DateInput}
                                    formatDate={user.datetime_format}
                                    min={endDateMin || null}
                                    max={endDateMax || null}
                                    placeholder={user.datetime_format}
                                  />
                                </TableCell>
                                <TableCell>
                                  {seasonStatus === 'actual' &&
                                    index === fields.length - 1 &&
                                    currentSeason &&
                                    moment(currentSeason.start_date).format(
                                      'YYYY-MM-DD'
                                    ) <
                                      moment()
                                        .subtract(1, 'd')
                                        .format('YYYY-MM-DD') && (
                                      <Translate>
                                        {({ translate }) => (
                                          <ActionWithPrompt
                                            onSuccess={() => {
                                              changeField(
                                                'seasons',
                                                `seasons[${index}].end_date`,
                                                moment()
                                                  .subtract(1, 'd')
                                                  .format('YYYY-MM-DD')
                                              )

                                              fields.push({
                                                id: 'new',
                                                start_date:
                                                  moment().format('YYYY-MM-DD'),
                                                end_date: moment()
                                                  .add(6, 'M')
                                                  .format('YYYY-MM-DD'),
                                              })
                                            }}
                                            message="Are you sure you want to end the Season?"
                                            title={translate(
                                              'season.end-season'
                                            )}
                                          />
                                        )}
                                      </Translate>
                                    )}
                                  {((seasonStatus !== 'actual' &&
                                    ((fields.length > 1 &&
                                      (seasons[fields.length - 1] || {}).id !==
                                        'new') ||
                                      fields.length > 2)) ||
                                    seasonStatus === 'new') && (
                                    <Translate>
                                      {({ translate }) => (
                                        <ActionWithPrompt
                                          className="text-danger border-danger"
                                          onSuccess={() =>
                                            seasonStatus === 'new'
                                              ? fields.pop()
                                              : deleteSeasonById(
                                                  currentSeason.team_id,
                                                  currentSeason.id
                                                )
                                          }
                                          message={translate('message.delete')}
                                          title={<DeleteOutlineIcon />}
                                        />
                                      )}
                                    </Translate>
                                  )}
                                </TableCell>
                              </StyledTr>
                            )
                          })}
                          {(seasons[fields.length - 1] || {}).id !== 'new' && (
                            <Grid item spacing={3}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.seasonAddBtn}
                                onClick={() => fields.push({ id: 'new' })}
                              >
                                <Translate id="season.add-new" />
                              </Button>
                            </Grid>
                          )}
                        </TableBody>
                      )}
                    />
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {open && (
          <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              severity="error"
              sx={{ width: '100%' }}
              onClose={handleClose}
            >
              <Translate id="season.start-date-error" />
            </Alert>
          </Snackbar>
        )}
      </form>
    </SeasonsLoader>
  )
}

SeasonsContainer.defaultProps = {
  seasons: [],
}

SeasonsContainer.propTypes = {
  seasons: PROP_TYPES.arrayOfObjects,
  deleteSeason: PROP_TYPES.func.isRequired,
  changeField: PROP_TYPES.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  auth: PropTypes.string.isRequired,
  history: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.match.isRequired,
}
/* eslint-disable no-await-in-loop */
export default compose(
  connect(mapStateToProps, {
    createSeason: seasonsModule.createSeason,
    updateSeason: seasonsModule.updateSeason,
    deleteSeason: seasonsModule.deleteSeason,
    changeField: change,
  }),
  withRouter,
  reduxForm({
    form: 'seasons',
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate,
    onSubmit: async (
      { seasons },
      dispatch,
      { createSeason, updateSeason }
    ) => {
      startDateError = false
      for (let i = 0; i < seasons.length; i += 1) {
        const season = seasons[i]
        if (season.id === 'new') {
          await createSeason({
            start_date: moment(season.start_date).format('YYYY-MM-DD'),
            end_date: moment(season.end_date).format('YYYY-MM-DD'),
          })

          return
        }

        await updateSeason(season.id, {
          start_date: moment(season.start_date).format('YYYY-MM-DD'),
          end_date: moment(season.end_date).format('YYYY-MM-DD'),
        })
      }
    },
  })
)(SeasonsContainer)
