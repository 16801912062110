import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import Rating from '@material-ui/lab/Rating'
import {
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Box,
  Typography,
} from '@material-ui/core'
import filterObject from 'utils/filterObject'
import BallIcon from 'assets/images/ballicon.svg'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import useTeamFeature from 'hooks/useTeamFeature'
import TeamEditDialog from './TeamEditDialog'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}))

const StyledRating = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
}))(Rating)

const TeamSummary = ({ team, matchesRating, club }) => {
  const [open, setOpen] = useState(false)

  const hasTeamRatingEnabled =
    useTeamFeature(TEAM_FEATURE_FLAGS.create_match_enabled).enabled &&
    useTeamFeature(TEAM_FEATURE_FLAGS.player_statistics_enabled).enabled

  const handleClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [initialValues, setInitialValues] = useState({
    ...filterObject({ ...team }, Boolean),
  })

  const { name } = team

  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        action={
          <IconButton onClick={handleClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        }
      />
      <CardContent>
        {open && (
          <TeamEditDialog
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            initialValues={{
              ...initialValues,
              logo: team.relationships.logo,
              tag: team.tag_name || null,
              age_class:
                team.age_class_uuid || team.relationships.age_class.uuid, // @todo Remove age_class_uuid when team update api will be migrated to v2
            }}
            setInitialValues={setInitialValues}
          />
        )}

        <Box className={classes.root}>
          <Avatar
            variant="square"
            alt="logo"
            className={classes.large}
            src={
              team.relationships.logo && team.relationships.logo.full_url
                ? team.relationships.logo.full_url
                : BallIcon
            }
          />
        </Box>
        <Box className={classes.root}>
          <Typography variant="h3">{name}</Typography>
          {hasTeamRatingEnabled && (
            <StyledRating name="read-only" value={matchesRating} readOnly />
          )}
          {club && club.enable_team_tags && (
            <Typography variant="body2">{team.tag_name}</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
TeamSummary.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tag_name: PropTypes.string.isRequired,
    age_class_uuid: PropTypes.string,
    relationships: PropTypes.shape({
      age_class: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
      }).isRequired,
      logo: PropTypes.shape({
        full_url: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
  matchesRating: PropTypes.number.isRequired,
  club: PropTypes.shape({
    enable_team_tags: PropTypes.bool.isRequired,
  }).isRequired,
}

export default connect(({ team, clubs }) => ({
  team,
  club: clubs.current,
}))(TeamSummary)
