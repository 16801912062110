import { Box, Paper, Tabs, Tab, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import useTeamFeature from 'hooks/useTeamFeature'
import useStyles from '../styles'
import Staff from './Staff'
import Player from './Player'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const InviteMembers = () => {
  const classes = useStyles()
  const [value, setValue] = useState('player')

  const { enabled: hasStaffManagementEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.staff_management_enabled
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box component={Paper} className={classes.invites}>
        <Typography variant="h4" className={classes.invitesTitle}>
          <Translate id="team.members.invite-new" />
        </Typography>
        <Box className={classes.tabContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={<Translate id="events.table-player" />}
              value="player"
              className={classes.tab}
            />
            {hasStaffManagementEnabled && (
              <Tab
                label={<Translate id="team.staff" />}
                value="staff"
                className={classes.tab}
              />
            )}
          </Tabs>
        </Box>
        <Box className={classes.contentContainer}>
          {value === 'player' && <Player />}
          {value === 'staff' && hasStaffManagementEnabled && <Staff />}
        </Box>
      </Box>
    </>
  )
}

export default InviteMembers
