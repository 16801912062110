import React, { useEffect, useState, useMemo } from 'react'
import { postRequest } from 'utils/axios'
import { Translate } from 'react-localize-redux'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { SubLink } from 'containers'
import { matches } from 'redux/modules'
import {
  Grid,
  Card,
  Box,
  Typography,
  CardHeader,
  CardContent,
} from '@material-ui/core'
import videoIcon from 'assets/images/video@1x.svg'
import PropTypes from 'prop-types'
import { useSequencesStyles } from '../styledComponents'
import SequencesCard from './SequencesCard'

const updateFavoriteSequences = (sequenceUuid, favorite) => {
  const url = `v2/sequences/${sequenceUuid}/favorite`
  const data = { favorite }

  return postRequest(url, data)
}

const ConnectedSequences = ({ fetchMatchSequences, currentMatchUuid }) => {
  const [sequences, setSequences] = useState([])
  const [selectedSequenceUuid, changeSelectedSequenceUuid] = useState(null)
  const { teamUuid, seasonId } = useParams()

  useEffect(() => {
    fetchMatchSequences(currentMatchUuid).then((res) => {
      const tempSequences = res.response.data ? res.response.data : []
      setSequences(tempSequences)
    })
  }, [])

  const selectedSequence = useMemo(
    () => sequences.find((item) => item.uuid === selectedSequenceUuid),
    [selectedSequenceUuid, sequences]
  )
  const handleFavoriteToggle = async (sequence) => {
    try {
      await updateFavoriteSequences(sequence.uuid, !sequence.favorite)
      setSequences((prevSequences) =>
        prevSequences.map((s) =>
          s.uuid === sequence.uuid ? { ...s, favorite: !sequence.favorite } : s
        )
      )
    } catch (error) {
      console.error('Error toggling favorite status:', error)
    }
  }

  const classes = useSequencesStyles()

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="h4">
            {' '}
            <Translate id="matches.connected-sequences" />
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          {sequences.length > 0 ? (
            sequences.map((sequence) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={sequence.uuid}>
                <SequencesCard
                  sequence={sequence}
                  onFavoriteToggle={() => handleFavoriteToggle(sequence)}
                  changeSelectedSequenceUuid={changeSelectedSequenceUuid}
                  selectedSequenceUuid={selectedSequenceUuid}
                  selectedSequence={selectedSequence}
                />
              </Grid>
            ))
          ) : (
            <SubLink
              to={`/team/${teamUuid}/season/${seasonId}/videos`}
              isFullPath
              className={classes.link}
            >
              <Box width="100%" mr={1} textAlign="center" py={4} px={3}>
                <img src={videoIcon} alt={videoIcon} />
                <Typography variant="p" component="p">
                  <Translate id="matches.no-sequences-text" />
                </Typography>
              </Box>
            </SubLink>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

ConnectedSequences.propTypes = {
  sequences: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    favorite: PropTypes.bool.isRequired,
  }).isRequired,
  fetchMatchSequences: PropTypes.func.isRequired,
  currentMatchUuid: PropTypes.string.isRequired,
}

export default connect(
  ({ matches: { current } }) => ({
    currentMatchUuid: current.uuid,
  }),
  {
    fetchMatchSequences: matches.fetchMatchSequences,
  }
)(ConnectedSequences)
