import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import {
  Button,
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { teams as teamsModule } from 'redux/modules'
import { track } from 'utils/segmentTracking'
import { team, status } from 'constants/trackeventsname'

const PlayersBulkUpload = ({ teamId, importPlayers, user }) => {
  const [step, setStep] = React.useState(1)
  const [error, setError] = React.useState(false)
  const [file, setFile] = React.useState(false)
  const [responses, setResponses] = React.useState(null)
  const [link, setLink] = React.useState(
    'https://uploads-ssl.webflow.com/612fa241b0d5b96d776bbc3e/6267b39b4be6cb3d07af43c6_Player%20Import%20Feature_Template_EN.csv'
  )

  const handleClose = () => {
    setStep(1)
    setResponses(null)
    setFile(null)
    setError(false)
  }

  const checkFile = (e) => {
    const selectedFile = e.target.value ? e.target : null
    if (!selectedFile) return
    setFile(selectedFile.files[0])
    const size = selectedFile.files[0].size / (1024 * 1024)
    if (size > 2.1) {
      e.target.value = null
      setError(2)

      return
    }
    const name = selectedFile.value ? selectedFile.value : null
    const extension = name.split('.').pop()
    if (extension.toLowerCase() !== 'csv') {
      e.target.value = null
      setError(1)

      return
    }
    setError(false)
  }

  const handleImport = () => {
    track(team.team_players_import, {
      team_id: +teamId,
      category: team.team,
      status: status.initial,
    })
    if (document.getElementById('players').value !== '' && !error) {
      importPlayers(teamId, { players: file }).then(({ response }) => {
        setResponses(response.data)
        track(team.team_players_import, {
          team_id: +teamId,
          category: team.team,
          status: status.complete,
          response: response.data,
        })
        document.getElementById('players').value = ''
        setFile(null)
      })
    } else {
      setError(1)
    }
  }

  const changeStep = (val) => {
    setStep(val)
  }

  useEffect(() => {
    if (user.language === 'en')
      setLink(
        'https://uploads-ssl.webflow.com/612fa241b0d5b96d776bbc3e/6267b39b4be6cb3d07af43c6_Player%20Import%20Feature_Template_EN.csv'
      )
    else if (user.language === 'de')
      setLink(
        'https://uploads-ssl.webflow.com/612fa241b0d5b96d776bbc3e/6267b34496f713412d3d3149_Player%20Import%20Feature_Template_DE.csv'
      )
    else if (user.language === 'es')
      setLink(
        'https://uploads-ssl.webflow.com/612fa241b0d5b96d776bbc3e/6267b3458ea46dff31d84352_Player%20Import%20Feature_Template_ES.csv'
      )
    else if (user.language === 'it')
      setLink(
        'https://uploads-ssl.webflow.com/612fa241b0d5b96d776bbc3e/6267b3808c41c9ad96ca6728_Player%20Import%20Feature_Template_IT.csv'
      )
  }, [user.language])

  return (
    <>
      {step === 1 && (
        <>
          <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
            <Translate id="team.create-your-team" />
          </Grid>
          <Grid className="pt-2" item xs={12} sm={12} md={6} lg={12} xl={12}>
            <b>
              <Translate id="team.step-1" />
            </b>{' '}
            <Translate id="team.download-template" />
          </Grid>
          <Grid className="pt-2" item xs={12} sm={12} md={6} lg={12} xl={12}>
            <a target="blank" href={link}>
              <Translate id="team.download-template-link-text" />
            </a>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClose()}
                fullWidth
              >
                <Translate id="button.cancel" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => changeStep(2)}
                fullWidth
              >
                <Translate id="training.next" />
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {step === 2 && (
        <>
          <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
            <b>
              <Translate id="team.step-2" />
            </b>{' '}
            <Translate id="team.add-player-info-in-template" />
            <p className="pt-1">
              <Translate id="team.note-to-not-change-order" />
            </p>
          </Grid>
          <Grid item className="pt-2" xs={12} sm={12} md={6} lg={12} xl={12}>
            <b>
              <Translate id="team.step-3" />
            </b>{' '}
            <Translate id="team.upload-csv" />
          </Grid>
          <Grid spacing={2} className="pt-2" container>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
              <Button
                variant="outlined"
                onClick={() => document.getElementById('players').click()}
              >
                <Translate id="team.choose-file" />
              </Button>
              &nbsp;
              {!file && <Translate id="team.no-file-chosen" />}
              {file && <span id="fileName">{file.name}</span>}
              <input
                type="file"
                className="d-none"
                id="players"
                size="large"
                accept="text/csv"
                onChange={checkFile}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={12}>
              {error && (
                <Typography color="error" textAlign="left" gutterBottom>
                  {error === 1 && <Translate id="team.select-csv-only" />}
                  {error === 2 && <Translate id="team.file-size-2mb" />}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClose()}
                fullWidth
              >
                <Translate id="button.cancel" />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleImport()}
                fullWidth
              >
                <Translate id="button.import" />
              </Button>
            </Grid>
          </Grid>
          {responses && (
            <>
              <Table>
                <TableRow>
                  <TableCell style={{ color: 'black' }}>
                    <Translate id="team.total" />: <b>{responses.total}</b>
                  </TableCell>
                  <TableCell style={{ color: 'green' }}>
                    <Translate id="team.successful" />:{' '}
                    <b>{responses.success}</b>
                  </TableCell>
                  <TableCell style={{ color: 'red' }}>
                    <Translate id="team.failed" />: <b>{responses.fail}</b>
                  </TableCell>
                </TableRow>
              </Table>
              <Table>
                {Object.keys(responses.records).map((key) =>
                  Object.keys(responses.records[key]).map((key1) =>
                    Object.keys(responses.records[key][key1]).map(() => (
                      <tr key={responses.records[key][key1]}>
                        <td style={{ color: 'black' }}>
                          <b>
                            Record:{key} => {responses.records[key][key1][0]}
                          </b>
                        </td>
                      </tr>
                    ))
                  )
                )}
              </Table>
            </>
          )}
        </>
      )}
    </>
  )
}

PlayersBulkUpload.defaultProps = {
  importPlayers: [],
}

PlayersBulkUpload.propTypes = {
  importPlayers: PropTypes.arrayOf(PropTypes.object.isRequired),
  teamId: PropTypes.number.isRequired,
  user: PropTypes.shape().isRequired,
}

export default connect(null, {
  importPlayers: teamsModule.importPlayers,
})(PlayersBulkUpload)
