import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { Select, MenuItem, InputLabel } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const SelectMD = ({
  id,
  options,
  value,
  name,
  label,
  onChange,
  disabled,
  meta,
  size,
  variant,
  margin,
}) => {
  const error = meta.touched && meta.error

  return (
    <>
      <FormControl error={error} variant={variant} size={size} margin={margin}>
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id={id}
          name={name}
          value={value}
          disabled={Boolean(disabled)}
          label={label}
          helperText={error}
          onChange={event => {
            onChange(event.target.value)
          }}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

SelectMD.defaultProps = {
  value: '',
  onChange: () => 0,
  disabled: false,
  label: '',
  name: '',
  margin: 'none',
  size: 'small',
  variant: 'outlined',
  id: '',
}

SelectMD.propTypes = {
  options: PROP_TYPES.options.isRequired,
  meta: PropTypes.shape().isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
}

export default SelectMD
