import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { trainings } from 'redux/modules'
import {
  checkIsAfterDateTime,
  concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat,
  formatDateTimeToServer,
  formatDateTimeToUtcDateTimeWithUserTimezone,
  formatUtcDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import PropTypes from 'prop-types'
import UserActionDialog from 'components/material/Calender/UserActionDialog'
import TrainingInfoEditForm from './TrainingInfoEditForm'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent)

const TrainingInfoDialog = ({
  open,
  handleClose,
  currentTraining,
  updateTraining,
  timezone,
  isTrainingSeries,
}) => {
  const [trainingData, setTrainingData] = useState({
    start_datetime: formatUtcDateTimeToUserTimezoneDateTime(
      currentTraining.start_datetime,
      timezone
    ),
    end_datetime: currentTraining.end_datetime
      ? formatUtcDateTimeToUserTimezoneDateTime(
          currentTraining.end_datetime,
          timezone
        )
      : null,
    intensity: currentTraining.intensity ?? '',
    location: currentTraining.location ?? '',
    availability_cutoff_time: currentTraining.availability_cutoff_time,
  })
  const [userDialogOpen, setUserDialogOpen] = useState(false)
  const [trainingUpdateData, setTrainingUpdateData] = useState({})

  const handleDateChange = (uiDate) => {
    setTrainingData({
      ...trainingData,
      start_datetime: formatDateTimeToServer(uiDate),
    })
  }

  const handleEndTimeChange = (uiDate) => {
    setTrainingData({
      ...trainingData,
      end_datetime: formatDateTimeToServer(uiDate),
    })
  }

  const handleRatingChange = (newRating) => {
    setTrainingData((prevData) => ({
      ...prevData,
      intensity: newRating,
    }))
  }

  const handleInputChange = (event) => {
    if (!event?.target) return
    const { name, value } = event.target
    setTrainingData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const [dateTimeError, setDateTimeError] = useState(null)

  const handleTrainingInfoSubmit = (values, params) => {
    const {
      focus,
      details,
      availability_cutoff_time: availabilityCutoffTime,
      intensity,
    } = values

    const tempTrainingData = {
      ...trainingData,
      start_datetime: formatDateTimeToUtcDateTimeWithUserTimezone(
        trainingData.start_datetime,
        timezone
      ),
      end_datetime: trainingData.end_datetime
        ? concatDateAndTimeOfTwoDatesWithUserTimezoneAndUtcFormat(
            trainingData.start_datetime,
            trainingData.end_datetime,
            timezone
          )
        : null,
      availability_cutoff_time: availabilityCutoffTime,
    }
    const body = {
      focus,
      details,
      intensity,
      ...tempTrainingData,
    }
    setTrainingUpdateData(body)
    if (
      checkIsAfterDateTime(
        tempTrainingData.start_datetime,
        tempTrainingData.end_datetime
      )
    ) {
      setDateTimeError('matches.end-time-error')

      return
    }
    if (isTrainingSeries) {
      setUserDialogOpen(true)
    } else {
      const queryParams = { ...params, apply_on: 'SINGLE' }
      updateTraining(currentTraining.uuid, body, queryParams).then(() => {
        handleClose()
      })
    }
  }

  const handleUserDialogAction = (applyOption) => {
    const queryParams = { apply_on: applyOption }
    updateTraining(currentTraining.uuid, trainingUpdateData, queryParams).then(
      () => {
        setUserDialogOpen(false)
        handleClose()
      }
    )
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="edit-match-info-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <InnrHead title={<Translate id="button.edit-training" />}>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </InnrHead>
        <DialogContent dividers>
          <TrainingInfoEditForm
            initialValues={{ ...currentTraining }}
            onSubmit={handleTrainingInfoSubmit}
            handleDateChange={handleDateChange}
            handleEndTimeChange={handleEndTimeChange}
            datetimesAndLocationData={trainingData}
            onChange={handleInputChange}
            onRatingChange={handleRatingChange}
            setTrainingData={setTrainingData}
            handleCancel={handleClose}
            dateTimeError={dateTimeError}
          />
        </DialogContent>
      </Dialog>
      {isTrainingSeries && (
        <UserActionDialog
          open={userDialogOpen}
          onClose={() => setUserDialogOpen(false)}
          onActionOne={() => {
            handleUserDialogAction('SINGLE')
            setUserDialogOpen(false)
            handleClose()
          }}
          onActionTwo={() => {
            handleUserDialogAction('SINGLE_AND_FOLLOWING')
            setUserDialogOpen(false)
            handleClose()
          }}
          title={<Translate id="training.update" />}
          subTitle={<Translate id="training.update-option" />}
          buttonOneLabel={<Translate id="training.update-this" />}
          buttonTwoLabel={<Translate id="training.update-this-following" />}
        />
      )}
    </>
  )
}

TrainingInfoDialog.propTypes = {
  currentTraining: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    start_datetime: PropTypes.string.isRequired,
    end_datetime: PropTypes.string,
    meeting_datetime: PropTypes.string,
    intensity: PropTypes.number,
    location: PropTypes.string,
    focus: PropTypes.string,
    details: PropTypes.string,
    availability_cutoff_time: PropTypes.number,
  }).isRequired,
  updateTraining: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  isTrainingSeries: PropTypes.bool.isRequired,
}

export default connect(
  ({ trainings: { current }, auth }) => ({
    currentTraining: current,
    timezone: auth.user.time_zone,
  }),
  { updateTraining: trainings.updateTraining }
)(TrainingInfoDialog)
