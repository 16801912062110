import { styled } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

export const EmptyArea = styled(Grid)(({ theme }) => ({
  '& > div': {
    minHeight: '80px',
    marginBottom: '0',
    lineHeight: '17px',
    letterSpacing: '0.1px',
    fontWeight: '300',
    color: theme.palette.text.primary,
  },
  '& > div > div:first-child': {
    marginBottom: '5px',
    color: theme.palette.secondary.main,
  },
}));

export const Container = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0.5),
  border: '1px solid #ededed',
  background: '#fff',
  borderRadius: '8px',
  boxShadow: '14px 5px 35px 0 rgba(0, 0, 0, 0.08)',

  '& > div': {
    '& > div:nth-child(2)': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 -5px',

      '& > div': {
        flex: '0 0 25%',
        maxWidth: '25%',
        marginTop: '-10px',
        padding: '0 5px',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '& > div': {
      '& > div:nth-child(2)': {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -5px',

        '& > div': {
          flex: '0 0 100%',
          maxWidth: '100%',
          marginTop: '-10px',
          padding: '0 5px',
        },
      },
    },
  },
}))

export const Summary = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  cursor: 'pointer',

  '& > span': {
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.14px',
    color: theme.palette.secondary.main,
  },
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    textAlign: 'center',
    width: '40px',
    height: '40px',
    lineHeight: '38px !important',
    border: '1px solid #ededed',
    borderRadius: '50%',
    fontSize: '19px',
    letterSpacing: '0.13px',
    color: theme.palette.text.primary,
  },
}))

export const TrainingNotAvailable = styled('div')(({ theme }) => ({
  background: '#ededed',
  width: '100%',
  padding: theme.spacing(1),
  borderRadius: '8px',
  fontSize: '1.1rem',
  textAlign: 'center',
  color: '#898989',
}))