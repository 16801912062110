import { makeStyles, styled, Paper } from '@material-ui/core'

export const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    position: 'relative'
  },
  list: {
    '& .MuiListItemText-root': {
      textAlign: 'center',
    },
  },
  label: {
    padding: theme.spacing(1.5, 0),
    fontFamily: 'Chakra Petch, sans-serif',
    fontSize: theme.spacing(3.2),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& .MuiBox-root': {
      marginLeft: theme.spacing(0.8),
      fontSize: theme.spacing(4.8),
      lineHeight: '20px',
      color: theme.palette.primary.main,
    },
  },
  FormControl: {
    width: '100%',
    margin: '.5rem',
    '& .MuiInputLabel-formControl': {
      fontSize: '1.2rem',
    },
  },
  DialogSolutionContact: {
    '& .MuiDialog-paper': {
      maxWidth: 383,
      textAlign: 'center',
    },
  },

  PlanCycleSelected: {
    background: 'rgba(41, 163, 115, 1)',
    color: '#fff',
  },
  toggleButtonGroup: {
    borderRadius: '4rem',
    backgroundColor: '#ebf5f0',

    '& .MuiButton-root:first-child': {
      borderRadius: '1.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      minWidth: 100,
    },
    '& .MuiButton-root:last-child': {
      borderRadius: '1.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      minWidth: 100,
    },
    '& .MuiButton-root': {
      border: '0',
    },
  },
  billingPlanGrid: {
    '& .MuiCard-root:hover': {
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
      borderColor: '#fff',
    },
  },
  SelectedPlan: {
    '& .MuiCard-root': {
      border: '2px solid rgba(41, 163, 115, 1)',
    },
    '& .MuiCard-root:hover': {
      border: '2px solid rgba(41, 163, 115, 1)',
      boxShadow: '14px 5px 35px 0 rgba(0,0,0,0.08)',
    },
  },
}))

export const PlanPriceGroupBox = styled('div')({
  padding: '1rem 0 0',
  textAlign: 'center',
  '& div': {
    fontSize: '3rem',
    lineHeight: '3rem',
    fontWeight: 'normal',
  },
})

export const SavePlan = styled('div')({
  display: 'inline-block',
  paddingLeft: '0.5rem',
  color: 'var(--primary-green)',
})

export  const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: '150px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export  const StyledPricingPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2.4),
  borderRadius: '8px !important',
  boxShadow: 'none !important',
}))

export  const InputBaseStyle = styled('div')(({ theme }) => ({
  '& .MuiInputBase-root': {
    minWidth: theme.spacing(32),
  },
}))