import React from 'react'
import { withRouter } from 'react-router-dom'
import { ArticlesLoader, FavoritesLoader } from 'loaders'
import PropTypes from 'prop-types'
import ArticlesWidgetContainer from './ArticlesWidgetContainer'

const ArticlesWidget = ({
  match: {
    params: { teamUuid, seasonId },
  },
  clubid,
}) => (
  <ArticlesLoader
    params={{
      limit: 20,
      order_by: 'random',
      team_id: teamUuid,
      season_id: seasonId,
      clubid,
    }}
    alwaysRenderChildren
    passIsLoading
  >
    <FavoritesLoader entity="articles">
      <ArticlesWidgetContainer clubid={clubid} />
    </FavoritesLoader>
  </ArticlesLoader>
)

ArticlesWidget.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      teamUuid: PropTypes.string.isRequired,
      seasonId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired
    }).isRequired
  }).isRequired,
  clubid: PropTypes.number.isRequired
}

export default withRouter(ArticlesWidget)
