import { Box, Paper, Tabs, Tab, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import useStyles from '../styles'
import ActiveMembers from './ActiveMembers'
import PendingInvites from './PendingInvites'

const AllMembers = () => {
  const classes = useStyles()
  const [value, setValue] = useState('active')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box component={Paper} className={classes.invites}>
        <Typography variant="h4" className={classes.invitesTitle}>
          <Translate id="team.members.all-members" />
        </Typography>
        <Box className={classes.tabContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={<Translate id="movePlayers.active" />}
              value="active"
              className={classes.tab}
            />
            <Tab
              label={<Translate id="player.pending" />}
              value="pending"
              className={classes.tab}
            />
          </Tabs>
        </Box>
        <Box>
          {value === 'active' && <ActiveMembers />}
          {value === 'pending' && <PendingInvites />}
        </Box>
      </Box>
    </>
  )
}

export default AllMembers
