import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'TRAINING'

const FETCH_SEASON_TRAININGS = actionsTypesGenerator(
  `FETCH_SEASON_${namespace}S`
)
const FETCH_TRAINING = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_TRAINING = actionsTypesGenerator(`CREATE_${namespace}`)
const DUPLICATE_TRAINING = actionsTypesGenerator(`DUPLICATE_${namespace}`)
const UPDATE_TRAINING = actionsTypesGenerator(`UPDATE_${namespace}`)
const SHARE_TRAINING = actionsTypesGenerator(`SHARE_${namespace}`)
const DELETE_TRAINING = actionsTypesGenerator(`DELETE_${namespace}`)
const DELETE_TRAINING_NOTE = actionsTypesGenerator(`DELETE_${namespace}_NOTE`)
const SCHEDULE_TRAININGS = actionsTypesGenerator(`SCHEDULE_${namespace}S`)
const CREATE_TRAINING_NOTE = actionsTypesGenerator(`CREATE_${namespace}_NOTE`)
const UPDATE_TRAINING_NOTE = actionsTypesGenerator(`UPDATE_${namespace}_NOTE`)
const FETCH_TRAINING_NOTE = actionsTypesGenerator(`FETCH${namespace}_NOTE`)
const UPDATE_TRAINING_PARTICIPANT = actionsTypesGenerator(
  `UPDATE_${namespace}_PARTICIPANT`
)
const UPDATE_TRAINING_PARTICIPANTS = actionsTypesGenerator(
  `UPDATE_${namespace}_PARTICIPANTS`
)
export const fetchSeasonTrainings = (seasonId) => ({
  types: FETCH_SEASON_TRAININGS,
  callAPI: {
    method: 'GET',
    entity: 'Trainings',
    path: `/seasons/${seasonId}/trainings`,
  },
})

export const fetchTraining = (uuid, params) => ({
  types: FETCH_TRAINING,
  callAPI: {
    method: 'GET',
    entity: 'Training',
    path: `/v2/trainings/${uuid}`,
    params,
  },
})

export const createTraining = (trainingUuid, data) => ({
  types: CREATE_TRAINING,
  callAPI: {
    method: 'POST',
    entity: 'Training',
    path: `/v2/seasons/${trainingUuid}/trainings`,
    data,
  },
})

export const duplicateTraining = (trainingUuid, data) => ({
  types: DUPLICATE_TRAINING,
  callAPI: {
    method: 'POST',
    entity: 'Training',
    path: `/v2/trainings/${trainingUuid}/duplicate`,
    data,
  },
})

export const updateTraining = (uuid, data, params) => ({
  types: UPDATE_TRAINING,
  callAPI: {
    method: 'PATCH',
    entity: 'Training',
    path: `/v2/trainings/${uuid}`,
    data,
    params,
  },
})

export const shareTraining = (id, paramsString) => ({
  types: SHARE_TRAINING,
  callAPI: {
    method: 'SHARE',
    entity: 'Training',
    path: `/trainings/${id}/share`,
    paramsString,
  },
})

export const deleteTraining = (trainingUuid, params) => ({
  types: DELETE_TRAINING,
  callAPI: {
    method: 'DELETE',
    entity: 'Training',
    path: `/v2/trainings/${trainingUuid}`,
    params,
  },
})

export const scheduleTrainings = (data) => ({
  types: SCHEDULE_TRAININGS,
  callAPI: {
    method: 'POST',
    entity: 'Training schedule',
    path: '/trainings/schedule',
    data,
  },
})
export const scheduleOnlyTrainings = (data) => ({
  types: SCHEDULE_TRAININGS,
  callAPI: {
    method: 'POST',
    entity: 'Training schedule',
    path: '/trainings/scheduleTraining',
    data,
  },
})

export const fetchTrainingNotes = (trainingUuid, params) => ({
  types: FETCH_TRAINING_NOTE,
  callAPI: {
    method: 'GET',
    entity: 'Training note',
    path: `/v2/trainings/${trainingUuid}/training-notes`,
    params,
  },
})

export const createTrainingNote = (trainingUuid, data) => ({
  types: CREATE_TRAINING_NOTE,
  callAPI: {
    method: 'POST',
    entity: 'Training note',
    path: `/v2/trainings/${trainingUuid}/training-notes`,
    data,
  },
  payload: { trainingUuid },
})

export const updateTrainingNote = (trainingNoteUuid, data) => ({
  types: UPDATE_TRAINING_NOTE,
  callAPI: {
    method: 'PUT',
    entity: 'Training note',
    path: `/v2/training-notes/${trainingNoteUuid}`,
    data,
  },
})

export const deleteTrainingNote = (trainingUuid) => ({
  types: DELETE_TRAINING_NOTE,
  callAPI: {
    method: 'DELETE',
    entity: 'Training note',
    path: `/v2/training-notes/${trainingUuid}`,
  },
  payload: { trainingUuid },
})

export const updateTrainingParticipant = (trainingPlayer, data) => ({
  types: UPDATE_TRAINING_PARTICIPANT,
  callAPI: {
    method: 'PATCH',
    entity: 'Training Participant',
    path: `/v2/training-players/${trainingPlayer}/`,
    data: { ...data, preventToastNotification: true },
  },
})

export const updateTrainingParticipantsInBulk = (trainingUuid, data) => ({
  types: UPDATE_TRAINING_PARTICIPANTS,
  callAPI: {
    method: 'PATCH',
    entity: 'Training Participants',
    path: `/v2/trainings/${trainingUuid}/participants`,
    data: { ...data, preventToastNotification: true },
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_SEASON_TRAININGS.request,
      FETCH_TRAINING.request,
      CREATE_TRAINING.request,
      DUPLICATE_TRAINING.request,
      UPDATE_TRAINING.request,
      DELETE_TRAINING.request,
      DELETE_TRAINING_NOTE.request,
      SCHEDULE_TRAININGS.request,
      FETCH_TRAINING_NOTE.request,
      CREATE_TRAINING_NOTE.request,
      UPDATE_TRAINING_NOTE.request,
      UPDATE_TRAINING_PARTICIPANT.request
    )]: onRequest,
    [FETCH_SEASON_TRAININGS.success]: (
      state,
      { response: { data: items = [], team_data: teamData = {} } }
    ) => onSuccess({ ...state, items, teamData }),
    [combineActions(
      FETCH_TRAINING.success,
      CREATE_TRAINING.success,
      DUPLICATE_TRAINING.success,
      UPDATE_TRAINING.success
    )]: (state, { response: { data = {}, team_data: teamData = {} }, id }) => {
      const items = state.items.updateById(id, { ...data, isNew: false })

      return onSuccess({
        ...state,
        items,
        current: { ...state.current, ...data },
        teamData,
      })
    },
    [combineActions(
      FETCH_TRAINING_NOTE.success,
      CREATE_TRAINING_NOTE.success,
      UPDATE_TRAINING_NOTE.success,
      UPDATE_TRAINING_PARTICIPANT.success,
      UPDATE_TRAINING_PARTICIPANTS.success
    )]: (state) => onSuccess({ ...state }),

    [DELETE_TRAINING.success]: (state, { id }) =>
      onSuccess({ ...state, items: state.items.filterById(id) }),

    [SCHEDULE_TRAININGS.success]: (state, { response: { data: items = [] } }) =>
      onSuccess({ ...state, items: [...state.items, ...items] }),

    [combineActions(
      FETCH_SEASON_TRAININGS.failure,
      FETCH_TRAINING.failure,
      CREATE_TRAINING.failure,
      DUPLICATE_TRAINING.failure,
      UPDATE_TRAINING.failure,
      DELETE_TRAINING.failure,
      DELETE_TRAINING_NOTE.failure,
      SCHEDULE_TRAININGS.failure,
      CREATE_TRAINING_NOTE.failure,
      UPDATE_TRAINING_NOTE.failure,
      UPDATE_TRAINING_PARTICIPANT.failure
    )]: onFailure,
  },
  initialState.trainings
)
