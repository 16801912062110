import React from 'react'
import { compose } from 'redux'
import { withLocalize, Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { Button, Grid, Box } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { Input, LanguageDropdown } from 'components'
import { getLocale } from 'utils/localstorage'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import createJoiValidator from 'utils/createJoiValidator'
import Joi from 'joi'
import InputPassword from 'components/inputes/InputPassword'
import useStyles from 'routes/styleComponents'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'

const ResetLink = `${process.env.REACT_APP_API_URL}/password/reset`

const validationSchema = Joi.object().keys({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(255)
    .label(getTranslatedLabelForFormField('form.e-mail'))
    .required(),
  password: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('login.password')),
})

const SignInForm = ({ clubId, auth, theme, onLanguageChange, handleSubmit }) => {
  const classes = useStyles(theme)

  return (
    <Grid md={12} item>
      <Box mb={1}>
        <Field
          id="email"
          name="email"
          label={<Translate id="form.e-mail" />}
          component={Input}
          required
        />
      </Box>
      <Box mb={1}>
        <Field
          id="password"
          component={InputPassword}
          label={<Translate id="login.password" />}
          name="password"
          required
        />
      </Box>
      <Box mb={1}>
        {clubId === 1 && (
          <LanguageDropdown
            language={getLocale()}
            handleChange={onLanguageChange}
            margin="none"
            label={<Translate id="login.language" />}
          />
        )}
      </Box>
      <Box align="right" mb={0.8}>
        <Link href={ResetLink} className={classes.link} underline="always">
          <Translate id="login.forgot-password" />
        </Link>
      </Box>
      <Box align="center">
        <Button
          fullWidth
          variant="contained"
          type="button"
          className={classes.button}
          size="large"
          disabled={auth.isLoading}
          onClick={handleSubmit}
        >
          <Translate id="login.login-btn" />
        </Button>
      </Box>
    </Grid>
  )
}

SignInForm.propTypes = {
  clubId: PropTypes.number.isRequired,
  auth: PropTypes.shape().isRequired,
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
}

export default compose(
  withLocalize,
  reduxForm({
    form: 'signinForm',
    validate: createJoiValidator(validationSchema),
  }),
  withRouter,
  connect(
    ({ auth, clubs }) => ({
      auth,
      theme: clubs.current ? clubs.current.theme : {},
    }),
    null
  )
)(SignInForm)
