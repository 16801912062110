import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  SignIn,
  Logout,
  WrongPermission,
  NoMatch,
  Signup,
  LoginWithSocialSites,
  TeamsDashboard,
} from './routes'
import {
  FirstEntryRouter,
  RouteWithSubRoutes,
  CheckAuth,
} from './containers'

import SsoCallback from './routes/SsoCallback'
import {
  REGISTER_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  WRONG_PERMISSION_ROUTE,
  NO_MATCH_ROUTE,
  TEAMS_ROUTE,
  INITIAL_ROUTE,
  SOCIAL_LOGIN_ROUTE,
} from 'constants/routes'

const AllRoutes = () => (
  <Switch>
    <Route exact path={SOCIAL_LOGIN_ROUTE} component={LoginWithSocialSites} />
    <Route exact path="/sso/callback" component={SsoCallback} />
    <Route path={LOGIN_ROUTE} component={SignIn} />
    <Route exact path="/cfg" component={() => {
      return <SignIn isCfg />
    }} />
    <Route path={REGISTER_ROUTE} component={Signup} />
    <Route path={LOGOUT_ROUTE} component={Logout} />
    <Route path={WRONG_PERMISSION_ROUTE} component={WrongPermission} />

    <Route path={NO_MATCH_ROUTE} component={NoMatch} />
    <CheckAuth>
      <Switch>
        <Route exact path={TEAMS_ROUTE} component={TeamsDashboard} />
        <Route path={INITIAL_ROUTE}>
          <RouteWithSubRoutes />
        </Route>
        <FirstEntryRouter />
      </Switch>
    </CheckAuth>
  </Switch>
)

export default AllRoutes
