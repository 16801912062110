import React, { useState } from 'react'
import { Box, Divider, Tab, Tabs } from '@material-ui/core'
import TabPanel from 'components/material/TabPanel'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { taskGroups as tasksModule } from 'redux/modules'
import { track } from 'utils/segmentTracking'
import MatchTaskGroupsWidget from 'containers/TaskManagement/MatchTaskGroupsWidget'
import useTeamFeature from 'hooks/useTeamFeature'
import MatchHighlights from './MatchHighlights'
import MatchAllNotes from './MatchNotes'
import ConnectedSequences from './MatchVideoSequence/ConnectedSequences'
import LineUpContainer from './MatchLineUp'
import RatePlayers from './RatePlayers/RatePlayers'
import PropTypes from 'constants/propTypes'
import { MATCH_SECTIONS } from 'constants/match'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const TabViewContainer = ({ fetchMatchTaskGroups, matchUuid }) => {
  const [activeTab, setActiveTab] = useState(MATCH_SECTIONS.matchLineup)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { enabled: hasVideoSectionEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.video_manager_enabled
  )

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)

    // Segment tracking
    switch (newValue) {
      case MATCH_SECTIONS.highlights:
        track('WClick_MP_Highlights', {
          category: 'Matchday planner',
        })
        break
      case MATCH_SECTIONS.videoSequences:
        track('WClick_MP_VideoSequence', {
          category: 'Matchday planner',
        })
        break
      case MATCH_SECTIONS.notes:
        track('WClick_MP_Notes', {
          category: 'Matchday planner',
        })
        break
      case MATCH_SECTIONS.ratePlayers:
        track('WClick_MP_RatePlayer', {
          category: 'Matchday planner',
        })
        break
      case MATCH_SECTIONS.tasks:
        track('WClick_MP_Tasks', {
          category: 'Matchday planner',
        })
        break
      default:
        break
    }
  }

  return (
    <Box m={2}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant={isMobile ? 'scrollable' : 'standard'}
        scrollButtons={isMobile ? 'auto' : 'off'}
        indicatorColor="primary"
      >
        <Tab
          label={<Translate id="matches.team-line-up" />}
          value={MATCH_SECTIONS.matchLineup}
        />
        <Tab
          label={<Translate id="matches.highlights.title" />}
          value={MATCH_SECTIONS.highlights}
        />
        <Tab
          label={<Translate id="matches.match-notes" />}
          value={MATCH_SECTIONS.notes}
        />
        {hasVideoSectionEnabled && (
          <Tab
            label={<Translate id="matches.connected-sequences" />}
            value={MATCH_SECTIONS.videoSequences}
          />
        )}
        <Tab
          label={<Translate id="matches.rate-players.button" />}
          value={MATCH_SECTIONS.ratePlayers}
        />
        <Tab
          label={<Translate id="menu.task" />}
          value={MATCH_SECTIONS.tasks}
        />
      </Tabs>
      <Divider />
      <TabPanel
        value={activeTab}
        index={MATCH_SECTIONS.matchLineup}
        padding={1}
      >
        <LineUpContainer />
      </TabPanel>
      <TabPanel value={activeTab} index={MATCH_SECTIONS.highlights}>
        <MatchHighlights />
      </TabPanel>
      <TabPanel value={activeTab} index={MATCH_SECTIONS.notes}>
        <MatchAllNotes />
      </TabPanel>
      {hasVideoSectionEnabled && (
        <TabPanel value={activeTab} index={MATCH_SECTIONS.videoSequences}>
          <ConnectedSequences />
        </TabPanel>
      )}
      <TabPanel value={activeTab} index={MATCH_SECTIONS.ratePlayers}>
        <RatePlayers />
      </TabPanel>
      <TabPanel value={activeTab} index={MATCH_SECTIONS.tasks}>
        <MatchTaskGroupsWidget loader={() => fetchMatchTaskGroups(matchUuid)} />
      </TabPanel>
    </Box>
  )
}

TabViewContainer.propTypes = {
  fetchMatchTaskGroups: PropTypes.func.isRequired,
  matchUuid: PropTypes.string.isRequired,
}

export default compose(
  connect(
    ({
      matches: {
        current: { id, uuid },
      },
    }) => ({
      currentMatchId: id,
      matchUuid: uuid,
    }),
    {
      fetchMatchTaskGroups: tasksModule.fetchMatchTaskGroups,
    }
  )
)(TabViewContainer)
