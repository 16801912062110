import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'SEASONS'

const FETCH_SEASONS = actionsTypesGenerator(`FETCH_${namespace}`)
const CREATE_SEASON = actionsTypesGenerator(`CREATE_${namespace}`)
const UPDATE_SEASON = actionsTypesGenerator(`UPDATE_${namespace}`)
const DELETE_SEASON = actionsTypesGenerator(`DELETE_${namespace}`)
const FETCH_PLAYER_ATTENDANCE = actionsTypesGenerator(
  `FETCH_${namespace}_ATTENDANCE`
)

export const fetchSeasons = (teamId) => ({
  types: FETCH_SEASONS,
  callAPI: {
    method: 'GET',
    entity: 'Seasons',
    path: `/teams/${teamId}/seasons`,
  },
  payload: { teamId },
})

export const createSeason = (data) => ({
  types: CREATE_SEASON,
  callAPI: {
    method: 'POST',
    entity: 'Season',
    path: '/seasons',
    data,
  },
})

export const updateSeason = (seasonId, data) => ({
  types: UPDATE_SEASON,
  callAPI: {
    method: 'PUT',
    entity: 'Season',
    path: `/seasons/${seasonId}`,
    data,
  },
  payload: { seasonId },
})

export const deleteSeason = (teamId, seasonId) => ({
  types: DELETE_SEASON,
  callAPI: {
    method: 'DELETE',
    entity: 'Season',
    path: `/seasons/${seasonId}`,
  },
  payload: { teamId, seasonId },
})

export const fetchPlayersAttendance = (id, params) => ({
  types: FETCH_PLAYER_ATTENDANCE,
  callAPI: {
    method: 'GET',
    entity: 'Team players Attendance',
    path: `/seasons/${id}/players-attendance`,
    params,
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_SEASONS.request,
      CREATE_SEASON.request,
      UPDATE_SEASON.request,
      DELETE_SEASON.request,
      FETCH_PLAYER_ATTENDANCE.request
    )]: onRequest,
    [FETCH_SEASONS.success]: (state, { response: { data = [] } }) =>
      onSuccess({ ...state, items: data }),
    [FETCH_PLAYER_ATTENDANCE.success]: (state, { response: { data = [] } }) =>
      onSuccess({ ...state, attendance: data }),
    [CREATE_SEASON.success]: (state, { response: { data = {} } }) =>
      onSuccess({
        ...state,
        items: [...state.items, data],
      }),
    [UPDATE_SEASON.success]: (state, { response: { data = {} }, seasonId }) =>
      onSuccess({
        ...state,
        items: state.items.updateById(+seasonId, data),
      }),
    [DELETE_SEASON.success]: (state, { seasonId }) =>
      onSuccess({
        ...state,
        items: state.items.filterById(+seasonId),
      }),
    [combineActions(
      FETCH_SEASONS.failure,
      CREATE_SEASON.failure,
      UPDATE_SEASON.failure,
      DELETE_SEASON.failure
    )]: onFailure,
  },
  initialState.seasons
)
