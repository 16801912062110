import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Box,
} from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import { SubLink } from 'containers'
import { PlayIcon } from 'components'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PropTypes from 'prop-types'
import useStyles from './useStyles'
import { VIDEO_ROUTE } from 'constants/routes'

const VideoCard = ({ videos, onComplete }) => {
  const classes = useStyles()

  return (
    <List dense className={classes.root}>
      {videos.map((video) => {
        return (
          <ListItem key={video.id} button>
            <Box
              display="flex"
              alignItems="center"
              component={SubLink}
              to={VIDEO_ROUTE.populateURLParams({
                videoId: video.id,
              })}
            >
              <ListItemAvatar>
                <Avatar
                  className={classes.media}
                  variant="rounded"
                  alt="video-thumbnail"
                  src={video.processed === 1 ? video.thumbnail_url : ''}
                >
                  <Box color="primary" className={classes.PlayCircle}>
                    <PlayIcon />
                  </Box>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={video.title}
                className={classes.typography}
                primaryTypographyProps={{ noWrap: true }}
              />
            </Box>
            <ListItemSecondaryAction>
              {video.completed ? (
                <CheckCircleIcon color="primary" />
              ) : (
                <Tooltip
                  title={<Translate id="videos.mark-as-complete" />}
                  arrow
                >
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => onComplete(video.id, 'completed')}
                  >
                    <CheckCircleIcon color="disabled" />
                  </IconButton>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </List>
  )
}

VideoCard.defaultProps = {
  videos: [],
  onComplete: () => {},
}

VideoCard.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      thumbnail_url: PropTypes.string.isRequired,
      processed: PropTypes.bool.isRequired,
    })
  ),
  onComplete: PropTypes.func,
}

export default VideoCard
