import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import CreateTeamForm from 'containers/CreateTeamForm'
import CircularSpinner from 'components/loader/CircularSpinner'
import { team } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const CreateTeamPopup = ({
  open,
  handleClose,
  selectedClubUuid,
  createTeamByClub,
  ageClasses,
  selectedClub,
  onApplyTeamFilters,
}) => {
  const [loader, setLoader] = useState(false)

  const handleSubmit = (values) => {
    setLoader(true)

    const selectedAgeClassUuid = values.age_class_uuid
    const selectedTagName = values.tag
    let selectedTagUuid = selectedTagName

    createTeamByClub(selectedClubUuid, values)
      .then(() => {
        if (selectedClubUuid) {
          selectedTagUuid = selectedClub?.relationships?.team_tags.find(
            (tag) => tag.name === selectedTagName
          )?.uuid

          if (onApplyTeamFilters) {
            onApplyTeamFilters(selectedAgeClassUuid, selectedTagUuid)
          }
          setLoader(false)
          handleClose()
        }
      })
      .catch((error) => {
        setLoader(false)
        toast(error.error)
        handleClose()
      })
  }

  return (
    <div>
      {loader ? (
        <CircularSpinner />
      ) : (
        <Translate>
          {({ translate }) => (
            <CreateTeamForm
              form="createTeamForm"
              initialValues={{
                name: '',
                age_class_uuid: '',
                gender: 'm',
                tag: '',
              }}
              title={translate('myteams.create-team')}
              open={open}
              onClose={handleClose}
              onSubmit={handleSubmit}
              ageClasses={ageClasses}
              selectedClub={selectedClub}
            />
          )}
        </Translate>
      )}
    </div>
  )
}

CreateTeamPopup.defaultProps = {
  open: false,
  selectedClubUuid: null,
}

CreateTeamPopup.propTypes = {
  handleClose: PROP_TYPES.func.isRequired,
  open: PROP_TYPES.bool,
  selectedClubUuid: PropTypes.string,
  createTeamByClub: PropTypes.func.isRequired,
  ageClasses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedClub: PropTypes.shape().isRequired,
  onApplyTeamFilters: PropTypes.func.isRequired,
}

export default connect(null, {
  createTeamByClub: team.createTeamByClub,
})(CreateTeamPopup)
