import React from 'react'
import { connect } from 'react-redux'
import { clubs } from 'redux/modules'
import PropTypes from 'prop-types'
import Loader from './Loader'


/**
 * Load and store current team's club.
 */
const ClubLoader = ({ children, fetchClub, team, ...rest }) => {
  return (
    <>
      {team && (
        <Loader
          fetchMethod={() => fetchClub(team.club_uuid)}
          triggers={[team.club_uuid]}
          {...rest}
        >
          {children}
        </Loader>
      )}
    </>
  )
}

ClubLoader.defaultProps = {
  teams: []
}

ClubLoader.propTypes = {
  children: PropTypes.node.isRequired,
  fetchClub: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object.isRequired),
  team: PropTypes.shape({
    club_uuid: PropTypes.string.isRequired,
  }).isRequired,
}

export default connect(
  ({ team }) => ({
    team,
  }),
  { fetchClub: clubs.fetchClub }
)(ClubLoader)
