import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  Paper,
  CardHeader,
  Card,
  Grid,
  MenuItem,
  Link,
  Box,
  Avatar,
  CardContent,
  Divider,
  Typography,
  Tooltip,
  ClickAwayListener,
  Hidden,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ShareIcon from '@material-ui/icons/Share'
import {
  formateUTCTimeZoneDate,
  formatUtcDateToDateTime,
  formatUtcDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import { matches } from 'redux/modules'
import AlertDialog from 'components/AlertDialog'
import DropDownMenu from 'components/DropDownMenu'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { compose } from 'redux'
import EventPopup from 'components/material/Calender/eventPopup'
import { track } from 'utils/segmentTracking'
import MatchInfoEditDialog from './MatchInfoEditDialog'
import MatchTitle from './MatchTitle'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'

const useStyles = makeStyles((theme) => ({
  noteContent: {
    boxShadow: '10px 5px 35px 0 rgba(0,0,0,0.08)',
  },
  styledCardHeader: {
    textAlign: 'center',
    padding: theme.spacing(2.1),
    position: 'relative',
    '& .MuiCardHeader-action': {
      position: 'absolute',
      right: 0,
      top: 0,
      margin: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        zIndex: 1098,
        top: 14,
      },
    },
  },
  vsIcon: {
    backgroundColor: theme.palette.primary.light,
  },
  [theme.breakpoints.down('sm')]: {
    mobileGrid: {
      padding: `${theme.spacing(0)} !important`,
      fontSize: 11,
      '& u': {
        fontSize: 11,
      },
      '& .MuiGrid-item small': {
        display: 'none',
      },
    },
    vsIcon: {
      height: 28,
      width: 28,
    },
  },
}))

const MatchInfo = ({
  dateFormat,
  currentMatch,
  deleteMatch,
  createMatch,
  history,
  seasons,
  match: {
    params: { seasonId },
  },
  timezone,
  language,
  team,
}) => {
  const classes = useStyles()
  const [matchCreateDialogOpen, setMatchCreateDialogOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState()
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [duplicateData, changeDuplicateData] = useState(null)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [matchInfoEditOpen, setMatchInfoEditOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(false)
  const [seasonUuid, setSeasonUuid] = useState(null)

  const handleInfoTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleInfoTooltipOpen = () => {
    setOpenTooltip(true)
  }

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget)

    // Segment tracking
    track('WClick_MP_Settings', {
      category: 'Matchday planner',
    })
  }
  const handleCloseOptions = () => {
    setAnchorEl(null)
  }
  const handleClickMatchInfoEditOpen = () => {
    setMatchInfoEditOpen(true)
    setAnchorEl(null)

    // Segment tracking
    track('WClick_MP_SettingEdit', {
      category: 'Matchday planner',
    })
  }
  const handleClickMatchInfoEditClose = () => {
    setMatchInfoEditOpen(false)
  }
  const handleAlertBoxClose = () => {
    setAlertOpen(false)
  }
  const handleAlertBoxOpen = () => {
    setAlertOpen(true)
    setAnchorEl(null)

    // Segment tracking
    track('WClick_MP_SettingDelete', {
      category: 'Matchday planner',
    })
  }

  const handleAlertConfirm = () => {
    deleteMatch(currentMatch.uuid).then(() => {
      history.push(`/team/${team.uuid}/season/${+seasonId}/events`)
      handleAlertBoxClose()
    })
  }

  const handleDuplicateClick = (text = '') => {
    setTrainingAndGame(text)
    setMatchCreateDialogOpen(true)
    setAnchorEl(null)

    const tempMatchData = {
      ...currentMatch,
      home: +currentMatch.home,
      date: formatUtcDateTimeToUserTimezoneDateTime(
        currentMatch.start_datetime,
        timezone
      ),
      end_time: currentMatch.end_datetime
        ? formatUtcDateTimeToUserTimezoneDateTime(
            currentMatch.end_datetime,
            timezone
          )
        : null,
      meeting_time: currentMatch.meeting_datetime
        ? formatUtcDateTimeToUserTimezoneDateTime(
            currentMatch.meeting_datetime,
            timezone
          )
        : null,
    }
    changeDuplicateData(tempMatchData)
    setIsDuplicate(true)

    // Segment tracking
    track('WClick_MP_SettingDuplicate', {
      category: 'Matchday planner',
    })
  }
  const handleClose = () => {
    setMatchCreateDialogOpen(false)
    changeDuplicateData(null)
    setIsDuplicate(false)
  }

  const createNewMatch = (data) => {
    createMatch(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${team.uuid}/season/${+seasonId}/matches/${uuid}`)
    })
  }
  const handleShareClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const shareUrl = `${process.env.REACT_APP_API_URL}/matches/${currentMatch.uuid}/share?language=${language}&time_zone=${timezone}`
    window.open(shareUrl, '_blank')
    // Segment tracking
    track('WClick_MP_SettingDownload', {
      category: 'Matchday planner',
    })
  }

  // #remove Once season uuid can be accessed from url we dont need to find season like this
  useEffect(() => {
    if (seasons.length > 0) {
      const season = seasons.find((s) => s.id === +seasonId)
      setSeasonUuid(season.uuid)
    }
  }, [seasons])

  return (
    <Box m={[2, 4]}>
      {matchCreateDialogOpen && (
        <EventPopup
          open={matchCreateDialogOpen}
          onClose={handleClose}
          duplicateData={duplicateData}
          trainingAndGame={trainingAndGame}
          createMatch={createNewMatch}
          selectedEventType={trainingAndGame}
          isDuplicate={isDuplicate}
        />
      )}
      {alertOpen && (
        <AlertDialog
          open={alertOpen}
          onClose={handleAlertBoxClose}
          onSuccess={handleAlertConfirm}
          onCancel={handleAlertBoxClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      <Paper className={classes.noteContent}>
        {matchInfoEditOpen && (
          <MatchInfoEditDialog
            open={matchInfoEditOpen}
            handleClose={handleClickMatchInfoEditClose}
          />
        )}
        <Card>
          <CardHeader
            className={classes.styledCardHeader}
            title={<MatchTitle currentMatch={currentMatch} />}
            action={
              <>
                <IconButton
                  onClick={handleOptionsClick}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                >
                  <MoreVertIcon />
                </IconButton>
                <DropDownMenu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  elevation={2}
                  keepMounted
                  edge="start"
                  open={Boolean(anchorEl)}
                  onClose={handleCloseOptions}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={handleClickMatchInfoEditOpen}>
                    <EditIcon from fontSize="small" />{' '}
                    <Translate id="button.edit" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDuplicateClick(TRAININGS_GAME.game)}
                  >
                    <FileCopyIcon fontSize="small" />{' '}
                    <Translate id="button.duplicate" />
                  </MenuItem>
                  <MenuItem onClick={handleShareClick}>
                    <ShareIcon fontSize="small" />{' '}
                    <Translate id="button.share" />
                  </MenuItem>
                  <MenuItem onClick={handleAlertBoxOpen}>
                    <DeleteIcon fontSize="small" />{' '}
                    <Translate id="button.delete" />
                  </MenuItem>
                </DropDownMenu>
              </>
            }
          />
          <CardContent>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} md={7}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <Box textAlign="center">
                      <Typography variant="h3">{team.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box textAlign="center">
                      <Typography variant="h2">
                        {currentMatch.goals_scored}
                      </Typography>
                    </Box>
                  </Grid>
                  <Avatar className={classes.vsIcon}>
                    <Translate id="matches.vs" />
                  </Avatar>
                  <Grid item xs={1}>
                    <Box textAlign="center">
                      <Typography variant="h2">
                        {currentMatch.goals_received}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box textAlign="center">
                      <Typography variant="h3">
                        {currentMatch.opponent}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                item
                xs={12}
                md={10}
                spacing={0}
                className={classes.mobileGrid}
              >
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="team.date" />
                    </small>
                  </Grid>
                  <Grid item>
                    <DateRangeOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {formateUTCTimeZoneDate(
                        currentMatch.start_datetime,
                        timezone,
                        dateFormat
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="events.location" />
                    </small>
                  </Grid>
                  <Grid item>
                    <MapOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      <Link
                        href={`https://maps.google.com/?q=${currentMatch.lat},${currentMatch.lng}`}
                        target="_blank"
                      >
                        {currentMatch.city || currentMatch.country || '-'}
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="matches.meeting-time" />
                    </small>
                  </Grid>
                  <Grid item>
                    <GroupOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {currentMatch.meeting_datetime
                        ? formatUtcDateToDateTime(
                            currentMatch.meeting_datetime,
                            timezone
                          )[1]
                        : '-'}
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="matches.kickoff-time" />
                    </small>
                  </Grid>
                  <Grid item>
                    <TimerOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {
                        formatUtcDateToDateTime(
                          currentMatch.start_datetime,
                          timezone
                        )[1]
                      }
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <DescriptionOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      <Hidden smDown>
                        <Tooltip title={currentMatch.details || '-'} arrow>
                          <Typography>
                            <u style={{ textDecorationStyle: 'dotted' }}>
                              Info
                            </u>
                          </Typography>
                        </Tooltip>
                      </Hidden>
                      <Hidden mdUp>
                        <ClickAwayListener onClickAway={handleInfoTooltipClose}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleInfoTooltipClose}
                            open={openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={currentMatch.details || '-'}
                            arrow
                          >
                            <Typography onClick={handleInfoTooltipOpen}>
                              <u style={{ textDecorationStyle: 'dotted' }}>
                                Info
                              </u>
                            </Typography>
                          </Tooltip>
                        </ClickAwayListener>
                      </Hidden>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}

MatchInfo.propTypes = {
  dateFormat: PROP_TYPES.string.isRequired,
  currentMatch: PROP_TYPES.shape().isRequired,
  history: PROP_TYPES.shape().isRequired,
  createMatch: PROP_TYPES.func.isRequired,
  deleteMatch: PROP_TYPES.func.isRequired,
  team: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.match.isRequired,
  seasons: PROP_TYPES.arrayOfObjects.isRequired,
  timezone: PROP_TYPES.string.isRequired,
  language: PROP_TYPES.string.isRequired,
}

export default compose(
  connect(
    ({ team, matches: { current }, auth, seasons: { items } }) => ({
      team,
      currentMatch: current,
      dateFormat: auth.user.datetime_format,
      timezone: auth.user.time_zone,
      language: auth.user.language,
      seasons: items,
    }),
    {
      createMatch: matches.createMatch,
      deleteMatch: matches.deleteMatch,
    }
  ),
  withRouter
)(MatchInfo)
