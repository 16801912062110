import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Grid, Hidden } from '@material-ui/core'
import { PerformanceWidget } from 'components'
import { team as teamModule } from 'redux/modules'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import TeamSkillsRadarChartWidget from 'components/TeamSkillsRadarChartWidget'
import useTeamFeature from 'hooks/useTeamFeature'
import AllPlayers from './components/AllPlayers'
import { TeamSummary, TeamPlayersGrid } from './components'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const TeamDashboardContainer = ({
  match: {
    params: { seasonId },
  },
  team,
  seasons,
  getTeamSeasonStatistics,
}) => {
  const [matchesPerformances, setMatchesPerformances] = useState([])
  const [matchesRating, setMatchesRating] = useState(0)
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(false)

  const { enabled: hasTeamGamePerformanceEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.advanced_match_features_enable
  )
  const { enabled: hasAddPlayersEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.add_players_enabled
  )

  useEffect(() => {
    if (seasons.length > 0) {
      setIsStatisticsLoading(true)
      // #remove Once season uuid can be accessed from url we dont need to find season like this
      const season = seasons.find((s) => s.id === +seasonId)

      getTeamSeasonStatistics(season.uuid).then((res) => {
        const tempMatchesPerformance = res.response.data.matches_performance
        const tempMatchesRating = res.response.data.matches_rating

        setMatchesPerformances(tempMatchesPerformance)
        setMatchesRating(tempMatchesRating)
        setIsStatisticsLoading(false)
      })
    }
  }, [seasons])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              <TeamSummary matchesRating={matchesRating} />
            </Grid>
            {hasAddPlayersEnabled && (
              <Hidden only={['md', 'lg', 'xl']}>
                <Grid item xs={12}>
                  <Translate>
                    {({ translate }) => (
                      <AllPlayers title={translate('team.whole-team')} />
                    )}
                  </Translate>
                </Grid>
              </Hidden>
            )}
            <Grid item xs={12}>
              <TeamSkillsRadarChartWidget />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Grid container spacing={2} direction="column">
            {hasAddPlayersEnabled && (
              <Grid item xs={12}>
                <TeamPlayersGrid />
              </Grid>
            )}
            <Grid item xs={12}>
              {hasTeamGamePerformanceEnabled &&
                (!isStatisticsLoading || matchesPerformances.length > 0) && (
                  <Translate>
                    {({ translate }) => (
                      <PerformanceWidget
                        title={translate('team.match-performance')}
                        match={team}
                        isTeam
                        data={matchesPerformances}
                      />
                    )}
                  </Translate>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

TeamDashboardContainer.propTypes = {
  team: PropTypes.shape({
    add_players_enabled: PropTypes.bool.isRequired,
  }).isRequired,
  getTeamSeasonStatistics: PropTypes.func.isRequired,
  seasons: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      seasonId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default compose(
  connect(
    ({ team, seasons: { items } }) => ({
      team,
      seasons: items,
    }),
    {
      getTeamSeasonStatistics: teamModule.getTeamSeasonStatistics,
    }
  ),
  withRouter
)(TeamDashboardContainer)
