import React, { useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sequences } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const SequencesLoader = ({
  teamId,
  children,
  fetchSequences,
  ...restProps
}) => {
  const [order, changeOrder] = useState('desc')
  const [selectedTags, changeSelectedTags] = useState([])

  const orderState = {
    order,
    changeOrder,
  }

  const params = {
    order,
    team_id: teamId,
  }
  const players = selectedTags.find((p) => p.startsWith('players='))
  const matches = selectedTags.find((p) => p.startsWith('matches='))
  const tags = selectedTags.filter(
    (p) => !p.startsWith('matches=') && !p.startsWith('players=') && p !== ''
  )

  const paramsString = tags.length && `&tags[]=${tags.join('&tags[]=')}`
  let finalParamString = paramsString

  if (finalParamString === 0) {
    if (players !== undefined) {
      finalParamString = `&${players}`
    }
    if (matches !== undefined && players !== undefined) {
      finalParamString = `${finalParamString}&${matches}`
    } else if (matches !== undefined) {
      finalParamString = `&${matches}`
    }
  } else if (finalParamString !== 0) {
    if (players !== undefined) {
      finalParamString = `${finalParamString}&${players}`
    }
    if (matches !== undefined && players !== undefined) {
      finalParamString = `${finalParamString}&${matches}`
    } else if (matches !== undefined) {
      finalParamString = `&${matches}`
    }
  }

  return (
    <Loader
      fetchMethod={() => fetchSequences(finalParamString, params)}
      triggers={[order, selectedTags]}
      {...restProps}
    >
      {cloneElement(children, {
        orderState,
        selectedTags,
        changeSelectedTags,
      })}
    </Loader>
  )
}

SequencesLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  fetchSequences: PropTypes.func.isRequired,
  teamId: PROP_TYPES.id.isRequired,
}

export default connect(
  ({ team }) => ({
    teamId: team.id,
  }),
  { fetchSequences: sequences.fetchSequences }
)(SequencesLoader)
