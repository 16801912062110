import React from 'react'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import { Translate } from 'react-localize-redux'
import SubLink from 'containers/SubLink'
import { TeaserHead, TeaserFab, TeaserButton } from './styledComponents'
import PROP_TYPES from 'constants/propTypes'
import { TEAM_SUBSCRIPTION_UPGRADE_ROUTE } from 'constants/routes'

const TeaserHeader = ({ children, buttonLabel, isMessageTag }) => {
  // @todo Teaser header is hidden for now as needs discussion
  const isTeaserEnabled = false

  return (
    <>
      {isTeaserEnabled && (
        <TeaserHead>
          <TeaserFab variant="extended" color="primary">
            <AnnouncementIcon />
          </TeaserFab>
          {isMessageTag === true ? (
            <Translate id="teasers.message-pro" />
          ) : (
            <Translate id="teasers.message-plus" />
          )}
          <TeaserButton
            component={SubLink}
            to={TEAM_SUBSCRIPTION_UPGRADE_ROUTE}
            variant="contained"
            color="default"
          >
            {buttonLabel}
          </TeaserButton>
          {children}
        </TeaserHead>
      )}
    </>
  )
}

TeaserHeader.defaultProps = {
  buttonLabel: <Translate id="teasers.button" />,
  isMessageTag: true,
}

TeaserHeader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  isMessageTag: PROP_TYPES.bool,
  buttonLabel: PROP_TYPES.string,
}

export default TeaserHeader
