import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import Logo from '../../../assets/images/coachbetter_Logo_Black.svg'
import useStyles from '../../styleComponents'

const Header = ({ theme, subHeading, heading }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box align="center">
        <img
          src={theme ? theme.logo : Logo}
          alt="Coachbetter"
          className={classes.signupLogo}
        />
      </Box>
      <Box align="center" mt={2} mb={3}>
        <Typography variant="h1" component="h1" className={classes.heading}>
          {heading}
        </Typography>
        {subHeading &&
          <Typography
            variant="body1"
            component="p"
            className={classes.paragraph}
          >
            {subHeading}
          </Typography>
        }
      </Box>
    </Box>
  )
}
Header.defaultProps = {
  theme: {},
}
Header.propTypes = {
  theme: PropTypes.shape(),
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]).isRequired,
  subHeading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]).isRequired,
}

export default Header
