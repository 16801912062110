import {
  Typography,
  Button,
  Box,
  Grid,
  CardContent,
  Card,
  Paper,
  ButtonGroup,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { styles, PlanPriceGroupBox, SavePlan } from './useStyles'
import { plans, billingCycle } from 'constants/plans'

const PricingBilling = ({
  activePlan,
  changebillingCycle,
  changeSubscriptionPlan,
}) => {
  const classes = styles()

  return (
    <>
      <Paper className={classes.root}>
        <Box my={4}>
          <Typography variant="h5" component="h5" align="center">
            <Translate id="plans.header1" /> <Translate id="plans.header2" />
          </Typography>
        </Box>
        <Box my={4} align="center">
          <ButtonGroup
            className={classes.toggleButtonGroup}
            color="primary"
            size="large"
          >
            {billingCycle.map((cycle) => (
              <Button
                key={cycle.value}
                disabled={
                  activePlan.plan.value === 'champion' &&
                  cycle.value === 'monthly'
                }
                onClick={() => changebillingCycle(cycle.value)}
                className={
                  cycle.value === activePlan.billingCycle.value
                    ? classes.PlanCycleSelected
                    : ''
                }
              >
                {cycle.label}
              </Button>
            ))}
          </ButtonGroup>
          <SavePlan>
            <Translate id="plans.save-percentage" />
          </SavePlan>
        </Box>
        <Grid container spacing={3}>
          {plans.map((plan) => {
            const monthlyByYearlyPlan =
              plan !== 'champion' ? (plan.annual / 12).toFixed(2) : 0

            return (
              <Grid
                key={plan.value}
                item
                lg={4}
                sm={12}
                xs={12}
                className={classes.billingPlanGrid}
              >
                <div
                  className={
                    plan.value === activePlan.plan.value
                      ? classes.SelectedPlan
                      : ''
                  }
                >
                  <Card variant="outlined">
                    <CardContent>
                      <Typography
                        variant="h3"
                        component="h3"
                        className={classes.label}
                      >
                        {plan.label}{' '}
                        <Box variant="span" component="span">
                          {plan.sub_label}
                        </Box>
                      </Typography>
                      <Divider />
                      <Box py={3}>
                        <Typography variant="h6" component="h6" align="center">
                          {plan.benefitsDesc}
                        </Typography>

                        {plan.monthly ? (
                          <PlanPriceGroupBox>
                            {activePlan.billingCycle.value === 'monthly' && (
                              <>
                                <div>
                                  {`${
                                    activePlan.currency
                                  } ${plan.monthly.toFixed(2)}`}{' '}
                                </div>
                                <Typography variant="p" component="p">
                                  <Translate id="checkout.monthly" />
                                </Typography>
                                <Button
                                  onClick={() => changebillingCycle('annual')}
                                >
                                  <span>
                                    <Translate id="checkout.switch" />
                                    {` ${activePlan.currency}${(
                                      plan.monthly * 12 -
                                      plan.annual
                                    ).toFixed(2)}`}
                                  </span>
                                </Button>
                              </>
                            )}
                            {activePlan.billingCycle.value === 'annual' && (
                              <>
                                <div>
                                  {`${activePlan.currency} ${monthlyByYearlyPlan}`}{' '}
                                </div>
                                <Typography variant="p" component="p">
                                  <Translate id="checkout.monthly" />
                                </Typography>{' '}
                              </>
                            )}
                          </PlanPriceGroupBox>
                        ) : (
                          ''
                        )}

                        {!plan.monthly && (
                          <PlanPriceGroupBox>
                            <div>
                              <Translate id="plans.free" />
                            </div>
                          </PlanPriceGroupBox>
                        )}
                      </Box>
                      <Divider />
                      <List className={classes.list}>
                        {plan.benefits.map((benefit) => (
                          <ListItem key={JSON.stringify(benefit)}>
                            <ListItemText primary={benefit} />
                          </ListItem>
                        ))}
                      </List>
                      {plan.value !== 'basic' && (
                        <Button
                          fullWidth
                          onClick={() => changeSubscriptionPlan(plan.value)}
                          variant={
                            plan.value === activePlan.plan.value
                              ? 'contained'
                              : 'outlined'
                          }
                          size="large"
                          color="primary"
                        >
                          <Translate id="plans.selectplan" />
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            )
          })}
        </Grid>
      </Paper>
    </>
  )
}

PricingBilling.propTypes = {
  activePlan: PropTypes.shape().isRequired,
  changebillingCycle: PropTypes.func.isRequired,
  changeSubscriptionPlan: PropTypes.func.isRequired,
}
export default PricingBilling
