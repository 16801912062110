import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  Grid,
  Typography,
  DialogTitle as MuiDialogTitle,
  IconButton,
  withStyles,
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import BallIcon from 'assets/images/ballicon.svg'
import { Close as CloseIcon } from '@material-ui/icons'
import { Field, reduxForm } from 'redux-form'
import { Input, AvatarUploader } from 'components'
import Joi from 'joi'
import createJoiValidator from 'utils/createJoiValidator'
import SelectList from 'components/inputes/SelectList'
import { connect } from 'react-redux'
import { compose } from 'redux'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'
import CustomAutocomplete from 'components/CustomAutocomplete'
import countries from 'constants/countries'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 1.4, 1, 3),
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      marginLeft: 'auto',
    },
  },
})
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const validationSchema = Joi.object().keys({
  name: Joi.string()
    .max(255)
    .required()
    .label(getTranslatedLabelForFormField('myteams.team-name')),
  country: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('team.country')),
  league: Joi.string()
    .max(255)
    .required()
    .label(getTranslatedLabelForFormField('team.league')),
  age_class: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('team.age-class')),
  gender: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('team.gender')),
  tag: Joi.string().max(255).allow(null, '').optional(),
})

const TeamEditForm = ({
  open,
  onClose,
  handleSubmit,
  ageClasses,
  initialValues,
  images,
  handleImageChange,
  ageClassLocked,
  club,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle onClose={onClose}>
        <Translate id="myteams.edit-team" />
      </DialogTitle>
      <DialogContent dividers>
        <AvatarUploader
          images={images}
          srcUrl={initialValues.logo ? initialValues.logo.full_url : BallIcon}
          onChange={handleImageChange}
        />
        <Typography variant="h4" component="h4" gutterBottom>
          <Translate id="team.general-info" />
        </Typography>
        <Field
          name="name"
          label={<Translate id="myteams.team-name" />}
          component={Input}
          margin="dense"
          required
        />
        <Field
          name="country"
          component={SelectList}
          label={<Translate id="team.country" />}
          id="country"
          required
        >
          {countries
            .filter((p) => p.value !== '')
            .map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
        </Field>
        <Field
          name="league"
          label={<Translate id="team.league" />}
          component={Input}
          margin="dense"
          required
        />
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Field
              name="age_class"
              component={SelectList}
              label={<Translate id="team.age-class" />}
              id="age_class"
              required
              disabled={ageClassLocked}
            >
              {ageClasses.length > 0 &&
                ageClasses.map((ageClass) => (
                  <MenuItem key={ageClass.uuid} value={ageClass.uuid}>
                    {ageClass.name}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item md={6}>
            <Field
              name="gender"
              component={SelectList}
              label={<Translate id="team.gender" />}
              id="gender"
              required
            >
              <MenuItem value="f">
                <Translate id="form.female" />
              </MenuItem>
              <MenuItem value="m">
                <Translate id="form.male" />
              </MenuItem>
            </Field>
          </Grid>
          {club && club.enable_team_tags && (
            <Grid item md={12}>
              <Field
                name="tag"
                label={<Translate id="team.tag" />}
                component={CustomAutocomplete}
                value={initialValues.tag || ''}
                options={(club?.tag_names).map((tag) => ({ title: tag }))}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} fullWidth>
          <Translate id="button.cancel" />
        </Button>
        <Button
          fullWidth
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          <Translate id="button.save" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TeamEditForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ageClasses: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  initialValues: PropTypes.shape().isRequired,
  ageClassLocked: PropTypes.bool.isRequired,
  images: PropTypes.shape().isRequired,
  handleImageChange: PropTypes.func.isRequired,
  club: PropTypes.shape({
    enable_team_tags: PropTypes.bool.isRequired,
    tag_names: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  withLocalize,
  connect(
    ({ clubs }) => ({
      ageClassLocked: clubs.current.lock_team_age_class,
      club: clubs.current,
    }),
    {}
  ),
  reduxForm({
    form: 'teamUpdateForm',
    enableReinitialize: true,
    validate: createJoiValidator(validationSchema),
  })
)(TeamEditForm)
