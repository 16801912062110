import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { taskGroups } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'

const TaskGroupsLoader = ({
  teamId,
  fetchTaskGroups,
  children,
  ...restProps
}) => {
  const fetchMethod = (params) => fetchTaskGroups(teamId, params)
  const triggers = [teamId]

  return (
    <Loader fetchMethod={fetchMethod} triggers={triggers} {...restProps}>
      {children}
    </Loader>
  )
}

TaskGroupsLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  teamId: PROP_TYPES.id.isRequired,
  fetchTaskGroups: PropTypes.func.isRequired,
}

export default connect(
  ({ team }) => ({
    teamId: team.id,
  }),
  { fetchTaskGroups: taskGroups.fetchTaskGroups }
)(TaskGroupsLoader)
