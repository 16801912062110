import React from 'react'
import {
  Chip,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import { SubLink } from 'containers'
import { formatCostumDateMonthNoForUI } from 'utils/formatDate'
import { Translate } from 'react-localize-redux'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})
const PlayerTaskRow = ({ dateFormat, event }) => {
  const classes = useRowStyles()

  return (
    <>
      {event?.tasks?.map(
        (task) =>
          task.full_name && (
            <TableRow key={task.id} className={classes.root}>
              <TableCell component="th" scope="row">
                <SubLink
                  to={TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
                    playerId: task.model_id,
                  })}
                  key={task.full_name}
                >
                  {task.full_name}
                </SubLink>
              </TableCell>
              <TableCell>
                {task?.completed_at ? (
                  <Chip
                    label={<Translate id="home.completed" />}
                    color="primary"
                  />
                ) : (
                  <Chip
                    label={<Translate id="player.pending" />}
                    color="default"
                  />
                )}
              </TableCell>
              {task?.questions.map((ques) => (
                <TableCell key={ques.id}>
                  {ques.type === 'RATING' && +ques.answer > 0 ? (
                    <Rating
                      name="simple-controlled"
                      value={+ques.answer}
                      readOnly
                      precision={0.1}
                    />
                  ) : (
                    <Typography variant="p">{ques.answer}</Typography>
                  )}
                </TableCell>
              ))}
              <TableCell>
                {task?.completed_at &&
                  formatCostumDateMonthNoForUI(task?.completed_at, dateFormat)}
              </TableCell>
            </TableRow>
          )
      )}
    </>
  )
}
PlayerTaskRow.propTypes = {
  event: PROP_TYPES.shape().isRequired,
  dateFormat: PROP_TYPES.string.isRequired,
}
export default PlayerTaskRow
