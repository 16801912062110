import React from 'react'
import { PlayersLoader, PlayerLoader } from 'loaders'
import PlayerDashboardContainer from './PlayerDashboardContainer'
import PROP_TYPES from 'constants/propTypes'

const PlayerDashboard = ({
  history,
  match: {
    params: { playerId },
  },
  match,
}) => (
  <PlayersLoader alwaysRenderChildren passIsLoading>
    <PlayerLoader
      playerId={playerId}
      additionalFields={[
        'avatar',
        'sequences',
        'statistics',
        'journal',
        'last_match',
        'logo',
      ]}
    >
      <PlayerDashboardContainer
        playerId={playerId}
        history={history}
        match={match}
      />
    </PlayerLoader>
  </PlayersLoader>
)

PlayerDashboard.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
}

export default PlayerDashboard
