import { Box, Paper } from '@material-ui/core'
import { InnrHead } from 'containers'
import React, { useState, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { videos as videosModule } from 'redux/modules'
import PropTypes from 'prop-types'
import UploadedAndNotCompleted from './components/UploadedAndNotCompleted'
import Completed from './components/Completed'

const VideosUploaded = ({ videos, updateVideo, updateVideoLocal }) => {
  const [videoUploadProgress, setVideoUploadProgress] = useState(0)

  const notCompletedVideos = useMemo(
    () => videos.filter(({ completed }) => !completed),
    [videos]
  )

  const completedVideos = useMemo(
    () => videos.filter(({ completed }) => completed),
    [videos]
  )

  const handleComplete = useCallback((videoId, status) => {
    const completed = status === 'completed' ? 1 : 0

    if (
      Boolean((notCompletedVideos.findById(videoId) || {}).completed) !==
      completed
    ) {
      updateVideo(videoId, { completed })
      updateVideoLocal({ id: videoId, data: { completed } })
    }
  })

  return (
    <Paper>
      <InnrHead title={<Translate id="videos.videos" />} />
      <Box p={2} pt={0}>
        <UploadedAndNotCompleted
          videos={notCompletedVideos}
          videoUploadProgress={videoUploadProgress}
          setVideoUploadProgress={setVideoUploadProgress}
          handleComplete={handleComplete}
        />
        <Box p={1} />
        <Completed videos={completedVideos} />
      </Box>
    </Paper>
  )
}

VideosUploaded.defaultProps = {
  videos: [],
}

VideosUploaded.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object.isRequired),
  updateVideo: PropTypes.func.isRequired,
  updateVideoLocal: PropTypes.func.isRequired,
}

export default connect(
  ({ videos }) => ({
    videos: videos.items,
  }),
  {
    updateVideo: videosModule.updateVideo,
    updateVideoLocal: videosModule.updateVideoLocal,
  }
)(VideosUploaded)
