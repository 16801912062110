import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import {
  IconButton,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { Avatar } from 'containers'
import { setLocale, getLocale } from 'utils/localstorage'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Constants from './Constants'
import LanguageToggle from './components/LanguageToggle'
import { StyledMenu } from './styledComponents'

const TopNavTeamDropdown = ({ club, team }) => {
  const MENU_ITEMS = useMemo(Constants, [])

  setLocale(getLocale())
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {team && (
        <IconButton size="medium" onClick={handleClick}>
          <Avatar
            src={`${team.relationships?.logo?.full_url}`}
            alt={team.name}
          />
        </IconButton>
      )}

      <StyledMenu
        id="Profile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <LanguageToggle />
        {MENU_ITEMS.map(
          ({ LinkComponent, linkTo, icon, text, divider, target }) =>
            !club?.display_billing && linkTo === '/current-team/edit' ? (
              ''
            ) : (
              <MenuList key={linkTo}>
                {divider}
                <MenuItem
                  button
                  onClick={handleClose}
                  component={(props) => <LinkComponent {...props}/>}
                  to={linkTo}
                  target={target}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </MenuItem>
              </MenuList>
            )
        )}
      </StyledMenu>
    </>
  )
}

TopNavTeamDropdown.propTypes = {
  team: PropTypes.shape({
    relationships: PropTypes.shape({
      logo: PropTypes.shape({
        full_url: PropTypes.string,
      }),
    }),
    name: PropTypes.string,
  }).isRequired,
  club: PropTypes.shape().isRequired,
}

export default compose(
  connect(({ clubs, team }) => ({
    club: clubs.current,
    team,
  })),
  withLocalize,
  withRouter
)(TopNavTeamDropdown)
