import React from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { drills } from 'redux/modules'
import { createSubUrl } from 'utils/urlConstructor'
import DrillCreateEditForm from 'containers/ActivityCreateContainer/components/DrillCreateEditForm'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { compose } from 'redux'
import { ACTIVITIES_ROUTE, DRILL_EDIT_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const ActivityCreateContainer = ({
  match,
  location: { fromUrl, fromPathname },
  drillsCategories,
  createDrill,
  history,
  teamId,
}) => {
  const linkBack = fromUrl || createSubUrl(match, ACTIVITIES_ROUTE)

  const emptyObject = {
    age_class: [],
    categories: [],
    description: '',
    duration: '',
    min_players: '',
    max_players: '',
    images: '',
    drill_flow: '',
    organization: '',
    coaching_points: '',
    variations: '',
  }

  const onSubmit = ({
    duration,
    min_players: minPlayers,
    max_players: maxPlayers,
    images,
    ...values
  }) => {
    const data = {
      ...values,
      duration: +duration,
      min_players: +minPlayers,
      max_players: +maxPlayers,
    }

    if (!images) {
      data[`images[0]`] = null
    } else {
      data[`images[0]`] = images
    }

    createDrill({ ...data, team_id: teamId }).then((res) => {
      const { data: temp } = res.response

      history.push(
        createSubUrl(
          match,
          DRILL_EDIT_ROUTE.populateURLParams({
            drillUuid: temp.uuid,
          })
        )
      )
    })
  }

  return (
    <Translate>
      {({ translate }) => (
        <DrillCreateEditForm
          form="drillCreate"
          initialValues={emptyObject}
          caption={translate('drills.create-drill')}
          fromPath={{
            value: linkBack,
            title: fromPathname || translate('drills.own-drills'),
          }}
          onSubmit={onSubmit}
          drillsCategories={drillsCategories}
        />
      )}
    </Translate>
  )
}

ActivityCreateContainer.defaultProps = {
  drillsCategories: [],
}

ActivityCreateContainer.propTypes = {
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
  createDrill: PropTypes.func.isRequired,
  drillsCategories: PROP_TYPES.arrayOfObjects,
  history: PROP_TYPES.history.isRequired,
  teamId: PROP_TYPES.number.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({
      drills: {
        current: { drillsCategories },
      },
      team,
    }) => ({ drillsCategories, teamId: team.id }),
    {
      createDrill: drills.createDrill,
    }
  )
)(ActivityCreateContainer)
