import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { EditCardForm } from 'containers'
import { subscription } from 'redux/modules'
import urlConstructor from 'utils/urlConstructor'
import { getCountry } from 'utils/localstorage'
import { toast } from 'react-toastify'
import { TEAMS_ROUTE } from 'constants/routes'
import PROP_TYPES from 'constants/propTypes'

const UpdateCard = ({
  history,
  match: {
    params: { teamUuid, seasonId },
  },
  updateCardApi,
}) => {
  const fromPath = {
    value: TEAMS_ROUTE,
    title: 'Teams',
  }

  const goBack = () =>
    history.push(urlConstructor(teamUuid, seasonId, TEAMS_ROUTE))

  const [countryValid, changeCountryValid] = useState(false)
  const handalCountyValid = value => {
    changeCountryValid(value)
  }
  const onSubmitCheckout = val => {
    const checkoutValues = val
    const date = checkoutValues.expiry.split('/')
    checkoutValues.country = getCountry()
    if (checkoutValues.country === '') {
      handalCountyValid(true)

      return
    }
    handalCountyValid(false)
    window.Stripe.card.createToken(
      {
        number: checkoutValues.cardnumber,
        exp_month: date[0],
        exp_year: date[1],
        cvc: checkoutValues.cvc,
      },
      (status, response) => {
        if (status === 200) {
          response.user_card_data = JSON.stringify({
            address_city: checkoutValues.city,
            address_country: checkoutValues.country,
            address_line1: checkoutValues.address_1,
            name: `${checkoutValues.first_name} ${checkoutValues.last_name}`,
          })
          updateCardApi(response)
            .then(goBack)
            .catch(e => {
              toast(e.error)
            })
        } else {
          toast(response.error.message)
        }
      }
    )
  }

  React.useEffect(() => {
    return function cleanup() {
      localStorage.removeItem('country')
    }
  }, [])

  // TODO club_id
  return (
    <Translate>
      {({ translate }) => (
        <EditCardForm
          form="createCheckout"
          title={translate('checkout.add_new_card')}
          fromPath={fromPath}
          apiKey={window.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          onSubmit={onSubmitCheckout}
          isCountryValid={countryValid}
          changeCountyValid={handalCountyValid}
        />
      )}
    </Translate>
  )
}

UpdateCard.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  updateCardApi: PropTypes.func.isRequired,
}

export default connect(
  null,
  {
    updateCardApi: subscription.updateCardApi,
  }
)(UpdateCard)
