import React from 'react'
import { VideoLoader } from 'loaders'
import urlConstructor from 'utils/urlConstructor'
import VideoLayout from './VideoLayout'
import CheckNotProcessedVideoSequences from './CheckNotProcessedVideoSequences'
import PROP_TYPES from 'constants/propTypes'

const Video = ({
  match: {
    params: { teamUuid, seasonId, videoId },
  },
  history: { push },
}) => {
  const historySubPush = path => push(urlConstructor(teamUuid, seasonId, path))

  return (
    <VideoLoader
      videoId={videoId}
      additionalFields={['sequences', 'players', 'trainings', 'matches']}
    >
      <CheckNotProcessedVideoSequences videoId={videoId}>
        <VideoLayout historySubPush={historySubPush} />
      </CheckNotProcessedVideoSequences>
    </VideoLoader>
  )
}

Video.propTypes = {
  match: PROP_TYPES.match.isRequired,
  history: PROP_TYPES.history.isRequired,
}

export default Video
