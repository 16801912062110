import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Translate } from 'react-localize-redux'

const useStyles = makeStyles((theme) => ({
  StyledTitle: {
    position: 'relative',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0, 7.5, 0),
    top: -5,
    borderRadius: '0 0 9px 9px',
    zIndex: 1078,

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      zIndex: 1075,
      color: 'transparent',
    },
    '&:before': {
      borderBottomWidth: 41,
      borderRightWidth: 42,
      borderBottomColor: 'transparent',
      borderRightColor: theme.palette.primary.light,
      top: -4,
      left: -41,
    },
    '&:after': {
      borderBottomWidth: 41,
      borderLeftWidth: 42,
      borderTopColor: 'transparent',
      borderLeftColor: theme.palette.primary.light,
      top: -4,
      right: -41,
    },
  },
  [theme.breakpoints.down('sm')]: {
    StyledTitle: {
      zIndex: 1,
      '&:before, &:after': {
        zIndex: 2,
      },
    },
  },
}))

const TrainingTitle = () => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item>
        <Grid container className={classes.StyledTitle} spacing={3}>
          <Grid item>
            <Grid item>
              <strong>
                <Translate id="menu.training" />
              </strong>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TrainingTitle
