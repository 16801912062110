import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { videos as videosModule } from 'redux/modules'

const CheckNotProcessedVideos = ({ teamId, children, videos, fetchVideos }) => {
  const [isChecking, changeIsChecking] = useState(false)

  let isMounted = true

  useEffect(() => {
    if (!isChecking) {
      if (
        videos.some(({ processed }) => processed === 0 || processed === false)
      ) {
        changeIsChecking(true)

        setTimeout(() => {
          fetchVideos({ team_id: teamId }).then(() => {
            if (isMounted) {
              changeIsChecking(false)
            }
          })
        }, 60000)
      } else {
        changeIsChecking(false)
      }
    }

    return () => {
      isMounted = false
    }
  }, [videos, isChecking])

  return children
}

export default connect(
  ({ videos, team }) => ({ videos: videos.items, teamId: team.id }),
  {
    fetchVideos: videosModule.fetchVideos,
  }
)(CheckNotProcessedVideos)
