import { formValueSelector } from 'redux-form'

export default (state) => {
  const selector = formValueSelector('seasons')

  return {
    initialValues: {
      seasons: state.seasons.items || [],
    },
    seasons: selector(state, 'seasons'),
    auth: state.auth,
  }
}
