import React from 'react'
import { withRouter } from 'react-router-dom'
import SubLink from 'containers/SubLink'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import urlConstructor from 'utils/urlConstructor'
import { compose } from 'redux'
import { withLocalize } from 'react-localize-redux'
import useTeamFeature from 'hooks/useTeamFeature'
import PropTypes from 'prop-types'
import { List } from './styledComponents'
import menu from './constants/menu'

const Sidebar = ({
  match: {
    params: { teamUuid, seasonId },
  },
  location: { pathname },
  onClose,
}) => {
  const mainPagePathname = urlConstructor(teamUuid, seasonId)

  return (
    <>
        {menu.map(
          ({
            to,
            title,
            icon,
            teaser,
            featureKey,
            isFullPath,
            rootPath,
            target,
            id,
          }) => {
            /**
             * Determines if the menu item should be displayed based on feature flags.
             *
             * - If `featureKey` is provided, `useTeamFeature(featureKey)` is called to check feature availability.
             * - If `featureKey` is **not** provided, it falls back to `{ enabled: true, tease: false }`, ensuring:
             *   - The item **is always visible** (`enabled: true`).
             *   - No teaser behavior applies (`tease: false`).
             */
            const { enabled, tease } = featureKey
              ? useTeamFeature(featureKey)
              : { enabled: true, tease: false }

            // If featureKey exists and is disabled (with no tease mode), hide the menu item.
            if (featureKey && !enabled && !tease) return null

            return (
              <List component="nav"
                style={{ position: 'relative', overflow: 'hidden' }}
                id={id}
                key={to}
              >
                {tease && teaser}
                <ListItem
                  to={to}
                  button
                  component={SubLink}
                  onClick={onClose}
                  isFullPath={isFullPath}
                  target={target}
                  isActive={!rootPath && pathname === mainPagePathname}
                  className={
                    (!rootPath && pathname === mainPagePathname) ||
                    (rootPath && pathname.includes(mainPagePathname + rootPath))
                      ? 'selected'
                      : ''
                  }
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                    primary={title}
                  />
                </ListItem>
              </List>
            )
          }
        )}
    </>
  )
}

Sidebar.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default compose(withLocalize, withRouter)(Sidebar)
