import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  Paper,
  CardHeader,
  Card,
  Grid,
  MenuItem,
  Link,
  Box,
  CardContent,
  Divider,
  Typography,
  Tooltip,
  ClickAwayListener,
  Hidden,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ShareIcon from '@material-ui/icons/Share'
import {
  formateUTCTimeZoneDate,
  formatUtcDateToDateTime,
  formatUtcDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import { trainings } from 'redux/modules'
import AlertDialog from 'components/AlertDialog'
import DropDownMenu from 'components/DropDownMenu'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { compose } from 'redux'
import EventPopup from 'components/material/Calender/eventPopup'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import UserActionDialog from 'components/material/Calender/UserActionDialog'
import TrainingTitle from './TrainingTitle'
import TrainingInfoDialog from './TrainingInfoDialog'
import PROP_TYPES from 'constants/propTypes'
import { TRAININGS_GAME } from 'constants/calender'

const useStyles = makeStyles((theme) => ({
  noteContent: {
    boxShadow: '10px 5px 35px 0 rgba(0,0,0,0.08)',
  },
  styledCardHeader: {
    textAlign: 'center',
    padding: theme.spacing(2.1),
    position: 'relative',
    '& .MuiCardHeader-action': {
      position: 'absolute',
      right: 0,
      top: 0,
      margin: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        zIndex: 1098,
        top: 14,
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    mobileGrid: {
      padding: `${theme.spacing(0)} !important`,
      fontSize: 11,
      '& u': {
        fontSize: 11,
      },
      '& .MuiGrid-item small': {
        display: 'none',
      },
    },
  },
}))

const TrainingInfo = ({
  dateFormat,
  currentTraining,
  deleteTraining,
  createTraining,
  history,
  seasons,
  match: {
    params: { seasonId },
  },
  timezone,
  language,
  team,
  duplicateTraining,
}) => {
  const classes = useStyles()
  const [trainingCreateDialogOpen, setTrainingCreateDialogOpen] =
    useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [duplicateData, changeDuplicateData] = useState(null)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [trainingInfoEditOpen, setTrainingInfoEditOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(false)
  const [seasonUuid, setSeasonUuid] = useState(null)
  const [isTrainingSeries, setIsTrainingSeries] = React.useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [userDialogOpen, setUserDialogOpen] = useState(false)

  useEffect(() => {
    setIsTrainingSeries(!!currentTraining.series_uuid)
  }, [currentTraining.series_uuid])

  const handleInfoTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleInfoTooltipOpen = () => {
    setOpenTooltip(true)
  }

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseOptions = () => {
    setAnchorEl(null)
  }
  const handleClickTrainingInfoEditOpen = () => {
    setTrainingInfoEditOpen(true)
    setAnchorEl(null)
  }
  const handleClickTrainingInfoEditClose = () => {
    setTrainingInfoEditOpen(false)
  }
  const handleAlertBoxClose = () => {
    setAlertOpen(false)
    setUserDialogOpen(false)
  }

  const handleAlertBoxOpen = () => {
    if (isTrainingSeries) {
      setUserDialogOpen(true)
    } else {
      setAlertOpen(false)
    }

    setAlertOpen(true)
    setAnchorEl(null)
  }

  const onDelete = (params) => {
    deleteTraining(currentTraining.uuid, { apply_on: params }).then(() => {
      history.push(`/team/${team.uuid}/season/${+seasonId}/events`)
      handleAlertBoxClose()
    })
  }
  const handleDuplicateClick = (text = '') => {
    setTrainingAndGame(text)
    setTrainingCreateDialogOpen(true)
    setAnchorEl(null)

    const tempTrainingData = {
      ...currentTraining,
      start_time: formatUtcDateTimeToUserTimezoneDateTime(
        currentTraining.start_datetime,
        timezone
      ),
      date: formatUtcDateTimeToUserTimezoneDateTime(
        currentTraining.start_datetime,
        timezone
      ),
      end_time: currentTraining.end_datetime
        ? formatUtcDateTimeToUserTimezoneDateTime(
            currentTraining.end_datetime,
            timezone
          )
        : null,
    }

    changeDuplicateData(tempTrainingData)
    setIsDuplicate(true)
  }
  const handleClose = () => {
    setTrainingCreateDialogOpen(false)
    changeDuplicateData(null)
    setIsDuplicate(false)
  }

  const createNewTraining = (data) => {
    createTraining(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${team.uuid}/season/${+seasonId}/trainings/${uuid}`)
    })
  }

  const createDuplicateTraining = (trainingUuid, data) => {
    duplicateTraining(trainingUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${team.uuid}/season/${+seasonId}/trainings/${uuid}`)
    })
  }

  const handleShareClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const shareUrl = `${process.env.REACT_APP_API_URL}/trainings/${currentTraining.uuid}/share?language=${language}&time_zone=${timezone}`
    window.open(shareUrl, '_blank')
  }

  useEffect(() => {
    if (seasons.length > 0) {
      const season = seasons.find((s) => s.id === +seasonId)
      setSeasonUuid(season.uuid)
    }
  }, [seasons])

  return (
    <Box m={[2, 4]}>
      {trainingCreateDialogOpen && (
        <EventPopup
          open={trainingCreateDialogOpen}
          onClose={handleClose}
          duplicateData={duplicateData}
          trainingAndGame={trainingAndGame}
          createTraining={createNewTraining}
          createDuplicateTraining={createDuplicateTraining}
          selectedEventType={trainingAndGame}
          isDuplicate={isDuplicate}
          isTrainingSeries={isTrainingSeries}
        />
      )}
      {alertOpen && (
        <AlertDialog
          open={alertOpen}
          onClose={handleAlertBoxClose}
          onSuccess={() => onDelete('SINGLE')}
          onCancel={handleAlertBoxClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {userDialogOpen && (
        <UserActionDialog
          open={userDialogOpen}
          onClose={handleAlertBoxClose}
          onActionOne={() => {
            onDelete('SINGLE')
          }}
          onActionTwo={() => {
            onDelete('SINGLE_AND_FOLLOWING')
          }}
          title={<Translate id="training.delete" />}
          subTitle={<Translate id="training.delete-option" />}
          buttonOneLabel={<Translate id="training.delete-this" />}
          buttonTwoLabel={<Translate id="training.delete-this-following" />}
        />
      )}
      <Paper className={classes.noteContent}>
        {trainingInfoEditOpen && (
          <TrainingInfoDialog
            open={trainingInfoEditOpen}
            handleClose={handleClickTrainingInfoEditClose}
            isTrainingSeries={isTrainingSeries}
          />
        )}
        <Card>
          <CardHeader
            className={classes.styledCardHeader}
            title={<TrainingTitle />}
            action={
              <>
                <IconButton
                  onClick={handleOptionsClick}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                >
                  <MoreVertIcon />
                </IconButton>
                <DropDownMenu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  elevation={2}
                  keepMounted
                  edge="start"
                  open={Boolean(anchorEl)}
                  onClose={handleCloseOptions}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={handleClickTrainingInfoEditOpen}>
                    <EditIcon from fontSize="small" />
                    <Translate id="button.edit" />
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleDuplicateClick(TRAININGS_GAME.training)
                    }
                  >
                    <FileCopyIcon fontSize="small" />
                    <Translate id="button.duplicate" />
                  </MenuItem>
                  <MenuItem onClick={handleShareClick}>
                    <ShareIcon fontSize="small" />
                    <Translate id="button.share" />
                  </MenuItem>
                  <MenuItem onClick={handleAlertBoxOpen}>
                    <DeleteIcon fontSize="small" />
                    <Translate id="button.delete" />
                  </MenuItem>
                </DropDownMenu>
              </>
            }
          />
          <CardContent>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} md={7}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    {currentTraining.focus && (
                      <Box textAlign="center">
                        <Typography variant="h3">
                          <Translate id="training.focus" />:{' '}
                          {currentTraining.focus}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                item
                xs={12}
                md={10}
                spacing={0}
                className={classes.mobileGrid}
              >
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <DateRangeOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {formateUTCTimeZoneDate(
                        currentTraining.start_datetime,
                        timezone,
                        dateFormat
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <MapOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      <Link
                        href={`https://maps.google.com/?q=${currentTraining.lat},${currentTraining.lng}`}
                        target="_blank"
                      >
                        {currentTraining.city || currentTraining.country || '-'}
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <TimerOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {currentTraining.start_datetime
                        ? formatUtcDateToDateTime(
                            currentTraining.start_datetime,
                            timezone
                          )[1]
                        : '-'}{' '}
                      -{' '}
                      {currentTraining.end_datetime
                        ? formatUtcDateToDateTime(
                            currentTraining.end_datetime,
                            timezone
                          )[1]
                        : '-'}
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <HourglassEmptyIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {currentTraining.availability_cutoff_time} hrs
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <DescriptionOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Box textAlign="center">
                        <Hidden smDown>
                          <Tooltip title={currentTraining.details || '-'} arrow>
                            <Typography>
                              <u style={{ textDecorationStyle: 'dotted' }}>
                                Info
                              </u>
                            </Typography>
                          </Tooltip>
                        </Hidden>
                        <Hidden mdUp>
                          <ClickAwayListener
                            onClickAway={handleInfoTooltipClose}
                          >
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleInfoTooltipClose}
                              open={openTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={currentTraining.details || '-'}
                              arrow
                            >
                              <Typography onClick={handleInfoTooltipOpen}>
                                <u style={{ textDecorationStyle: 'dotted' }}>
                                  Info
                                </u>
                              </Typography>
                            </Tooltip>
                          </ClickAwayListener>
                        </Hidden>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}

TrainingInfo.propTypes = {
  dateFormat: PROP_TYPES.string.isRequired,
  currentTraining: PROP_TYPES.shape().isRequired,
  history: PROP_TYPES.shape().isRequired,
  createTraining: PROP_TYPES.func.isRequired,
  duplicateTraining: PROP_TYPES.func.isRequired,
  deleteTraining: PROP_TYPES.func.isRequired,
  team: PROP_TYPES.shape().isRequired,
  match: PROP_TYPES.match.isRequired,
  seasons: PROP_TYPES.arrayOfObjects.isRequired,
  timezone: PROP_TYPES.string.isRequired,
  language: PROP_TYPES.string.isRequired,
}

export default compose(
  connect(
    ({ team, trainings: { current }, auth, seasons: { items } }) => ({
      team,
      currentTraining: current,
      dateFormat: auth.user.datetime_format,
      timezone: auth.user.time_zone,
      language: auth.user.language,
      seasons: items,
    }),
    {
      createTraining: trainings.createTraining,
      deleteTraining: trainings.deleteTraining,
      duplicateTraining: trainings.duplicateTraining,
    }
  ),
  withRouter
)(TrainingInfo)
