import { Box, IconButton, Paper, Tab, Tabs, Toolbar } from '@material-ui/core'
import React from 'react'
import { Translate } from 'react-localize-redux'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { compose } from 'redux'
import urlConstructor from 'utils/urlConstructor'
import PropTypes from 'prop-types'
import { TEAM_PLAYER_DASHBOARD_ROUTE } from 'constants/routes'

const TopBar = ({
  history,
  match: {
    params: { teamUuid, seasonId, playerId },
  },
}) => {
  const handleBack = () => {
    history.push(
      urlConstructor(
        teamUuid,
        seasonId,
        TEAM_PLAYER_DASHBOARD_ROUTE.populateURLParams({
          playerId,
        })
      )
    )
  }

  return (
    <Paper component={Box} mb={2}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleBack}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Tabs value={0} indicatorColor="primary">
          <Tab label={<Translate id="player.transfer-history.title" />} />
        </Tabs>
      </Toolbar>
    </Paper>
  )
}

TopBar.propTypes = {
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      teamUuid: PropTypes.string.isRequired,
      seasonId: PropTypes.number.isRequired,
      playerId: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default compose(withRouter)(TopBar)
