import React from 'react'
import { withRouter } from 'react-router-dom'
import { DrillsLoader, FavoritesLoader } from 'loaders'
import DrillsWidgetContainer from './DrillsWidgetContainer'
import PROP_TYPES from 'constants/propTypes'

const DrillsWidget = ({
  ageClassUuid,
  clubId,
  match: {
    params: { teamUuid, seasonId },
  },
}) => {
  return (
    <DrillsLoader
      params={{
        limit: 20,
        order_by: 'random',
        team_id: teamUuid,
        season_id: seasonId,
        age_class_uuid: ageClassUuid,
      }}
      alwaysRenderChildren
      passIsLoading
    >
      <FavoritesLoader entity="drills">
        <DrillsWidgetContainer clubId={clubId} />
      </FavoritesLoader>
    </DrillsLoader>
  )
}
DrillsWidget.propTypes = {
  ageClassUuid: PROP_TYPES.string.isRequired,
  clubId: PROP_TYPES.number.isRequired,
  match: PROP_TYPES.shape().isRequired,
}

export default withRouter(DrillsWidget)
