import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import useStyles from './styles'
import GeneralTrainingInfoContainer from './components/GeneralTrainingInfo'
import TabViewContainer from './components/TabViewContainer'

const TrainingDashboardLayout = () => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} className={classes.MobileContainer}>
        <Paper elevation={3}>
          <Grid container>
            <Grid item xs={12}>
              <GeneralTrainingInfoContainer />
            </Grid>
            <Grid item xs={12}>
              <TabViewContainer />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

export default TrainingDashboardLayout
