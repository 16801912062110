/* eslint-disable no-plusplus */
import React, { useState } from 'react'
import { saveAs } from 'file-saver'
import { Translate, withLocalize } from 'react-localize-redux'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'

import {
  Button as MuiButton,
  IconButton,
  Popper,
  Fade,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  Grid,
  Divider,
  Box,
} from '@material-ui/core'
import { ClickAwayListener } from '@mui/material'
import ShareIcon from '@material-ui/icons/Share'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import TelegramIcon from '@material-ui/icons/Telegram'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import LinkIcon from '@material-ui/icons/Link'
import PROP_TYPES from 'constants/propTypes'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'

const Button = withStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0.2, 0.5),
  },
}))(MuiButton)

const ShareMuiIcon = ({
  className,
  share,
  translate,
  id,
  checkBoxList,
  isPlayer,
  placement = '',
}) => {
  let downloadLink = ''

  const [anchorEl, setAnchorEl] = React.useState(null)
  const totalCheckBoxList = checkBoxList.map((option, index) =>
    option ? index : null
  )
  const [checked, setChecked] = useState(totalCheckBoxList)

  const handleToggle = value => () => {
    if (value === 0) {
      return
    }
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const shareWrapped = e => {
    e.preventDefault()
    e.stopPropagation()
    const shareStr = `&shared_by=pdf`
    if (downloadLink !== '') {
      saveAs(downloadLink)
    } else if (isPlayer) {
      share(id, shareStr, { filter_information: checked }).then(
        ({ response: { data: downloadUrl } }) => {
          downloadLink = downloadUrl
          saveAs(downloadUrl)
        }
      )
    } else {
      share(id, shareStr).then(({ response: { data: downloadUrl } }) => {
        downloadLink = downloadUrl
        saveAs(downloadUrl)
      })
    }
  }

  const shareWatsap = e => {
    e.preventDefault()
    e.stopPropagation()
    const shareStr = `&shared_by=whatsapp`
    if (downloadLink !== '') {
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        downloadLink
      )}`
    } else if (isPlayer) {
      share(id, shareStr, { filter_information: checked }).then(
        ({ response: { data: downloadUrl } }) => {
          downloadLink = downloadUrl
          window.location.href = `whatsapp://send?text=${encodeURIComponent(
            downloadLink
          )}`
        }
      )
    } else {
      share(id, shareStr).then(({ response: { data: downloadUrl } }) => {
        downloadLink = downloadUrl
        window.location.href = `whatsapp://send?text=${encodeURIComponent(
          downloadLink
        )}`
      })
    }
  }

  const shareMail = e => {
    e.preventDefault()
    e.stopPropagation()
    const shareStr = `&shared_by=email`
    if (downloadLink !== '') {
      window.location.href = `mailto:?body=${encodeURIComponent(
        downloadLink
      )}&subject=${encodeURIComponent(translate('training.subject-mail'))}`
    } else if (isPlayer) {
      share(id, shareStr, { filter_information: checked }).then(
        ({ response: { data: downloadUrl } }) => {
          downloadLink = downloadUrl
          window.location.href = `mailto:?body=${encodeURIComponent(
            downloadLink
          )}&subject=${encodeURIComponent(translate('training.subject-mail'))}`
        }
      )
    } else {
      share(id, shareStr).then(({ response: { data: downloadUrl } }) => {
        downloadLink = downloadUrl
        window.location.href = `mailto:?body=${encodeURIComponent(
          downloadLink
        )}&subject=${encodeURIComponent(translate('training.subject-mail'))}`
      })
    }
  }
  const shareCopyLink = e => {
    e.preventDefault()
    e.stopPropagation()
    const shareStr = `&shared_by=link`
    if (downloadLink !== '') {
      navigator.clipboard.writeText(downloadLink)
    } else if (isPlayer) {
      share(id, shareStr, { filter_information: checked }).then(
        ({ response: { data: downloadUrl } }) => {
          downloadLink = downloadUrl
          navigator.clipboard.writeText(downloadLink)
        }
      )
    } else {
      share(id, shareStr).then(({ response: { data: downloadUrl } }) => {
        downloadLink = downloadUrl
        navigator.clipboard.writeText(downloadLink)
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const ids = open ? 'transitions-popper' : undefined

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={placement}
        style={{ zIndex: '9999' }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={(e) => open && handleClick(e)}>
            <Paper elevation={3} variant="outlined">
              {checkBoxList.length > 0 && (
                <>
                  <List>
                    {checkBoxList.map((key, index) => {
                      const labelId = `checkbox-list-label-${index}`

                      return (
                        <>
                          <ListItem className={(index===0 ? "d-none" : "")}>
                            <ListItemSecondaryAction>
                              <Checkbox
                                id={labelId}
                                edge="start"
                                checked={checked.indexOf(index) !== -1}
                                tabIndex={-1}
                                onChange={handleToggle(index)}
                              />
                            </ListItemSecondaryAction>
                            <ListItemText id={labelId} primary={key} />
                          </ListItem>
                        </>
                      )
                    })}
                  </List>
                  <Divider />
                </>
              )}
              <Grid component={Box} display="flex" p={1}>
                <Button onClick={shareWrapped} size="small">
                  {' '}
                  <InsertDriveFileIcon />
                  <Translate id="button.pdf" />
                </Button>
                <Button onClick={shareMail} size="small">
                  {' '}
                  <TelegramIcon />
                  <Translate id="button.mail" />
                </Button>
                <Button onClick={shareCopyLink} title="Copy" size="small">
                  {' '}
                  <LinkIcon />
                  <Translate id="button.copy" />
                </Button>
                <Button onClick={shareWatsap} size="small">
                  {' '}
                  <WhatsAppIcon />
                  <Translate id="button.whatapp" />
                </Button>
              </Grid>
            </Paper>
          </ClickAwayListener>
          </Fade>
        )}
      </Popper>
      <IconButton
        aria-describedby={ids}
        className={className}
        onClick={(e) => !open && handleClick(e)}
      >
        <ShareIcon fontSize="small" />
      </IconButton>
    </>
  )
}

ShareMuiIcon.defaultProps = {
  className: '',
  placement:'',
  checkBoxList: [],
  isPlayer: false,
}

ShareMuiIcon.propTypes = {
  className: PROP_TYPES.string,
  isPlayer: PROP_TYPES.bool,
  placement: PROP_TYPES.string,
  id: PROP_TYPES.string.isRequired,
  share: PROP_TYPES.func.isRequired,
  translate: PROP_TYPES.shape().isRequired,
  checkBoxList: PROP_TYPES.shape(),
}

export default compose(withRouter, withLocalize)(ShareMuiIcon)
