import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Box, Button, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { SubLink, HasClubPermission } from 'containers'
import {
  TRAINING_PACKAGES_ROUTE,
  DRILLS_CATEGORIES,
  DRILL_CREATE_ROUTE,
} from 'constants/routes'
import PERMISSIONS from 'constants/permissions'

const useStyles = makeStyles((theme) => ({
  navigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  navList: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
  },
  navItem: {
    paddingRight: '35px',
  },
  link: {
    color: 'gray',
  },
  button: {
    margin: theme.spacing(0, 0.8),
  },
}))

const Navigation = () => {
  const { teamUuid, seasonId } = useParams()
  const baseRoute = `/team/${teamUuid}/season/${seasonId}`
  const classes = useStyles()

  return (
    <nav className={classes.navigation}>
      <ul className={classes.navList}>
        <li className={classes.navItem}>
          <Typography variant="h3" component="h3">
            <Translate id="drills.activities" />
          </Typography>
        </li>
        <li>
          <NavLink
            to={`${baseRoute}${TRAINING_PACKAGES_ROUTE}`}
            className={classes.link}
          >
            <Typography variant="h3" component="h3">
              <Translate id="navbar.training_packages" />
            </Typography>
          </NavLink>
        </li>
      </ul>
      <Box>
        <HasClubPermission ability={PERMISSIONS.CREATE_DRILL_CATEGORY}>
          <Button
            variant="contained"
            color="primary"
            component={SubLink}
            className={classes.button}
            to={DRILLS_CATEGORIES}
          >
            <Translate id="drills.manage-categories" />
          </Button>
        </HasClubPermission>
        <Button
          variant="contained"
          color="primary"
          component={SubLink}
          className={classes.button}
          to={DRILL_CREATE_ROUTE}
        >
          <Translate id="drills.create-drill" />
        </Button>
      </Box>
    </nav>
  )
}

export default Navigation
