import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import moment from 'moment'
import { MenuItem, Link } from '@material-ui/core'
import { SelectedMenu } from 'components'
import urlConstructor from 'utils/urlConstructor'
import { SeasonsLoader } from 'loaders'
import { SEASONS_ROUTE } from 'constants/routes'

const SeasonSelector = (props) => {
  const {
    match: {
      isExact,
      params: { teamUuid, seasonId },
    },
    seasons: { items: seasons },
  } = props

  const [normalizedSeasons, setNormalizedSeasons] = useState([])

  const onSelect = (newSeasonId) => {
    const newPathname = urlConstructor(teamUuid, newSeasonId)

    window.location = newPathname
  }

  const [currentSeason, changeCurrentSeason] = React.useState(+seasonId)

  useEffect(() => {
    changeCurrentSeason(+seasonId)
  }, [seasonId])

  useEffect(() => {
    setNormalizedSeasons(
      seasons.map((item) => {
        const { start_date: startDay, end_date: endDay, id: value } = item

        const title = `${moment(startDay).format('MM.YYYY')} - ${moment(
          endDay
        ).format('MM.YYYY')}`

        return { value, title, disabled: !isExact }
      })
    )
  }, [seasons])

  return (
    <SeasonsLoader alwaysRenderChildren>
      {normalizedSeasons && normalizedSeasons.length !== 0 && (
        <SelectedMenu
          id="current-season"
          value={currentSeason}
          style={{ width: 180 }}
          onChange={(event) => {
            changeCurrentSeason(+event.target.value)
            onSelect(event.target.value)
          }}
          label={<Translate id="menu.current-season" />}
        >
          {normalizedSeasons.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.title}
            </MenuItem>
          ))}
          <Link href={`/team/${teamUuid}/season/${seasonId}${SEASONS_ROUTE}`}>
            <MenuItem>
              <Translate id="season.manage" />
            </MenuItem>
          </Link>
        </SelectedMenu>
      )}
    </SeasonsLoader>
  )
}

SeasonSelector.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  seasons: PropTypes.shape().isRequired,
}

export default compose(
  withRouter,
  connect(({ seasons }) => ({ seasons }))
)(SeasonSelector)
