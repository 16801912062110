import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { team, themes } from 'redux/modules'
import Loader from './Loader'
import PROP_TYPES from 'constants/propTypes'
import { WRONG_PERMISSION_ROUTE } from 'constants/routes'

const TeamLoader = ({
  additionalParams,
  children,
  teamUuid,
  fetchTeam,
  getClubTheme,
  ...restProps
}) => {
  return (
    <Loader
      fetchMethod={(params) => {
        return fetchTeam(teamUuid, {
          ...params,
          ...additionalParams,
          include: ['ageClass', 'logo'],
        }).then((res) => {
          const teamData = res.response.data

          // If team data is not available, redirect to the wrong permission route to prevent unauthorized access
          if (!teamData) {
            window.location.href = WRONG_PERMISSION_ROUTE
          }

          const canViewTeamDashboard = teamData.can.some(
            (permission) => permission === 'access-team-via-web-app'
          )

          // This security check helps to restrict players to open team dashboard using direct link
          if (!canViewTeamDashboard) {
            window.location.href = WRONG_PERMISSION_ROUTE
          }

          // Load current club theme
          getClubTheme(teamData.club_uuid)
        })
      }}
      triggers={[teamUuid]}
      {...restProps}
    >
      {children}
    </Loader>
  )
}

TeamLoader.defaultProps = {
  additionalParams: {},
}

TeamLoader.propTypes = {
  children: PROP_TYPES.children.isRequired,
  teamUuid: PropTypes.string.isRequired,
  fetchTeam: PropTypes.func.isRequired,
  additionalParams: PropTypes.shape(),
  getClubTheme: PropTypes.func.isRequired,
}

export default connect(null, {
  fetchTeam: team.fetchTeam,
  getClubTheme: themes.getClubTheme,
})(TeamLoader)
