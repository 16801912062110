import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar as MuiAvatar } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  Avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}))

const Avatar = ({ children, src, alt }) => {
  const classes = useStyles()

  return (
    <MuiAvatar className={classes.Avatar} src={src} alt={alt}>
      {children}
    </MuiAvatar>
  )
}
Avatar.defaultProps = {
  children: <div />,
  src: '',
}

Avatar.propTypes = {
  children: PropTypes.node,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
}

export default Avatar
