import React from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { Translate } from 'react-localize-redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import {
  HasClubPermission,
  ImagesUploader,
  LanguageSelectorForAdmins,
} from 'containers'
import {
  Card,
  Input,
  InputNumber,
  LinkBack,
  FroalaTextEditor,
  Checkbox,
  AgeClassSelector,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import createJoiValidator from 'utils/createJoiValidator'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import DrillsSelector from './components/DrillsSelector'
import PERMISSIONS from 'constants/permissions'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    float: 'right',
  },
}))

const validationSchema = Joi.object().keys({
  title: Joi.string().max(255).required(),
  description: Joi.any(),
  age_class: Joi.array().has(Joi.number()),
  number_of_players: Joi.number().min(1).required(),
  images: Joi.array().has(Joi.any()),
  global: Joi.any().allow(0, 1),
  warmup_drills: Joi.any(),
  main_part_drills: Joi.any(),
  final_part_drills: Joi.any(),
})

const TrainingPackageCreateEditForm = ({
  caption,
  fromPath,
  availableDrills,
  isEditForm,
  showDeleteButton,
  handleDelete,
  handleSubmit,
  classes = useStyles(),
  ageClasses,
}) => {
  return (
    <Card>
      <Card.Title>
        <Translate id={`training.${caption.toLowerCase()}`} />
        {isEditForm && <LanguageSelectorForAdmins />}
      </Card.Title>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Field name="title" placeholder="Name" component={Input} />
            <Field
              name="description"
              placeholder="Description"
              component={FroalaTextEditor}
            />
            <DrillsSelector availableDrills={availableDrills} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FieldArray name="images" component={ImagesUploader} />
            <HasClubPermission
              ability={PERMISSIONS.CREATE_GLOBAL_TRAINING_PACKAGE}
            >
              <Field name="global" label="Global" component={Checkbox} />
            </HasClubPermission>
            <h5>
              <Translate id="form.age" />
            </h5>
            <Field
              name="age_class"
              component={AgeClassSelector}
              items={ageClasses}
            />
            <Translate>
              {({ translate }) => (
                <Field
                  name="number_of_players"
                  label={translate('drills.number-of-players')}
                  component={InputNumber}
                />
              )}
            </Translate>
          </Grid>
        </Grid>
        <div className={classes.root}>
          {showDeleteButton && (
            <Translate>
              {({ translate }) => (
                <Button
                  color="secondary"
                  onClick={handleDelete}
                  message={translate('message.delete')}
                  variant="link"
                >
                  {translate('button.delete')}
                </Button>
              )}
            </Translate>
          )}
          <LinkBack
            to={fromPath.value}
            title={<Translate id="button.cancel" />}
            isFullPath
          />
          <Button color="primary" onClick={handleSubmit} variant="contained">
            <Translate id="button.save" />
          </Button>
        </div>
      </form>
    </Card>
  )
}

TrainingPackageCreateEditForm.defaultProps = {
  availableDrills: [],
  isEditForm: false,
  showDeleteButton: false,
  handleDelete: () => 0,
  ageClasses: [],
}

TrainingPackageCreateEditForm.propTypes = {
  caption: PropTypes.string.isRequired,
  fromPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  availableDrills: PropTypes.arrayOf(PropTypes.object.isRequired),
  ageClasses: PropTypes.arrayOf(PropTypes.object.isRequired),
  isEditForm: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  classes: PropTypes.func.isRequired,
}

export default compose(
  connect(
    ({ ageClasses }) => ({
      ageClasses: ageClasses.items,
    }),
    {}
  ),
  reduxForm({
    enableReinitialize: true,
    validate: createJoiValidator(validationSchema),
  })
)(TrainingPackageCreateEditForm)
