import React, { useState } from 'react'
import { Tabs, Tab, Box } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import ActivitiesContainer from 'containers/ActivitiesContainer'
import TrainingPackagesContainer from 'containers/TrainingPackagesContainer'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import useStyles from './styles'

const DrillsAndTrainingPackages = ({
  onPhaseSelect,
  onTrainingPackageSelect,
}) => {
  const [activeTab, setActiveTab] = useState('activities')

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }
  const classes = useStyles()

  return (
    <Box>
      <TabContext value={activeTab}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="tabs"
          className={classes.tabsRoot}
        >
          <Tab
            label={<Translate id="drills.activities" />}
            value="activities"
            className={classes.tab}
          />
          <Tab
            label={<Translate id="navbar.training_packages" />}
            value="training-packages"
            className={classes.tab}
          />
        </Tabs>
        <TabPanel value="activities" className={classes.tabPanel}>
          <ActivitiesContainer withPhaseMenu onPhaseSelect={onPhaseSelect} />
        </TabPanel>
        <TabPanel value="training-packages" className={classes.tabPanel}>
          <TrainingPackagesContainer
            showAddToPhaseButton
            onTrainingPackageSelect={onTrainingPackageSelect}
          />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

DrillsAndTrainingPackages.propTypes = {
  onPhaseSelect: PropTypes.func.isRequired,
  onTrainingPackageSelect: PropTypes.func.isRequired,
}

export default DrillsAndTrainingPackages
