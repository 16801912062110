import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
  updateItems,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

// @dependencycycle This injection creates a dependency cycle between redux store and local storage
import { setClub } from '../../utils/localstorage'

const FETCH_CLUB = actionsTypesGenerator('FETCH_CLUB')
const FETCH_CLUBS = actionsTypesGenerator('FETCH_CLUBS')
const SET_SELECTED_CLUB = 'SET_SELECTED_CLUB'

export const fetchClub = (id) => ({
  types: FETCH_CLUB,
  callAPI: {
    method: 'GET',
    path: `/clubs/${id}`,
    data: {
      preventToastNotification: true,
    },
  },
})

export const fetchClubs = (params) => ({
  types: FETCH_CLUBS,
  callAPI: {
    method: 'GET',
    path: `/v2/clubs`,
    data: {
      preventToastNotification: true,
    },
    params,
  },
})

// Update the currently selected club in Redux
export const setSelectedClub = (club) => ({
  type: SET_SELECTED_CLUB,
  payload: club,
})

export default handleActions(
  {
    [combineActions(FETCH_CLUB.request, FETCH_CLUBS.request)]: onRequest,
    [FETCH_CLUB.success]: (state, { response: { data } }) => {
      // for backward compatibility
      setClub(data)

      return onSuccess({
        ...state,
        items: updateItems(state.items, data),
        current: data,
      })
    },
    [FETCH_CLUBS.success]: (state, { response: { data } }) => {
      return onSuccess({
        ...state,
        items: data,
      })
    },
    [combineActions(FETCH_CLUB.failure, FETCH_CLUBS.failure)]: onFailure,

    [SET_SELECTED_CLUB]: (state, { payload }) => ({
      ...state,
      current: payload, // Update the currently selected club in Redux
    }),
  },
  initialState.clubs
)
