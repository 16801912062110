import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import { Translate } from 'react-localize-redux'
import { taskGroups as tasksModule } from 'redux/modules'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AlertDialog from 'components/AlertDialog'
import SubLink from 'containers/SubLink'
import { PlayersLoader } from 'loaders'
import PropTypes from 'prop-types'
import AddTaskGroupDialog from './AddTaskGroupDialog'
import { TRAININGS_GAME } from 'constants/calender'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
    height: 204,
    padding: theme.spacing(0),
    '& .MuiListItemSecondaryAction-root': {
      right: 12,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 15,
    },
    '& .MuiAvatarGroup-avatar': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: 10,
    },
  },
  colorBadge: {
    width: 5,
    height: 5,
    borderRadius: 50,
    backgroundColor: theme.palette.primary.main,
  },
  listItemText: {
    maxWidth: '50%',
  },
  listItemTextEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const TaskGroupsWidget = ({
  deleteTaskGroup,
  players,
  teamTaskGroups,
  isTrainingEvent,
  isMatchEvent,
  eventId,
  fetchTaskGroups,
  teamId,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [taskGroup, setTaskGroup] = useState(null)
  const [openAlert, setOpenAlert] = useState(false)
  const [deleteTaskId, setDeleteTaskId] = useState(false)
  const [taskGroupsState, setTaskGroupsState] = useState()

  const handleOpen = (selectedTaskGroup) => {
    setTaskGroup(selectedTaskGroup)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setOpenAlert(false)
  }
  const handleAlertOpen = () => {
    setOpenAlert(true)
  }
  const openAlertBox = (taskId) => {
    if (taskId) {
      setDeleteTaskId(taskId)
      handleAlertOpen()
    }
  }
  const handleDelete = () => {
    deleteTaskGroup(deleteTaskId)
    setOpenAlert(false)
  }

  useEffect(() => {
    if (isTrainingEvent) {
      fetchTaskGroups(teamId, {
        filter_by: TRAININGS_GAME.training,
        event_uuid: eventId,
      })
    } else {
      fetchTaskGroups(teamId)
    }
  }, [fetchTaskGroups, teamId, eventId])

  useEffect(() => {
    const updatedTaskGroups = teamTaskGroups.map((trainingGroup) => ({
      ...trainingGroup,
      assignees: trainingGroup.model_ids.reduce((acc, modelId) => {
        const player = players.find((p) => p.id === +modelId)
        if (player) acc.push(player)

        return acc
      }, []),
    }))

    setTaskGroupsState(updatedTaskGroups)
  }, [teamTaskGroups, players])

  return (
    <>
      {/* We need to load players so that we can attach them in task assignees
      and in add task group dialog to select task for players */}
      <PlayersLoader>
        <Card>
          {open && (
            <AddTaskGroupDialog
              open={open}
              onClose={handleClose}
              selectedTaskGroup={taskGroup}
              isTrainingEvent={isTrainingEvent}
              isMatchEvent={isMatchEvent}
              eventId={eventId}
            />
          )}
          <CardHeader
            title={
              <Typography variant="h4" component="h4">
                <Translate id="task-management.title" />
              </Typography>
            }
            action={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleOpen(null)}
                >
                  <Translate id="task-management.add-task" />
                </Button>
              </>
            }
          />
          <CardContent className={classes.root}>
            <List>
              {taskGroupsState?.map((element) => (
                <ListItem key={element.id} dense={Boolean(true)}>
                  <ListItemIcon>
                    <Box className={classes.colorBadge} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={
                      <Tooltip title={element.title} placement="top" arrow>
                        <Box className={classes.listItemTextEllipsis}>
                          <SubLink to={`/task-group/${element.id}`}>
                            {element.title}
                          </SubLink>
                        </Box>
                      </Tooltip>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Box>
                        <Tooltip
                          title={<Translate id="button.assignee" />}
                          placement="top"
                          arrow
                        >
                          <AvatarGroup>
                            {element?.assignees?.slice(0, 2).map((player) => (
                              <Avatar
                                key={player?.id}
                                alt={player?.first_name}
                                src={player?.avatar?.full_url}
                              />
                            ))}
                          </AvatarGroup>
                        </Tooltip>
                      </Box>
                      <Box>
                        {element?.assignees.length > 2
                          ? `+${element?.assignees.length - 2}`
                          : ''}
                      </Box>
                      <Box>
                        <Tooltip
                          title={<Translate id="button.edit" />}
                          placement="top"
                          arrow
                        >
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleOpen(element)}
                            disabled={Boolean(element.completed)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={<Translate id="button.delete" />}
                          placement="top"
                          arrow
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => openAlertBox(element.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </CardContent>
          {openAlert && (
            <AlertDialog
              open={openAlert}
              onClose={handleClose}
              onSuccess={handleDelete}
              onCancel={handleClose}
              title={<Translate id="button.delete" />}
              subTitle={
                <Translate id="events.are-you-sure-you-want-to-delete" />
              }
              submitButtonLabel={<Translate id="button.ok" />}
              cancelButtonLabel={<Translate id="button.close" />}
            />
          )}
        </Card>
      </PlayersLoader>
    </>
  )
}

TaskGroupsWidget.defaultProps = {
  isTrainingEvent: false,
  isMatchEvent: false,
  eventId: 0,
}

TaskGroupsWidget.propTypes = {
  teamTaskGroups: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  isMatchEvent: PropTypes.bool,
  isTrainingEvent: PropTypes.bool,
  eventId: PropTypes.number,
  deleteTaskGroup: PropTypes.func.isRequired,
  players: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  fetchTaskGroups: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
}
export default compose(
  withRouter,
  connect(
    ({ taskGroups, players, team }) => ({
      teamTaskGroups: taskGroups.items,
      players: players.items,
      teamId: team.id,
    }),
    {
      fetchTaskGroups: tasksModule.fetchTaskGroups,
      deleteTaskGroup: tasksModule.deleteTaskGroup,
    }
  )
)(TaskGroupsWidget)
