import { getRequest } from 'utils/axios'

export const fetchClubs = (queryParams = {}) => {
  const url = `/v2/clubs`

  return getRequest(url, queryParams)
}

export const fetchClubTeams = (clubUuid, queryParams = {}) => {
  const url = `/v2/clubs/${clubUuid}/teams`

  return getRequest(url, queryParams)
}
