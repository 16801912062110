import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import requiredIf from 'react-required-if'
import { withRouter } from 'react-router-dom'
import urlConstructor from 'utils/urlConstructor'
import { StyledLink } from '../styledComponents'

const NavLink = forwardRef(
  (
    {
      children,
      className,
      to,
      fromPathname,
      isFullPath,
      match,
      target,
      onClick,
      ...restProps
    },
    ref
  ) => {
    const {
      params: { teamUuid, seasonId },
      url: fromUrl,
    } = match

    const pathname = isFullPath ? to : urlConstructor(teamUuid, seasonId, to)

    return (
      <StyledLink
        className={className}
        to={{
          pathname,
          fromUrl,
          fromPathname,
          ...restProps,
        }}
        onClick={onClick}
        ref={ref}
        target={target}
      >
        {children}
      </StyledLink>
    )
  }
)

NavLink.defaultProps = {
  children: <div />,
  className: null,
  to: '',
  target: '',
  fromPathname: undefined,
  isFullPath: false,
  isExternal: false,
  onClick: () => {},
}

NavLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  fromPathname: PropTypes.string,
  target: PropTypes.string,
  isFullPath: PropTypes.bool,
  isExternal: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  onClick: requiredIf(PropTypes.func, (props) => !props.to),
}

export default withRouter(NavLink)
