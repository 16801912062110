import React from 'react'
import { Translate } from 'react-localize-redux'
import { styled, makeStyles, withTheme } from '@material-ui/core/styles'
import { Line as LineChart } from 'react-chartjs-2'
import { rgba } from 'polished'
import {
  Paper,
  Divider,
  Toolbar,
  Tab,
  Tabs,
  Grid,
  Box,
  ButtonGroup as MuiButtonGroup,
  Button,
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import BallIcon from 'assets/images/ballicon.svg'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: 56,
    '& .MuiTab-root': {
      minHeight: 56,
      minWidth: 200,
    },
  },
  button: {
    margin: theme.spacing(0, 1.6),
  },
  title: {
    flex: '1 1 0',
    [theme.breakpoints.down('md')]: { width: '30%' },
  },
}))

const StyledChart = styled(Box)(({ theme }) => ({
  height: 258,
  '& canvas': {
    height: '100% !important',
    width: '100% !important',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const NoData = styled(Box)(({ theme }) => ({
  minHeight: 258,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  div: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#d0d0d0',
    p: {
      margin: theme.spacing(0),
      marginTop: theme.spacing(1.2),
    },
  },
}))

const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  margin: theme.spacing(0.9, 2),
  '& .MuiSvgIcon-root': {
    fontSize: theme.typography.fontSize,
  },
  [theme.breakpoints.down('sm')]: {},
}))
const PerformanceWidget = ({ title, data, theme }) => {
  const firstDatasetColor = rgba(theme.Dataset.color_1, 0.3)
  const firstDatasetBorderColor = rgba(theme.Dataset.color_1, 1)

  const chartData = {
    labels: [],
   
    datasets: [
      {
        label: 'Rating',
        backgroundColor: firstDatasetColor,
        borderColor: firstDatasetBorderColor,
        pointBorderColor: firstDatasetBorderColor,
        pointBackgrounColor: firstDatasetColor,
        pointRadius: 3,
        borderWidth: 3,
        data: [],
      }
    ],
  }

  const options = {
    // spanGaps: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
    },
    plugins:{
      legend: {
       display: false
      }
     },
    hover: {
      mode: 'dataset',
    },
    scaleStepWidth :1,
    scales: {
      x:{
      },
      y: {
        max: 5,
            min: 0,
            ticks: {
                stepSize: 1
            }
      }
    },
  }
  // chart end
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const playerStat = [...data]

  function compare(a, b) {
    const numberPattern = /\d+/g
    const aDate = a.date.match(numberPattern).join('')
    const bDate = b.date.match(numberPattern).join('')
    if (aDate > bDate) return 1
    if (bDate > aDate) return -1

    return 0
  }

  const formatedData = [...data].sort(compare)

  const lastPage = Math.ceil(formatedData.length / 10)
  const dataModular = formatedData.length % 10
  const page = lastPage - 1
  let temp = (page - 1) * 10 + (dataModular === 0 ? 10 : dataModular)
  if (temp < 0) {
    temp = 0
  }
  let lastIndex = temp + 10
  if (lastIndex > formatedData.length) {
    lastIndex = formatedData.length
    temp = 0
  }

  const getMatchPerfStartData = formatedData.slice(temp, lastIndex)
  const [matchPerformance, setMatchPagination] = React.useState({
    data: getMatchPerfStartData,
    page,
  })

  const getMatchPerformancData = directionPage => {
    let currentPage = matchPerformance.page
    if (
      (directionPage === 'prev' && currentPage === 0) ||
      (directionPage === 'next' &&
        formatedData.length - (currentPage + 1) * 10 <= 0)
    )
      return

    switch (directionPage) {
      case 'next':
        currentPage += 1
        break
      case 'prev':
        currentPage -= 1
        break
      default:
    }

    temp = (currentPage - 1) * 10 + (dataModular === 0 ? 10 : dataModular)
    if (temp < 0) {
      temp = 0
    }
    lastIndex = temp + 10
    if (lastIndex > formatedData.length) {
      lastIndex = formatedData.length
      temp = 0
    }
    const currentData = formatedData.slice(temp, lastIndex)

    setMatchPagination({
      data: currentData,
      page: currentPage,
    })
  }

  const chartRating = []
  const chartDate = []
  let formatedDate = ''
  let datef = ''
  if (matchPerformance.data.length > 0) {
    matchPerformance.data.forEach(item => {
      if (item) {
        formatedDate = item.date.split('-')
        datef = formatedDate[2].concat('.', formatedDate[1])
        chartDate.push(datef)
        if (item.rating == null) {
          item.rating = 0
        }
        chartRating.push(item.rating)
      }
    })
  } else if (playerStat) {
    playerStat.forEach(item => {
      if (item) {
        formatedDate = item.date.split('-')
        datef = formatedDate[2].concat('.', formatedDate[1])
        chartDate.push(datef)
        if (item.rating == null) {
          item.rating = 0
        }
        chartRating.push(item.rating)
      }
    })
  }
  chartData.labels = chartDate
  chartData.datasets[0].data = chartRating || []

  React.useEffect(() => {}, [])
  const classes = useStyles()

  return (
    <Paper component={Box}>
      <Toolbar disableGutters={false} className={classes.toolbar}>
        <Box className={classes.title}>
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={title} />
          </Tabs>
        </Box>
        <ButtonGroup size="small">
          <Button size="small" onClick={() => getMatchPerformancData('prev')}>
            <ArrowBackIosIcon />
          </Button>
          <Button size="small" onClick={() => getMatchPerformancData('next')}>
            <ArrowForwardIosIcon />
          </Button>
        </ButtonGroup>
      </Toolbar>
      <Divider />
      <Box mx={2} mt={2} pb={2}>
        {value === 0 && (
          <>
            {matchPerformance.data.length > 0 ? (
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <StyledChart>
                    <LineChart data={chartData} options={options} />
                  </StyledChart>
                </Grid>
              </Grid>
            ) : (
              <NoData>
                <div>
                  <img alt="icon" src={BallIcon} />
                  <p>
                    <Translate id="home.empty-performance" />
                  </p>
                </div>
              </NoData>
            )}
          </>
        )}
      </Box>
    </Paper>
  )
}

PerformanceWidget.defaultProps = {
  data: [],
}

PerformanceWidget.propTypes = {
  theme: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
}

export default withTheme(PerformanceWidget)
