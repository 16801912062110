import React from 'react'
import { Translate } from 'react-localize-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Paper, Tabs, Tab, Toolbar, Typography, Box } from '@material-ui/core'
import useTeamFeature from 'hooks/useTeamFeature'
import { FavoritesLoader } from 'loaders'
import {
  ArticlesCarousel,
  DrillsCarousel,
  TrainingPackagesCarousel,
  VideosCarousel,
  SequencesCarousel,
} from './components'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const MuiBox = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(Box)
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 50,
    borderBottom: '1px solid #e8e8e8',
  },
  title: {
    flex: '1 1 100%',
  },
}))

const FavoritesContainer = () => {
  const classes = useToolbarStyles()

  const { enabled: hasVideoSectionEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.video_manager_enabled
  )

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography variant="h4" component="h4">
          <Translate id="navbar.favorites" />
        </Typography>
      </Toolbar>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root}>
          <Tabs
            value={value}
            className={classes.title}
            onChange={handleChange}
            variant="scrollable"
            indicatorColor="primary"
            scrollButtons="auto"
          >
            <Tab label={<Translate id="home.articles" />} />
            <Tab label={<Translate id="home.drills" />} />
            <Tab label={<Translate id="navbar.training_packages" />} />
            {hasVideoSectionEnabled && (
              <Tab label={<Translate id="videos.videos" />} />
            )}
            {hasVideoSectionEnabled && (
              <Tab label={<Translate id="videos.sequences" />} />
            )}
          </Tabs>
        </Toolbar>
        <MuiBox>
          {value === 0 && (
            <FavoritesLoader entity="articles" alwaysRenderChildren>
              <ArticlesCarousel changeTab={setValue} />
            </FavoritesLoader>
          )}
          {value === 1 && (
            <FavoritesLoader entity="drills" alwaysRenderChildren>
              <DrillsCarousel changeTab={setValue} />
            </FavoritesLoader>
          )}
          {value === 2 && (
            <FavoritesLoader entity="trainingPackages" alwaysRenderChildren>
              <TrainingPackagesCarousel changeTab={setValue} />
            </FavoritesLoader>
          )}
          {value === 3 && (
            <FavoritesLoader entity="videos" alwaysRenderChildren>
              <VideosCarousel changeTab={setValue} />
            </FavoritesLoader>
          )}
          {value === 4 && (
            <FavoritesLoader entity="sequences" alwaysRenderChildren>
              <SequencesCarousel changeTab={setValue} />
            </FavoritesLoader>
          )}
        </MuiBox>
      </Paper>
    </>
  )
}

export default FavoritesContainer
