import { combineActions, handleActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'TEAM'

const FETCH_TEAMS = actionsTypesGenerator(`FETCH_${namespace}S`)
const IMPORT_PLAYERS = actionsTypesGenerator('IMPORT_PLAYERS')
const FETCH_TEAM_INVITE_URL = actionsTypesGenerator(`FETCH_TEAM_INVITE_URL`)
const FETCH_TEAMS_BY_PARAMS = actionsTypesGenerator(`FETCH_TEAMS_BY_PARAMS`)
const FETCH_NEXT_EVENTS = actionsTypesGenerator(`FETCH_NEXT_EVENTS`)
const FETCH_CLUB_TEAMS = actionsTypesGenerator(`FETCH_CLUB_TEAMS`)

// @tofix Remove old api call and use #v2 api allover
export const fetchTeams = () => ({
  types: FETCH_TEAMS,
  callAPI: {
    method: 'GET',
    entity: 'Teams',
    path: '/user/teams',
  },
})
export const fetchTeamsByParams = (params) => ({
  types: FETCH_TEAMS_BY_PARAMS,
  callAPI: {
    method: 'GET',
    entity: 'Teams',
    path: '/v2/teams',
    params,
  },
})

export const fetchClubTeams = (clubUuid,params) => ({
  types: FETCH_CLUB_TEAMS,
  callAPI: {
    method: 'GET',
    entity: 'Teams',
    path: `/v2/clubs/${clubUuid}/teams`,
    params,
  },
})
export const importPlayers = (id, data) => ({
  types: IMPORT_PLAYERS,
  callAPI: {
    method: 'POST',
    entity: 'Players Import',
    path: `/teams/${id}/import_players`,
    data,
  },
})

export const fetchInviteUrl = (teamUuid) => ({
  types: FETCH_TEAM_INVITE_URL,
  callAPI: {
    method: 'GET',
    entity: 'Invites URL',
    path: `/v2/teams/${teamUuid}/invite-url`,
  },
})

export const fetchTeamNextEvents = (seasonUuid) => ({
  types: FETCH_NEXT_EVENTS,
  callAPI: {
    method: 'GET',
    entity: 'Team',
    path: `/seasons/${seasonUuid}/next-events`,
    data: {
      preventToastNotification: true,
    },
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_TEAMS.request,
      FETCH_TEAMS_BY_PARAMS.request,
      FETCH_CLUB_TEAMS.request,
      IMPORT_PLAYERS.request,
      FETCH_TEAM_INVITE_URL.request,
      FETCH_NEXT_EVENTS.request
    )]: onRequest,
    [FETCH_TEAMS.success]: (state, { response: { data = [] } }) => {
      return onSuccess({
        ...state,
        items: data,
      })
    },
    [FETCH_TEAMS_BY_PARAMS.success]: (state, { response }) => {
      return onSuccess({
        ...state,
        items: response,
      })
    },
    [FETCH_CLUB_TEAMS.success]: (state, { response }) => {
      return onSuccess({
        ...state,
        items: response,
      })
    },
    [combineActions(
      FETCH_TEAMS.failure,
      FETCH_TEAMS_BY_PARAMS.failure,
      FETCH_CLUB_TEAMS.failure,
      IMPORT_PLAYERS.failure,
      FETCH_TEAM_INVITE_URL.failure,
      FETCH_NEXT_EVENTS.failure
    )]: onFailure,
  },
  initialState.teams
)
