import React, { useEffect, useState } from 'react'
import {
  Box,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  useTheme,
} from '@material-ui/core'
import TabPanel from 'components/material/TabPanel'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import TaskGroupsWidget from 'containers/TaskManagement/TaskGroupsWidget'
import TrainingPlan from './TrainingSession'
import TrainingNotes from './TrainingNotes'
import GroupsAndParticipationContainer from './GroupsAndParticipation'
import PropTypes from 'constants/propTypes'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'
import useTeamFeature from 'hooks/useTeamFeature'

const useStyles = makeStyles({
  tabRoot: {
    '& .MuiTab-root': {
      padding: '6px 22px',
      maxWidth: 'none',
    },
  },
})

const TabViewContainer = ({
  currentTrainingUuid,
  participants,
  trainingDrills,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [activeTab, setActiveTab] = useState('training-plan')
  const [players, setPlayers] = useState(participants || [])
  const [sessionDrills, setSessionDrills] = useState(trainingDrills || [])

  const { enabled: hasTrainingNotesEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.advanced_training_features_enabled
  )

  useEffect(() => {
    setSessionDrills(trainingDrills || [])
  }, [trainingDrills])

  const handlePlayersChange = (updatedPlayers) => {
    setPlayers(updatedPlayers)
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleUpdateDrills = (updatedDrills) => {
    setSessionDrills(updatedDrills)
  }

  useEffect(() => {
    setPlayers(participants)
  }, [participants])

  return (
    <Box padding={2}>
      <Box style={{ padding: '0 16px' }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons={isMobile ? 'auto' : 'off'}
          indicatorColor="primary"
          className={classes.tabRoot}
        >
          <Tab
            label={<Translate id="training.training-plan" />}
            value="training-plan"
          />
          <Tab
            label={<Translate id="training.group-and-participation" />}
            value="training-groups"
          />
          {hasTrainingNotesEnabled && (
            <Tab
              label={<Translate id="matches.match-notes" />}
              value="training-notes"
            />
          )}
          <Tab label={<Translate id="menu.task" />} value="training-tasks" />
        </Tabs>
        <Divider />
      </Box>
      <TabPanel value={activeTab} index="training-plan">
        <TrainingPlan
          sessionDrills={sessionDrills}
          onUpdateDrills={handleUpdateDrills}
        />
      </TabPanel>
      <TabPanel value={activeTab} index="training-groups">
        <GroupsAndParticipationContainer
          players={players}
          onChange={handlePlayersChange}
        />
      </TabPanel>
      {hasTrainingNotesEnabled && (
        <TabPanel value={activeTab} index="training-notes">
          <TrainingNotes />
        </TabPanel>
      )}
      <TabPanel value={activeTab} index="training-tasks">
        <TaskGroupsWidget isTrainingEvent eventId={currentTrainingUuid} />
      </TabPanel>
    </Box>
  )
}

TabViewContainer.propTypes = {
  currentTrainingUuid: PropTypes.string.isRequired,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  trainingDrills: PropTypes.arrayOf().isRequired,
}

export default connect(({ trainings: { current } }) => ({
  currentTrainingUuid: current.uuid,
  participants: current?.relationships?.participants,
  trainingDrills: current?.relationships?.drills,
}))(TabViewContainer)
