import React from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Button, Grid, Box } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import useStyles from 'routes/styleComponents'

const CfgSSO = ({ theme }) => {
  const classes = useStyles(theme)

  const callbackUrl = `${process.env.REACT_APP_URL}/sso/callback`
  const redirectUrl = `${process.env.REACT_APP_API_URL}/auth/cfg/login?redirect_to=${encodeURI(callbackUrl)}`

  return (
    <Grid md={12} item>
      <Box align="center">
        <Button
          fullWidth
          variant="contained"
          type="button"
          className={classes.button}
          size="large"
          onClick={() => {
            window.location.href = redirectUrl
          }}
          style={{ cursor: "pointer" }}
        >
          Login with Man City account
        </Button>
      </Box>
    </Grid>
  )
}

CfgSSO.propTypes = {
  theme: PropTypes.oneOfType([
    PropTypes.shape().isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]).isRequired,
}

export default compose(
  withRouter,
)(CfgSSO)
