import React, { useState, useEffect } from 'react'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import {
  Paper,
  CardHeader,
  Card,
  Grid,
  MenuItem,
  Box,
  Link,
  CardContent,
  Divider,
  Typography,
  Tooltip,
  ClickAwayListener,
  Hidden,
} from '@material-ui/core'
import { generalEvents } from 'redux/modules'
import { useParams, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import IconButton from '@material-ui/core/IconButton'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import MapOutlinedIcon from '@material-ui/icons/MapOutlined'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import {
  formatCostumDateForUI,
  formatUtcDateToDateTime,
  formatUtcDateTimeToUserTimezoneDateTime,
} from 'utils/formatDate'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import AlertDialog from 'components/AlertDialog'
import DropDownMenu from 'components/DropDownMenu'
import EventPopup from 'components/material/Calender/eventPopup'
import PropTypes from 'prop-types'
import { useInfoStyles } from '../styles'
import EventInfoEditDialog from './EventInfoEditDialog'
import { TRAININGS_GAME } from 'constants/calender'

const EventInfo = ({
  currentEvent,
  dateFormat,
  createEvent,
  deleteEvent,
  history,
  timezone,
  seasons,
  team,
}) => {
  const { seasonId } = useParams()
  const [eventCreateDialogOpen, setEventCreateDialogOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState()
  const [eventInfoEditOpen, setEventInfoEditOpen] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [openTooltip, setOpenTooltip] = useState(false)
  const [duplicateData, changeDuplicateData] = useState(null)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [seasonUuid, setSeasonUuid] = useState(null)

  const handleInfoTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleInfoTooltipOpen = () => {
    setOpenTooltip(true)
  }

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseOptions = () => {
    setAnchorEl(null)
  }
  const handleClickEventInfoEditOpen = () => {
    setEventInfoEditOpen(true)
    setAnchorEl(null)
  }
  const handleClickEventInfoEditClose = () => {
    setEventInfoEditOpen(false)
  }
  const handleAlertBoxClose = () => {
    setAlertOpen(false)
  }
  const handleAlertBoxOpen = () => {
    setAlertOpen(true)
    setAnchorEl(null)
  }
  const handleAlertConfirm = () => {
    deleteEvent(currentEvent.uuid).then(() => {
      history.push(`/team/${team.uuid}/season/${+seasonId}/events`)
      handleAlertBoxClose()
    })
  }

  const handleDuplicateClick = (text = '') => {
    setTrainingAndGame(text)
    setEventCreateDialogOpen(true)
    setAnchorEl(null)
    const tempEventData = {
      ...currentEvent,
      date: formatUtcDateTimeToUserTimezoneDateTime(
        currentEvent.start_datetime,
        timezone
      ),
      end_time: currentEvent.end_datetime
        ? formatUtcDateTimeToUserTimezoneDateTime(
            currentEvent.end_datetime,
            timezone
          )
        : null,
    }
    changeDuplicateData(tempEventData)
    setIsDuplicate(true)
  }

  const handleClose = () => {
    setEventCreateDialogOpen(false)
    changeDuplicateData(null)
    setIsDuplicate(false)
  }

  const createNewEvent = (data) => {
    createEvent(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      history.push(
        `/team/${team.uuid}/season/${+seasonId}/general-events/${uuid}`
      )
    })
  }

  // #remove Once season uuid can be accessed from url this can be removed
  useEffect(() => {
    if (seasons) {
      const season = seasons.find((s) => s.id === +seasonId)
      setSeasonUuid(season.uuid)
    }
  }, [seasons])
  const classes = useInfoStyles()

  return (
    <Box m={[2, 4]}>
      {eventCreateDialogOpen && (
        <EventPopup
          open={eventCreateDialogOpen}
          onClose={handleClose}
          duplicateData={duplicateData}
          trainingAndGame={trainingAndGame}
          createEvent={createNewEvent}
          selectedEventType={trainingAndGame}
          isDuplicate={isDuplicate}
        />
      )}
      {alertOpen && (
        <AlertDialog
          open={alertOpen}
          onClose={handleAlertBoxClose}
          onSuccess={handleAlertConfirm}
          onCancel={handleAlertBoxClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      <Paper className={classes.noteContent}>
        {eventInfoEditOpen && (
          <EventInfoEditDialog
            open={eventInfoEditOpen}
            handleClose={handleClickEventInfoEditClose}
          />
        )}

        <Card>
          <CardHeader
            className={classes.styledCardHeader}
            title={
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <Grid container className={classes.StyledTitle} spacing={3}>
                    <Grid item>
                      <Box>
                        <strong>
                          <Translate id="button.event" />
                        </strong>
                      </Box>
                    </Grid>
                    <Grid item>
                      <strong>-</strong>
                    </Grid>
                    <Grid item>
                      <strong>{currentEvent.name}</strong>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            action={
              <>
                <IconButton
                  onClick={handleOptionsClick}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                >
                  <MoreVertIcon />
                </IconButton>
                <DropDownMenu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  elevation={2}
                  keepMounted
                  edge="start"
                  open={Boolean(anchorEl)}
                  onClose={handleCloseOptions}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={handleClickEventInfoEditOpen}>
                    <EditIcon from fontSize="small" />
                    <Translate id="button.edit" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDuplicateClick(TRAININGS_GAME.event)}
                  >
                    <FileCopyIcon fontSize="small" />
                    <Translate id="button.duplicate" />
                  </MenuItem>
                  <MenuItem onClick={handleAlertBoxOpen}>
                    <DeleteIcon fontSize="small" />
                    <Translate id="button.delete" />
                  </MenuItem>
                </DropDownMenu>
              </>
            }
          />
          <CardContent>
            <Grid container justifyContent="center" spacing={3}>
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                item
                xs={12}
                md={10}
                spacing={0}
                className={classes.mobileGrid}
              >
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="team.date" />
                    </small>
                  </Grid>
                  <Grid item>
                    <DateRangeOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {formatCostumDateForUI(
                        currentEvent.start_datetime,
                        dateFormat
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="events.location" />
                    </small>
                  </Grid>
                  <Grid item>
                    <MapOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      <Link
                        href={`https://maps.google.com/?q=${currentEvent.lat},${currentEvent.lng}`}
                        target="_blank"
                      >
                        {currentEvent.city || currentEvent.country || '-'}
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="events.table-time" />
                    </small>
                  </Grid>
                  <Grid item>
                    <GroupOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {
                        formatUtcDateToDateTime(
                          currentEvent.start_datetime,
                          timezone
                        )[1]
                      }
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <small>
                      <Translate id="matches.end-time" />
                    </small>
                  </Grid>
                  <Grid item>
                    <TimerOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      {currentEvent.end_datetime
                        ? formatUtcDateToDateTime(
                            currentEvent.end_datetime,
                            timezone
                          )[1]
                        : '-'}
                    </Box>
                  </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                  item
                  xs={2}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <DescriptionOutlinedIcon fontSize="small" />
                  </Grid>
                  <Grid item>
                    <Box textAlign="center">
                      <Hidden smDown>
                        <Tooltip title={currentEvent.details || '-'} arrow>
                          <Typography>
                            <u style={{ textDecorationStyle: 'dotted' }}>
                              Info
                            </u>
                          </Typography>
                        </Tooltip>
                      </Hidden>
                      <Hidden mdUp>
                        <ClickAwayListener onClickAway={handleInfoTooltipClose}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleInfoTooltipClose}
                            open={openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={currentEvent.details || '-'}
                            arrow
                          >
                            <Typography onClick={handleInfoTooltipOpen}>
                              <u style={{ textDecorationStyle: 'dotted' }}>
                                Info
                              </u>
                            </Typography>
                          </Tooltip>
                        </ClickAwayListener>
                      </Hidden>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  )
}

EventInfo.propTypes = {
  history: PropTypes.shape().isRequired,
  currentEvent: PropTypes.shape().isRequired,
  createEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  seasons: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  team: PropTypes.shape().isRequired,
}

export default compose(
  connect(
    ({ team, auth, generalEvents: { current }, seasons: { items } }) => ({
      team,
      currentEvent: current,
      dateFormat: auth.user.datetime_format,
      timezone: auth.user.time_zone,
      seasons: items,
    }),
    {
      createEvent: generalEvents.createEvent,
      deleteEvent: generalEvents.deleteEvent,
    }
  ),
  withRouter
)(EventInfo)
