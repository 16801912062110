import React, { useState, useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { auth as authModule } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const Logout = ({ handleLogout, auth, club }) => {
  const [redirectTo, setRedirectTo] = useState(null)

  // Clear local storage
  localStorage.clear()

  useEffect(() => {
    const { locale } = auth
    const cfgClubUuid = process.env.REACT_APP_CFG_CLUB_UUID
    let loginUrl = locale === 'en' ? '/login' : `/${locale}/login`

    if (cfgClubUuid === club?.uuid) {
      loginUrl = `/cfg`
    }

    setRedirectTo(loginUrl) // Update state after logout

    handleLogout()
  }, [])

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return null // Prevent rendering issues
}

Logout.propTypes = {
  handleLogout: PROP_TYPES.func.isRequired,
  auth: PROP_TYPES.shape({
    locale: PROP_TYPES.string.isRequired,
  }).isRequired,
  club: PROP_TYPES.shape({
    uuid: PROP_TYPES.string.isRequired,
  }).isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ auth, clubs }) => ({
      auth,
      club: clubs.current,
    }),
    {
      handleLogout: authModule.logout,
    }
  )
)(Logout)
