export default [
  {
    value: 'group_a',
    title: 'Group A',
    groupId: 0
  },
  {
    value: 'group_b',
    title: 'Group B',
    groupId: 1
  },
  {
    value: 'group_c',
    title: 'Group C',
    groupId: 2
  },
  {
    value: 'group_d',
    title: 'Group D',
    groupId: 3
  },
]
