import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: 228,
    overflow: 'auto',
    '& .MuiListItemAvatar-root': {
      marginRight: theme.spacing(1),
    },
  },
  paperRoot: {
    '& .MuiList-root': {
      maxHeight: 328,
    },
  },
  media: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  typography: {
    maxWidth: 150,
  },
  iconbutton: {
    padding: theme.spacing(0),
  },
  PlayCircle: {
    position: 'absolute',
    top: '20%',
    height: 40,
    width: 40,
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50%',
    left: theme.spacing(0),
    right: theme.spacing(0),
    margin: theme.spacing(0, 'auto'),
    '& .MuiSvgIcon-root': {
      fontSize: 40,
      height: 40,
      width: 40,
    },
  },
}))

export default useStyles
